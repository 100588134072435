import React, { useState, useEffect } from "react";

const Notification = ({ message, success, auto }: { message: string, success: boolean, auto: boolean }) => {
    const [visible, setVisible] = useState(true);

    useEffect(() => {
        if (auto) {
            setTimeout(() => {
                setVisible(false)
            }, 5000);            
        }
    });

    return(
        <div className={`notification ${!visible && 'hidden'}`}>
            <div className={`box ${success ? 'success-sm' : 'error-sm'}`}>
                <span className="material-icons">priority_high</span><span>{message}</span>
            </div>
        </div>
    );
};

export default Notification;