import Modal from '@material-ui/core/Modal';
import TextField from '@material-ui/core/TextField';
import { makeStyles } from '@material-ui/core/styles';
import Autocomplete from '@material-ui/lab/Autocomplete';
import { format } from 'date-fns';
import { ChangeEvent, FormEvent, useEffect, useState } from 'react';
import { useMutation, useQuery } from "react-query";
import ModalLoader from "../../../common/ModalLoader";
import Notification from "../../../common/Notification";
import { newAccount } from "../../../data/mutations/account";
import { getAllPlans, searchClient } from "../../../data/queries/account";
import Account, { AutofillUser } from "../../../models/Account";
import formValidator from "../../../services/formValidator";


interface IAddService {
  edit: boolean;
  carrier: { account: Account, setAccount: Function, planId: string };
  adding: { adding: boolean, setAdding: Function },
  setMessage: Function;
  refetch: Function;
}

export interface IPlan {
  id: string;
  planName: string;
}

const useStyles = makeStyles((theme) => ({
  paper: {
    position: 'absolute',
    backgroundColor: theme.palette.background.paper,
    borderRadius: '8px',
    padding: theme.spacing(2, 4, 3),
    top: '15%',
    left: '35%',
    '@media (max-width: 780px)': {
      left: '1%',
      padding: theme.spacing(2, 4, 0),
    }
  },
}));

const AddClient = ({ edit, carrier, adding, setMessage, refetch }: IAddService) => {
  const classes = useStyles();

  const [creatingId, setCreatingId] = useState('');
  const [plans, setPlans] = useState<IPlan[]>([]);
  const [error, setError] = useState('');
  const [searchString, setSearchString] = useState('');
  const [formError, setFormError] = useState({
    status: false,
    name: '',
    message: ''
  });
  const [searchedUsers, setSearchedUsers] = useState<AutofillUser[]>([]);

  useEffect(() => {
    if ((carrier.planId !== undefined) && (carrier.planId !== 'all')) {
      setCreatingId(carrier.planId);
    }
  }, [carrier.planId]);

  useEffect(() => {
    const currDate = new Date();
    // let value = `${currDate.getFullYear()}-${currDate.getMonth() + 1}`;
    // const daysInMonth = getDaysInMonth(currDate.getFullYear(), (currDate.getMonth() + 1));
    
    // if ((daysInMonth - currDate.getDate()) > 9) {
    //   value = `${currDate.getFullYear()}-${currDate.getMonth() + 2}`;
    // } else {
    //   value = `${currDate.getFullYear()}-${currDate.getMonth() + 3}`;
    // }

    carrier.setAccount({
      ...carrier.account,
      // startDate: new Date(value)
      startDate: currDate.setDate(currDate.getDate() + 11)
    });
  },
  // eslint-disable-next-line
  [])

  const { isLoading: loading } = useQuery(['get all plans'], getAllPlans, {
    onSuccess(datas: { count: number, plans: IPlan[] }){
        setPlans(datas.plans);
    }
});

  const { mutate: addAccount, isLoading } = useMutation(
    ({ planId, account } : { planId: string, account: Account }) => 
    newAccount(planId, account), {
      onSuccess() {
        adding.setAdding(false);
        setTimeout(() => {
          setMessage('');
        }, 5000);
        setMessage('The account has been added!');
        refetch();
      },
      onError(error: {message: string}) {
        setTimeout(() => {
          setError('');
        }, 5000);
        setError(error.message);
      }
  });

  useQuery([`search with query ${searchString}`, { searchString }], searchClient, {
    onSuccess: (data) => {
      if (data) {
        setSearchedUsers(data);        
      }
    },
    enabled: searchString.trim() !== null && searchString.length > 2,
    retry: false,
    cacheTime: 0,
  });

  const handleClose = () => {
    adding.setAdding(false);
  };

  const handleDateChange = (e: ChangeEvent<HTMLInputElement | HTMLSelectElement>) => {
    const date = new Date(e.target.value);
    const currDate = new Date();
    currDate.setDate(currDate.getDate() - 1);
    const lessDate = date.getTime() < currDate.getTime();

    if (lessDate) {
      setTimeout(() => {
        setFormError({
            status: false,
            name: '',
            message: ''
        })
      }, 5000);
      return setFormError({
          status: true,
          name: 'startDate',
          message : 'Cannot use past date'
      });
    }

    date.setDate(date.getDate() + 11);
    
    // if ((date.getMonth() === currDate.getMonth()) && (date.getFullYear() === currDate.getFullYear())) {
    //   if ((daysInMonth - currDate.getDate()) > 9) {
    //     value = `${date.getFullYear()}-${date.getMonth() + 2}`;
    //   } else {
    //     value = `${date.getFullYear()}-${date.getMonth() + 3}`;
    //   }
    // } else {
    //   value = `${date.getFullYear()}-${date.getMonth() + 2}`;
    // }    

    carrier.setAccount({
      ...carrier.account,
      // startDate: new Date(value)
      startDate: date
    });
  }

  const onChange = (e: ChangeEvent<HTMLInputElement | HTMLSelectElement>) => carrier.setAccount({
    ...carrier.account,
    [e.target.name]: e.target.value
  });

  const onSubmit = (e: FormEvent) => {
    e.preventDefault();    

    const { status, name, message } = formValidator(carrier.account);
        if (status) {
            setTimeout(() => {
                setFormError({
                    status: false,
                    name: '',
                    message: ''
                })
            }, 5000);
            return setFormError({
                status,
                name,
                message 
            });
        }
        if (creatingId === '') {
          setTimeout(() => {
              setFormError({
                  status: false,
                  name: '',
                  message: ''
              })
          }, 5000);
          return setFormError({
              status: true,
              name: 'creatingId',
              message : 'You have not selected a plan'
          });
      }   
    // create plan or edit one
    if (edit) {
      // awaiting editing
    } else {      
      addAccount({ planId: creatingId, account: carrier.account });
    }    
  }

  const body = (
    <div className={`${classes.paper} add-plan dimension-2`}>
      {
        isLoading && (
          <ModalLoader />
        )
      }
      {
        error !== '' && (
          <Notification
            message={error}
            success={false}
            auto={false}
          />
        )
      }
      <div className="title">
          <span>Add {carrier.planId ? 'an Account' : 'a Client'}</span>
      </div>
      <div className="body">
          <form onSubmit={onSubmit}>
              <div className="auto">
                  <label htmlFor="mpesaPhone">
                      Mpesa Number
                  </label>
                <Autocomplete
                    id="combo-box-demo"
                    options={searchedUsers}
                    getOptionLabel={(option: any) => option.mpesaPhone}
                    style={{
                      width: 250,
                      marginBottom: '10px'
                    }}
                    onChange={(e, values) => {
                      carrier.setAccount({
                        ...carrier.account,
                        // contactPhone: values?.contactPhone,
                        mpesaPhone: values?.mpesaPhone,
                        firstName: values?.firstName,
                        lastName: values?.lastName,
                        email: values?.email
                      });
                    }}
                    renderInput={(params: any) => 
                      <TextField 
                        {...params}
                        name="mpesaPhone"
                        label={carrier.account.mpesaPhone.toString()}
                        value={carrier.account.mpesaPhone  }
                        variant="outlined"
                        onChange={e => {
                          setSearchString(e.target.value);
                          carrier.setAccount({
                            ...carrier.account,
                            mpesaPhone: e.target.value
                          })
                        }}
                        />}
                  /><br />
              </div>
              <div>
                {
                    formError.name === 'mpesaPhone' && (
                      <small className="m-2">{formError.message}</small>
                    )
                  }
              </div>
              {/* <div>
                  <label htmlFor="mpesanumber">
                      MPESA Number
                  </label>
                  <input
                    className={`${formError.name === 'mpesaPhone' && 'input-error'}`}
                    name="mpesaPhone"
                    type="text"
                    placeholder="MPESA Number"
                    value={carrier.account.mpesaPhone}
                    onChange={onChange}
                  />
                  {
                    formError.name === 'mpesaPhone' && (
                      <small className="m-2">{formError.message}</small>
                    )
                  }
              </div> */}
              <div>
                  <label htmlFor="firstname">
                      First Name
                  </label>
                  <input
                    className={`${formError.name === 'firstName' && 'input-error'}`}
                    name="firstName"
                    type="text"
                    placeholder="First Name"
                    value={carrier.account.firstName}
                    onChange={onChange}
                  />
                  {
                    formError.name === 'firstName' && (
                      <small className="m-2">{formError.message}</small>
                    )
                  }
              </div>
              <div>
                  <label htmlFor="lastname">
                      Last Name
                  </label>
                  <input
                    className={`${formError.name === 'lastName' && 'input-error'}`}
                    name="lastName"
                    type="text"
                    placeholder="Last Name"
                    value={carrier.account.lastName}
                    onChange={onChange}
                  />
                  {
                    formError.name === 'lastName' && (
                      <small className="m-2">{formError.message}</small>
                    )
                  }
              </div>
              <div>
                  <label htmlFor="email">
                      Email
                  </label>
                  <input
                    className={`${formError.name === 'email' && 'input-error'}`}
                    name="email"
                    type="text"
                    placeholder="Email"
                    value={carrier.account.email}
                    onChange={onChange}
                  />
                  {
                    formError.name === 'email' && (
                      <small className="m-2">{formError.message}</small>
                    )
                  }
              </div>
              <div>
                  <label htmlFor="accountnumber">
                      Account Number
                  </label>
                  <input
                    className={`${formError.name === 'accountNumber' && 'input-error'}`}
                    name="accountNumber"
                    type="text"
                    placeholder="Account Number"
                    value={carrier.account.accountNumber}
                    onChange={onChange}
                  />
                  {
                    formError.name === 'accountNumber' && (
                      <small className="m-2">{formError.message}</small>
                    )
                  }
              </div>
              {
                (carrier.planId === 'all' || carrier.planId === undefined) && (
                  <div>
                    <label htmlFor="accountplan">
                        Account Package
                    </label>
                    <select
                      className={`${formError.name === 'creatingId' && 'input-error'}`}
                      name="planId"
                      value={creatingId}
                      onChange={(e) => setCreatingId(e.target.value)}
                      disabled={loading}
                    >
                      <option value="" >Select Package</option>
                      {/* Display all the plans */}
                      {
                        plans.length > 0 && plans.map((plan, i) => 
                          <option key={i} value={plan.id}>{plan.planName}</option>
                          )
                      }
                    </select>
                    {
                      formError.name === 'creatingId' && (
                        <small className="m-2">{formError.message}</small>
                      )
                    }       
                </div>
                )
              }
              <div>
                  <label htmlFor="startdate">
                      Start Date
                  </label>
                  <input
                    className={`${formError.name === 'startDate' && 'input-error'}`}
                    name="startDate"
                    type="date"
                    value={format(new Date(carrier.account.startDate),'yyyy-MM-dd')}
                    onChange={handleDateChange}
                  />
                  {
                    formError.name === 'startDate' && (
                      <small className="m-2">{formError.message}</small>
                    )
                  }
              </div>
              <div className="footer">
                <button className="button-action btn-2" type="submit">
                    Add {carrier.planId ? 'Account': 'Client'}
                </button>
              </div>
          </form>
      </div>
    </div>
  );

  return (
    <div>
      <Modal
        open={adding.adding}
        onClose={handleClose}
        aria-labelledby="simple-modal-title"
        aria-describedby="simple-modal-description"
      >
        {body}
      </Modal>
    </div>
  );
};


export default AddClient;