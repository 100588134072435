import authApi from "../../api/auth";
import errorHandler from "../../services/errorHandler";


const activateEmail = async(params: any) => {
    const [, { token }] = params.queryKey;

    try {
        let resp = await (await authApi.activateEmail(token)).data;
        return resp;
    } catch (error) {
        errorHandler(error)
    }
}

export { activateEmail };