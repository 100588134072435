import { Box, LinearProgress, LinearProgressProps, makeStyles, Modal, Typography } from "@material-ui/core";
import { useEffect, useState } from "react";
import { CSVLink } from "react-csv";
// import Account from "../../../models/Account";
import { useQuery } from "react-query";
import Image from "../../../assets/images/upload.png";
import Notification from "../../../common/Notification";
import { uploadCSV } from "../../../data/mutations/account";
import { getAllPlans } from "../../../data/queries/account";
import { IPlan } from "./AddClient";


interface IUploadService {
    carrier: { planId: string };
    uploading: { uploadingCSV: boolean, setUploadingCSV: Function },
    setMessage: Function;
    refetch: Function;
}

const useStyles = makeStyles((theme) => ({
    paper: {
      position: 'absolute',
      backgroundColor: theme.palette.background.paper,
      borderRadius: '8px',
      padding: theme.spacing(2, 4, 3),
      top: '15%',
      left: '35%',
      '@media (max-width: 780px)': {
        left: '1%',
        padding: theme.spacing(2, 4, 0),
      }
    },
}));

const LinearProgressWithLabel = (props: LinearProgressProps & { value: number, totalSize: number, planId: string }) => {
    return (
      <Box sx={{ display: 'flex', alignItems: 'center'}}>
        <Box sx={{ width: '100%', mr: 1 }}>
          <Typography variant="body2" color="primary">{props.planId ? 'Account' : 'Client'}</Typography>
          <LinearProgress variant="determinate" {...props} />
          <Typography variant="body2" style={{ color: '#71717A' }}>{(props.value/100) * props.totalSize}MB / {props.totalSize}MB</Typography>
        </Box>
        <Box sx={{ minWidth: 35 }}>
          <Typography variant="body2" color="primary">{`${Math.round(
            props.value,
          )}%`}</Typography>
        </Box>
      </Box>
    );
  }

const UploadCSV = ({ carrier, uploading, setMessage, refetch }: IUploadService) => {
    const classes = useStyles();
    const [progress, setProgress] = useState(0);
    const [done, setDone] = useState(false);
    const [totalSize, setTotalSize] = useState(0);
    const [plans, setPlans] = useState<IPlan[]>([]);
    const [creatingId, setCreatingId] = useState('');
    const [error, setError] = useState('');
    const [formError, setFormError] = useState({
      status: false,
      name: '',
      message: ''
    });

    const { isLoading: loading } = useQuery(['get all plans'], getAllPlans, {
      onSuccess(datas: { count: number, plans: IPlan[] }){
          setPlans(datas.plans);
      }
    });

    const csvData = [
      ["firstName", "lastName", "mpesaPhone", "email", "accountNumber"],
      ["exampleName", "exampleName", "254xxxxxxxxx", "example@example.com", "xxxxxx"]
    ];

    useEffect(() => {
        if (done) {
            setDone(true);
            setTimeout(() => {
              handleClose();
            }, 3000);
            setMessage('The residences have been added!');
            setTimeout(() => {
              setMessage('');
            }, 5000);
            refetch();
        }
    },
    // eslint-disable-next-line
    [done])

    const handleClose = () => {
        uploading.setUploadingCSV(false);
    };

    const body = (
        <div className={`${classes.paper} add-plan dimension-2`}>
          {
            error !== '' && (
              <Notification
                message={error}
                success={false}
                auto={false}
              />
            )
          }
          <div className="title-csv">
              <span>Upload {carrier.planId ? 'Account' : 'Client'} CSV files</span>
          </div>
          <div className="upload-content">
              <form onSubmit={() => {}}>
                <div className="">
                {
                    done ? (
                        <div className="upload-success">
                            <div className="image-div">
                                <img src={Image} alt="" />
                            </div>
                            <div className="confirmation-text">
                                <p>Upload Successful...Redirecting</p>
                            </div>
                        </div>
                    ) : (
                        <div>
                        <div className="plan-id-input">
                          <label htmlFor="subscriptionplan">
                            {carrier.planId ? 'Account' : 'Client'} Package
                          </label>
                          <div className="wrap-select">
                            <select
                              className={`${formError.name === 'creatingId' && 'input-error'}`}
                              name="planId"
                              value={creatingId}
                              onChange={(e) => setCreatingId(e.target.value)}
                              disabled={loading}
                            >
                              <option value="" >Select Package</option>
                              {/* Display all the plans */}
                              {
                                plans.length > 0 && plans.map((plan, i) => 
                                  <option key={i} value={plan.id}>{plan.planName}</option>
                                  )
                              }
                            </select>
                            {
                              formError.name === 'creatingId' && (
                                <small className="m-2">{formError.message}</small>
                              )
                            } 
                          </div>
                        </div>
                        <div className="sample-csv">
                          <span>Download a Sample CSV Format <span className="material-icons sample-icon">arrow_forward</span></span>
                          <CSVLink
                            data={csvData}
                            filename={"uploadSample.csv"}
                            className="sample-btn"
                            >
                              Download
                          </CSVLink>
                        </div>
                        <label
                          className={`upload-label`}
                          htmlFor="upload"
                          draggable
                          onDragOver={(e) => e.preventDefault()}
                          onDrop={async (ev) => {
                            ev.nativeEvent.preventDefault();
                            console.log(ev.dataTransfer)
                            if (ev.dataTransfer.files) {
                              if (creatingId === '') {
                                setTimeout(() => {
                                  setFormError({
                                      status: false,
                                      name: '',
                                      message: ''
                                  })
                                }, 5000);
                                return setFormError({
                                  status: true,
                                  name: 'creatingId',
                                  message : 'You have not selected a package'
                                });
                              }
                              let value = ev.dataTransfer?.files[0].size / (1024 * 1024);
                              let round = (Math.round(value * 10) / 10).toFixed(1);
                              setTotalSize(parseFloat(round));
                              if (ev.dataTransfer?.items[0].type === 'text/csv') {
                                const formData = new FormData();
                                formData.append('file', ev.dataTransfer?.files[0]);
                                
                                try {
                                  let resp = await uploadCSV(creatingId, formData, setProgress);
                                  if (resp) {
                                    setDone(true);
                                  }
                                } catch (error: any) {
                                  setTimeout(() => {
                                    setError('');
                                  }, 10000);
                                  setError(error.message);
                                  setProgress(0);
                                }
                              }
                            }
                          }}
                          >
                            <div>
                                <span className="material-icons">
                                    cloud_upload
                                </span>
                                <p className="description">Drag and Drop or <span className="colored">Browse</span> Files to Upload</p>
                                <p className="placeholder">
                                    Upload File
                                </p>
                            </div>
                        </label>
                        <input
                            id="upload"
                            name="logo"
                            placeholder="Upload"
                            type="file"
                            onChange={async (e: any) => {
                              e.preventDefault();
                              if (creatingId === '') {
                                setTimeout(() => {
                                  setFormError({
                                      status: false,
                                      name: '',
                                      message: ''
                                  })
                                }, 5000);
                                return setFormError({
                                  status: true,
                                  name: 'creatingId',
                                  message : 'You have not selected a package'
                                });
                              }
                              let value = e.target?.files[0].size / (1024 * 1024);
                              let round = (Math.round(value * 10) / 10).toFixed(1);
                              setTotalSize(parseFloat(round));
                              if (e.target?.files[0].type === 'text/csv') {
                                const formData = new FormData();
                                formData.append('file', e.target?.files[0]);
                                
                                try {
                                  let resp = await uploadCSV(creatingId, formData, setProgress);
                                  if (resp) {
                                    setDone(true);
                                  }
                                } catch (error: any) {
                                  setTimeout(() => {
                                    setError('');
                                  }, 10000);
                                  setError(error.message);
                                  setProgress(0);
                                }
                              }
                            }}
                        />
                        </div>
                    )    
                }
                </div>
                {
                    !done && (
                        <div className="progress">
                            <div>
                                <span className="material-icons">
                                    upload_file
                                </span>
                            </div>
                            <div style={{ width: '100%' }}>
                                <LinearProgressWithLabel value={progress} totalSize={totalSize} planId={carrier.planId} />
                            </div>
                        </div>
                    )
                }
              </form>
          </div>
        </div>
    );
    
    return (
        <div>
          <Modal
            open={uploading.uploadingCSV}
            onClose={handleClose}
            aria-labelledby="simple-modal-title"
            aria-describedby="simple-modal-description"
          >
            {body}
          </Modal>
        </div>
    );
}

export default UploadCSV;
export { useStyles };
