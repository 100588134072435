import { createSlice, PayloadAction } from '@reduxjs/toolkit'
import type { RootState } from '../rootReducer';
import Company from '../../models/Company';
import { CompanyAccount, Ledger } from '../../models/Account';
import Plan from '../../models/Plan';

interface AdminState {
  companies: Company[];
  plans: Plan[],
  plansCount: number,
  transactions:any,

//   companyAccounts: CompanyAccount[];
//   ledger: Ledger | null;
}

const initialState: AdminState = {
  companies: [],
  plans: [],
  plansCount: 0,
  transactions: [],
//   companyAccounts: [],
//   ledger: null

}

export const adminSlice = createSlice({
  name: 'client',
  initialState,
  reducers: {
    loadAdminCompanies: (state, action: PayloadAction<Company[]>) => {
      state.companies = action.payload;
    },
    loadAdminPlans: (state, action: PayloadAction<Plan[]>) => {
      state.plans = action.payload;
    },
    loadAdminPlansCount: (state, action: PayloadAction<number>) => {
      state.plansCount = action.payload;
    },
    // loadCompanyAccounts: (state, action: PayloadAction<CompanyAccount[]>) => {
    //   state.companyAccounts = action.payload;
    // },
    loadTransactionHistory: (state, action: PayloadAction<Ledger>) => {
      state.transactions = action.payload;
    }
  },
});

export default adminSlice.reducer;

export const { loadAdminCompanies, loadAdminPlans, loadAdminPlansCount,loadTransactionHistory } = adminSlice.actions;
export const getAdminCompanies = (state: RootState) => state.admin.companies;
export const getAdminPlans = (state: RootState) => state.plan.plans;
export const getAdminPlansCount = (state: RootState) => state.plan.count;
export const getTransactionHistory = (state: RootState) => state.admin.transactions;
// export const getCompanyAccounts = (state: RootState) => state.client.companyAccounts;
// export const getLedger = (state: RootState) => state.client.ledger;