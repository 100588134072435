import React, { useState } from "react";
import {
  makeStyles,
  useTheme,
  Theme,
  createStyles,
} from "@material-ui/core/styles";
import Table from "@material-ui/core/Table";
import TableBody from "@material-ui/core/TableBody";
import TableCell from "@material-ui/core/TableCell";
import TableContainer from "@material-ui/core/TableContainer";
import TableFooter from "@material-ui/core/TableFooter";
import TablePagination from "@material-ui/core/TablePagination";
import TableRow from "@material-ui/core/TableRow";
import Paper from "@material-ui/core/Paper";
import IconButton from "@material-ui/core/IconButton";
import { BsChevronBarLeft, BsChevronBarRight } from "react-icons/bs";
import { FaChevronLeft, FaChevronRight } from "react-icons/fa6";
import { Box, TableHead } from "@material-ui/core";
import DateRangePicker from "../../ui/dashboard/inputs/datetimepickers/DateRangePicker";
import { FiSearch } from "react-icons/fi";
import { useAppDispatch, useAppSelector } from "../../../store/hooks";
import {
  getTransactionHistory,
  loadTransactionHistory,
} from "../../../store/slices/adminSlice";
import { IControllers } from "../../dashboard/plan/Plans";
import { useParams } from "react-router-dom";
import { useQuery } from "react-query";
import { getTransactionHistory as allTransactions } from "../../../data/queries/admin";

const useStyles1 = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      flexShrink: 0,
      marginLeft: theme.spacing(2.5),
    },
    table: {
      // minWidth: 350,
      border: "10px solid green",
      borderRadius: "50px ", // Set border radius here
      backgroundColor: "#FFFF",
      // m: 4,
      // width: '5rem',
      // height: '5rem',
      // overflow: `scroll`,
    },
  })
);

interface Column {
  id: "name" | "date" | "fee" | "status" | "amount";
  label: string;
  minWidth?: number;
  align?: "right";
  format?: (value: number) => string;
}

const columns: Column[] = [
  { id: "name", label: "Name", minWidth: 170 },
  { id: "date", label: "Date", minWidth: 100 },
  {
    id: "fee",
    label: "Fee",
    minWidth: 170,
    align: "right",
    format: (value: number) => value.toLocaleString("en-US"),
  },
  {
    id: "status",
    label: "Status",
    minWidth: 170,
    align: "right",
    format: (value: number) => value.toLocaleString("en-US"),
  },
  {
    id: "amount",
    label: "Amount",
    minWidth: 170,
    align: "right",
    format: (value: number) => value.toFixed(2),
  },
];

interface TablePaginationActionsProps {
  count: number;
  page: number;
  rowsPerPage: number;
  onPageChange: (
    event: React.MouseEvent<HTMLButtonElement>,
    newPage: number
  ) => void;
}

function TablePaginationActions(props: TablePaginationActionsProps) {
  const classes = useStyles1();
  const theme = useTheme();
  const { count, page, rowsPerPage, onPageChange } = props;

  const handleFirstPageButtonClick = (
    event: React.MouseEvent<HTMLButtonElement>
  ) => {
    onPageChange(event, 0);
  };

  const handleBackButtonClick = (
    event: React.MouseEvent<HTMLButtonElement>
  ) => {
    onPageChange(event, page - 1);
  };

  const handleNextButtonClick = (
    event: React.MouseEvent<HTMLButtonElement>
  ) => {
    onPageChange(event, page + 1);
  };

  const handleLastPageButtonClick = (
    event: React.MouseEvent<HTMLButtonElement>
  ) => {
    onPageChange(event, Math.max(0, Math.ceil(count / rowsPerPage) - 1));
  };

  return (
    <div className={classes.root}>
      <IconButton
        onClick={handleFirstPageButtonClick}
        disabled={page === 0}
        aria-label="first page"
      >
        {theme.direction === "rtl" ? (
          <BsChevronBarRight />
        ) : (
          <BsChevronBarLeft />
        )}
      </IconButton>
      <IconButton
        onClick={handleBackButtonClick}
        disabled={page === 0}
        aria-label="previous page"
      >
        {theme.direction === "rtl" ? <FaChevronRight /> : <FaChevronLeft />}
      </IconButton>
      <IconButton
        onClick={handleNextButtonClick}
        disabled={page >= Math.ceil(count / rowsPerPage) - 1}
        aria-label="next page"
      >
        {theme.direction === "rtl" ? <FaChevronLeft /> : <FaChevronRight />}
      </IconButton>
      <IconButton
        onClick={handleLastPageButtonClick}
        disabled={page >= Math.ceil(count / rowsPerPage) - 1}
        aria-label="last page"
      >
        {theme.direction === "rtl" ? (
          <BsChevronBarLeft />
        ) : (
          <BsChevronBarRight />
        )}
      </IconButton>
    </div>
  );
}

function createData(
  name: string,
  calories: number,
  fat: number,
  a: any,
  b: any
) {
  return { name, calories, fat, a, b };
}

const rows = [createData("Cupcake", 305, 3.7, 30, 6)].sort((a, b) =>
  a.calories < b.calories ? -1 : 1
);

const useStyles2 = makeStyles({
  table: {
    minWidth: 500,
  },
});

export default function TransactionHistory() {
  const classes = useStyles2();

  const { companyId } = useParams<{ companyId: string }>();
  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(5);
  const dispatch = useAppDispatch();
  const transactions = useAppSelector(getTransactionHistory);

  const [state, setState] = useState<IControllers>({
    page: 0,
    rowsPerPage: 10,
    limit: 10,
    skip: 0,
    companyId,
  });

  const { data, refetch, isLoading } = useQuery(
    ["get clients", { state }],
    allTransactions,
    {
      onSuccess(datas: { transactions: any }) {
        dispatch(loadTransactionHistory(datas.transactions));
      },
    }
  );

  console.log(transactions, "UYTFIYUTDFYTDFCIYHRTDFC");

  const emptyRows =
    rowsPerPage - Math.min(rowsPerPage, rows.length - page * rowsPerPage);

  const handleChangePage = (
    event: React.MouseEvent<HTMLButtonElement> | null,
    newPage: number
  ) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (
    event: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>
  ) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };

  const [selectedDate, setSelectedDate] = React.useState<Date | null>(
    new Date("2014-08-18T21:11:54")
  );

  const handleDateChange = (date: Date | null) => {
    setSelectedDate(date);
  };

  return (
    <main className="">
      <TableContainer component={Paper}>
        <Box
          component={"div"}
          sx={{
            p: 1,
          }}
          className={"px-6 grid justify-between grid-cols-3 items-baseline"}
        >
          <header className={"text-xl font-bold"}>Transaction history </header>

          <div>
            <DateRangePicker />
          </div>
          <div>
            <div className="relative border-0">
              <div className="pointer-events-none absolute inset-y-0 left-0 flex items-center ">
                <span className="text-gray-500 text-xl mr-2 text-secondary">
                
                </span>
              </div>
              <input
                type="text"
                className={
                  "rounded-xl border border-[#25282C33]/20  bg-[#F7F7F7]   block w-full   py-1.5 pl-4 text-secondary font-medium text-lg placeholder:text-base placeholder:text-ondefault focus:ring-0 sm:text-base  sm:leading-6 placeholder:capitalize  border-secondray focus:border-secondary focus:border-b-2 focus:border-0 focus:outline-none "
                }
                // autoComplete="off"
              />

              <div className="absolute inset-y-0 right-0 flex items-center">
                <span className="text-gray-500 text-xl mr-0.5 text-secondary">
                <FiSearch className="text-[#25282C]"/>
                </span>
              </div>
            </div>
          </div>
        </Box>

        <Table className={""} aria-label="custom pagination table">
          <TableHead>
            <TableRow>
              {columns.map((column) => (
                <TableCell
                  key={column.id}
                  align={column.align}
                  style={{ minWidth: column.minWidth }}
                >
                  {column.label}
                </TableCell>
              ))}
            </TableRow>
          </TableHead>
          {transactions === undefined ? (
            <TableBody>
              <TableRow>
                <TableCell align="center" colSpan={6} rowSpan={6} padding="normal">No Data</TableCell>
              </TableRow>
            </TableBody>
          ) : (
            <>
              <TableBody>
                {(rowsPerPage > 0
                  ? rows.slice(
                      page * rowsPerPage,
                      page * rowsPerPage + rowsPerPage
                    )
                  : rows
                ).map((row) => (
                  <TableRow key={row.name}>
                    <TableCell component="th" scope="row">
                      {row.name}
                    </TableCell>
                    <TableCell style={{ width: 160 }} align="right">
                      {row.calories}
                    </TableCell>
                    <TableCell style={{ width: 160 }} align="right">
                      {row.fat}
                    </TableCell>
                    <TableCell style={{ width: 160 }} align="right">
                      {row.a}
                    </TableCell>
                    <TableCell style={{ width: 160 }} align="right">
                      {row.b}
                    </TableCell>
                  </TableRow>
                ))}
               
              </TableBody>
              {/* <TableFooter>
              <TableRow>
                <TablePagination
                  rowsPerPageOptions={[5, 10, 25, { label: "All", value: -1 }]}
                  colSpan={3}
                  count={rows.length}
                  rowsPerPage={rowsPerPage}
                  page={page}
                  SelectProps={{
                    inputProps: { "aria-label": "rows per page" },
                    native: true,
                  }}
                  onPageChange={handleChangePage}
                  onRowsPerPageChange={handleChangeRowsPerPage}
                  ActionsComponent={TablePaginationActions}
                />
              </TableRow>
            </TableFooter> */}
            </>
          )}
        </Table>
      </TableContainer>
    </main>
  );
}
