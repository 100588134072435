import { useState } from "react";
import { useParams } from "react-router-dom";
import Account from "../../../models/Account";
import BreadCrumb from "../../layout/Breadcrumb";
import AddClient from "./AddClient";
import UploadCSV from "./UploadCSV";

const UploadTopbar = ({ setMessage, refetch }: { setMessage: Function; refetch: Function; }) => {
    const { planId } = useParams<{ planId: string }>();
    const [adding, setAdding] = useState(false);
    const [uploadingCSV, setUploadingCSV] = useState(false);
    const [account, setAccount] = useState<Account>({
        // contactPhone: "",
        mpesaPhone: "",
        firstName: "",
        lastName: "",
        email: "",
        accountNumber: "",
        startDate: ""
    });

    const handleOpen = () => {
        setAdding(true);
        setAccount({
            // contactPhone: "",
            mpesaPhone: "",
            firstName: "",
            lastName: "",
            email: "",
            accountNumber: "",
            startDate: new Date().toString()
        });
    };

    const handleOpenUploadCSV = () => {
        setUploadingCSV(true);
    };

    return(
        <div className="add-service-wrap">
            {
                adding && (
                    <AddClient
                        edit={false}
                        carrier={{ account, setAccount, planId }}
                        adding={{ adding, setAdding }}
                        setMessage={setMessage}
                        refetch={refetch}
                    />
                )
            }
            {
                uploadingCSV && (
                    <UploadCSV
                        carrier={{planId}}
                        uploading={{ uploadingCSV, setUploadingCSV }}
                        setMessage={setMessage}
                        refetch={refetch}
                    />
                )
            }
            <div className="bread-crumb">
                <BreadCrumb />
            </div>
            <div className="add-service">
                <button
                    className="button-action-inverse btn-1"
                    onClick={handleOpenUploadCSV}
                    >
                    <span>Upload {planId ? 'Accounts' : 'Clients'}</span>
                    <span className="material-icons">folder</span>
                </button>
                <button
                    className="button-action btn-1"
                    onClick={handleOpen}
                    >
                        <span>Add {planId ? 'Account' : 'Client'}</span>
                        <span className="material-icons">add</span>
                </button>
            </div>
        </div>
    )
}

export default UploadTopbar;