import { useState, ChangeEvent, FormEvent } from 'react';
import { useMutation } from "react-query";
import { makeStyles } from '@material-ui/core/styles';
import Modal from '@material-ui/core/Modal';
import { MiscDetails } from "../../../models/Account";
import formValidator from "../../../services/formValidator";
import { addMisc } from "../../../data/mutations/account";
import ModalLoader from "../../../common/ModalLoader";
import Notification from "../../../common/Notification";
import { format } from 'date-fns';


interface IAddMisc {
  carrier: { miscValue: MiscDetails, setMiscValue: Function, accountId: string, setAccountId?: Function };
  adding: { addingMisc: boolean, setAddingMisc: Function },
  setMessage: Function;
  refetch: Function;
}

const useStyles = makeStyles((theme) => ({
  paper: {
    position: 'absolute',
    backgroundColor: theme.palette.background.paper,
    borderRadius: '8px',
    padding: theme.spacing(2, 4, 3),
    top: '15%',
    left: '35%',
    '@media (max-width: 780px)': {
      left: '1%',
      padding: theme.spacing(2, 4, 0),
    }
  },
}));

const AddMisc = ({ carrier, adding, setMessage, refetch }: IAddMisc) => {
  const classes = useStyles();

  const [error, setError] = useState('');
  const [formError, setFormError] = useState({
    status: false,
    name: '',
    message: ''
  });

  const { mutate: updateMisc, isLoading } = useMutation(
    ({ accountId, miscValue } : { accountId: string, miscValue: MiscDetails }) => 
    addMisc(accountId, miscValue), {
      onSuccess() {
        adding.setAddingMisc(false);
        setTimeout(() => {
          setMessage('');
        }, 5000);
        setMessage('The cost has been updated!');
        refetch();
      },
      onError(error: {message: string}) {
        setTimeout(() => {
          setError('');
        }, 5000);
        setError(error.message);
      }
  });

  const handleClose = () => {
    adding.setAddingMisc(false);
    carrier.setMiscValue({
      itemName: "",
      amount: 0,
      entryType: "",
      entryDate: new Date()
    });
    carrier.setAccountId && carrier.setAccountId("");
  };

  const EntryTypes: { name: string, value: string }[] = [
    {
      name: 'Debit',
      value: 'debit'
    },
    {
      name: 'Credit',
      value: 'credit'
    }
  ];

  const onChange = (e: ChangeEvent<HTMLInputElement | HTMLSelectElement>) => carrier.setMiscValue({
    ...carrier.miscValue,
    [e.target.name]: e.target.value
  });

  const onChangeDate = (e: ChangeEvent<HTMLInputElement>) => carrier.setMiscValue({
    ...carrier.miscValue,
    [e.target.name]: new Date(e.target.value)
});

  const onSubmit = (e: FormEvent) => {
    e.preventDefault();
    
    const { status, name, message } = formValidator(carrier.miscValue);
        if (status) {
            setTimeout(() => {
                setFormError({
                    status: false,
                    name: '',
                    message: ''
                })
            }, 5000);
            return setFormError({
                status,
                name,
                message 
            });
        }   
        
    updateMisc({ accountId: carrier.accountId, miscValue: carrier.miscValue });
  }

  const body = (
    <div className={`${classes.paper} add-plan dimension-2`}>
      {
        isLoading && (
          <ModalLoader />
        )
      }
      {
        error !== '' && (
          <Notification
            message={error}
            success={false}
            auto={false}
          />
        )
      }
      <div className="title">
          <span>Add Miscellaneous</span>
      </div>
      <div className="body">
          <form onSubmit={onSubmit}>
              <div>
                  <label htmlFor="itemName">
                      Description
                  </label>
                  <input
                    id="itemName"
                    className={`${formError.name === 'itemName' && 'input-error'}`}
                    name="itemName"
                    type="text"
                    placeholder="Cost Description"
                    value={carrier.miscValue.itemName}
                    onChange={onChange}
                  />
                  {
                    formError.name === 'itemName' && (
                      <small className="m-2">{formError.message}</small>
                    )
                  }
              </div>
              <div>
                  <label htmlFor="amount">
                      Enter Amount
                  </label>
                  <input
                    id="amount"
                    className={`${formError.name === 'amount' && 'input-error'}`}
                    name="amount"
                    type="number"
                    placeholder="Enter amount"
                    value={carrier.miscValue.amount}
                    onChange={onChange}
                  />
                  {
                    formError.name === 'amount' && (
                      <small className="m-2">{formError.message}</small>
                    )
                  }
              </div>
              <div>
                  <label htmlFor="entryType">
                      Entry Type
                  </label>
                  <select
                    id="entryType"
                    className={`${formError.name === 'entryType' && 'input-error'}`}
                    name="entryType"
                    value={carrier.miscValue.entryType}
                    onChange={onChange}
                  >
                    <option value="" >Select Entry Type</option>
                    {/* Display the entryType(s) */}
                    {
                      EntryTypes.map((typ, i) => 
                        <option key={i} value={typ.value}>{typ.name}</option>
                        )
                    }
                  </select>
                  {
                    formError.name === 'entryType' && (
                      <small className="m-2">{formError.message}</small>
                    )
                  }       
              </div>
              <div>
                  <label htmlFor="entryDate">
                      Entry Date
                  </label>
                  <input
                    id="entryDate"
                    className={`${formError.name === 'entryDate' && 'input-error'}`}
                    name="entryDate"
                    type="date"
                    value={format(
                      new Date(carrier.miscValue.entryDate),
                      'yyyy-MM-dd'
                    )}
                    onChange={onChangeDate}
                  />
                  {
                    formError.name === 'entryDate' && (
                      <small className="m-2">{formError.message}</small>
                    )
                  }
              </div>
              <div className="footer">
                <button className="button-action btn-2" type="submit">
                    Add Miscellaneous
                </button>
              </div>
          </form>
      </div>
    </div>
  );

  return (
    <div>
      <Modal
        open={adding.addingMisc}
        onClose={handleClose}
        aria-labelledby="simple-modal-title"
        aria-describedby="simple-modal-description"
      >
        {body}
      </Modal>
    </div>
  );
};


export default AddMisc;