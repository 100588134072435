import Table from "@material-ui/core/Table";
import TableBody from "@material-ui/core/TableBody";
import TableCell from "@material-ui/core/TableCell";
import TableRow from "@material-ui/core/TableRow";
import TableHead from "@material-ui/core/TableHead";
import { useHistory, useParams } from "react-router-dom";
import { useAppDispatch, useAppSelector } from "../../../store/hooks";
import {
  getClients,
  getClientsCount,
  loadClients,
} from "../../../store/slices/clientsSlice";
import { IControllers } from "../../dashboard/plan/Plans";
import { ChangeEvent, useState } from "react";
import { loadCount } from "../../../store/slices/planSlice";
import {
  getClientsAdmin as callClients,
  searchAdminClient,
} from "../../../data/queries/admin";
import { useQuery } from "react-query";
import Client from "../../../models/Account";
import { searchClient } from "../../../data/queries/clients";
import paths from "../../../routes/paths";
import { TablePagination } from "@material-ui/core";

const headCells = [
  { id: "cnm", numeric: true, disablePadding: true, label: "Client Name" },
  { id: "cmps", numeric: true, disablePadding: false, label: "Mpesa" },
  { id: "cml", numeric: true, disablePadding: false, label: "Email" },
  { id: "cdctv", numeric: true, disablePadding: false, label: "" },
  { id: "cvwn", numeric: true, disablePadding: false, label: "" },
];

const Clients = () => {
  const history = useHistory();
  const dispatch = useAppDispatch();
  const clients = useAppSelector(getClients);
  const count = useAppSelector(getClientsCount);
  const { companyId } = useParams<{ companyId: string }>();

  const [message, setMessage] = useState("");
  const [searchString, setSearchString] = useState("");
  const [foundClient, setFoundClient] = useState<Client[]>([]);

  const [state, setState] = useState<IControllers>({
    page: 0,
    rowsPerPage: 10,
    limit: 10,
    skip: 0,
    companyId,
  });

  const { data, refetch, isLoading } = useQuery(
    ["get clients", { state }],
    callClients,
    {
      onSuccess(datas: { total: number; clients: Client[] }) {
        dispatch(loadClients(datas.clients));
        dispatch(loadCount(datas.total));
      },
    }
  );

  const manageThrottle = (cb: Function, d: number) => {
    let lastTime = 0;

    return (...args: any) => {
      let now = new Date().getTime();

      if (now - lastTime < d) return;
      lastTime = now;
      return cb(...args);
    };
  };

  const onSearchChange = (e: ChangeEvent<HTMLInputElement>) => {
    setSearchString(e.target.value);

    manageThrottle(async () => {
      let resp = await searchAdminClient(searchString);

      if (resp) {
        setFoundClient(resp.clients);
      }
    }, 1000)();
  };

  const handleChangePage = (event: any, newPage: any) => {
    setState({
      ...state,
      page: newPage,
      skip: newPage * state.limit,
    });
    refetch();
  };

  const handleChangeRowsPerPage = (event: any) => {
    setState({
      ...state,
      rowsPerPage: parseInt(event.target.value, 5),
      page: 1,
      limit: parseInt(event.target.value, 5),
      skip: 0,
    });
    refetch();
  };
  
  return (
    <main className="">
      <div className="header-section">
        <div className="table-search ">
          <div className="left">
            <div className="custom">
              <span className="material-icons">search</span>
              <input
                type="text"
                placeholder="Search"
                value={searchString}
                onChange={onSearchChange}
              />
            </div>
              {/* absolute end */}
          <div
            className={`left-dropdown ${
              searchString.length > 0 ? "view-drop" : "hide-drop"
            }`}
          >
            {foundClient.length > 0 && (
              foundClient.map((client, index) => (
                <p key={index}  onClick={() => {
                    dispatch(loadClients(foundClient.filter(fc => fc.id === fc.id)));
                    dispatch(loadCount(0));
                    setSearchString('');
                }}  >
                  <span className="material-icons">search</span>
                  <span className="span1">{`${client.firstName} ${client.lastName ?? ''}`}</span>
                </p>
              ))
            ) }
          </div>

          {/* absolute end */}
          </div>
        
        </div>
      </div>
      <div className="tables">
        <Table
          aria-labelledby="tableTitle"
          aria-label="enhanced table"
          className="table"
        >
          <TableHead className="table-header">
            <TableRow>
              <TableCell
                style={{ position: "absolute", border: "none" }}
              ></TableCell>
              {headCells.map((headCell: any) => (
                <TableCell
                  className="cell"
                  key={headCell.id}
                  align="left"
                  padding={headCell.disablePadding ? "none" : "normal"}
                >
                  {headCell.label}
                </TableCell>
              ))}
            </TableRow>
          </TableHead>
          {/* {
                                    isLoading && (
                                        <DashLoader />
                                    )
                                } */}
          <TableBody>
            {clients.map((row: Client, index: number) => {
              return (
                <TableRow hover tabIndex={-1} key={row.id}>
                  <TableCell></TableCell>
                  <TableCell
                    className="clickable"
                    align="left"
                    // onClick={() => history.push(paths.viewclient.replace(':clientId', `${row.id}`))}
                  >
                    {/* {capitalize(row.firstName)} {capitalize(row.lastName)} */}
                    {row.firstName} {row.lastName}
                  </TableCell>
                  <TableCell align="left">{row.mpesaPhone}</TableCell>
                  <TableCell align="left">{row.email}</TableCell>
                  <TableCell className="activate" align="left">
                    <button
                      className={`${row.isActive ? "act" : "deact"}`}
                      onClick={() => {
                        // setId(row.id!);
                        // activatingClient({ id: row.id!, isActive: !row.isActive })
                      }}
                    >
                      <span className="material-icons">circle</span>
                      <span>Activate</span>
                    </button>
                  </TableCell>
                  <TableCell
                    className="edit"
                    align="center"
                    onClick={() => history.push(paths.viewadminclient.replace(':clientId', `${row.id}`))}
                  >
                    View
                  </TableCell>
                </TableRow>
              );
            })}
          </TableBody>
        </Table>
        <div className="pagination">
        <TablePagination
          component="div"
          count={count}
          page={state.page}
          onPageChange={handleChangePage}
          rowsPerPage={state.rowsPerPage}
          onRowsPerPageChange={handleChangeRowsPerPage}
        />
      </div>
      </div>
    </main>
  );
};

export default Clients;
