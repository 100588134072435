import React from "react";
import Spinner from "../assets/images/spinner.gif";


const ImageUpload = () => {
    return(
        <div className="dash-loader">
            <div className="dash-spinner">
                <img
                    src={Spinner}
                    alt='Loading...'
                    className="image-loader"
                    />
            </div>
        </div>
    )
}

export default ImageUpload;