import axios from "axios";
import { baseUrl } from "./base";
import { IDemo } from "../components/demo/DemoPage";

const demoApi = {
    sendSTKDemo: (payload: IDemo) => {
        return axios.post(`${baseUrl}/demo`, payload);
    }
}

export default demoApi;