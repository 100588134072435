import { Modal } from "@material-ui/core";
import { useStyles } from "../dashboard/account/UploadCSV";
import { ChangeEvent, useState } from "react";
import { useMutation } from "react-query";
import { io } from "socket.io-client";
import { sendSTK } from "../../data/mutations/client";
import { useParams } from "react-router-dom";
import { useCompany } from "../../utils/hooks";
import ModalLoader from "../../common/ModalLoader";
import VerifyLoader from "../../common/VerifyLoader";
import Notification from "../../common/Notification";
import ErrorImage from "../../assets/images/PayFail.png";
import SuccessImage from "../../assets/images/PaySuccess.png";

interface IMpesaService {
  mpesa: {
    mpesa: boolean;
    setMpesa: Function;
    systemAccountNumber: string;
    refetch: Function;
  };
  setMessage: Function;
}

export interface IMpesaPayload {
  mpesaPhone: string;
  amount: number;
}

const MpesaModel = ({ mpesa, setMessage }: IMpesaService) => {
  const classes = useStyles();
  const { accountId } = useParams<{ accountId: string }>();
  const isCompany = useCompany();
  const [phoneNumber, setPhoneNumber] = useState<string>("");
  const [amount, setAmount] = useState(0);
  const [error, setError] = useState({
    phoneNumber: "",
    amount: "",
  });
  const [viewInput, setViewInput] = useState(true);
  const [errorResp, setErrorResp] = useState(false);
  const [succResp, setSuccResp] = useState(false);
  const [errorPushing, setErrorPushing] = useState("");
  const [successPushing, setSuccessPushing] = useState("");
  const [verifying, setVerifying] = useState(false);

  const onChange = (e: ChangeEvent<HTMLInputElement>) =>
    setPhoneNumber(e.target.value);
  const onChangeAmount = (e: ChangeEvent<any>) => setAmount(e.target.value);

  const { mutate: sendMpesaSTK, isLoading } = useMutation(
    () =>
      sendSTK(isCompany, accountId, {
        mpesaPhone: `+${phoneNumber}`,
        amount: Number(amount),
      }),
    {
      onSuccess(datas: { customerMessage: string }) {
        setTimeout(() => {
          setSuccessPushing("");
        }, 5000);
        setVerifying(true);
        setViewInput(false);
        setSuccessPushing(datas.customerMessage);
      },
      onError(error: { message: string }) {
        setTimeout(() => {
          setErrorPushing("");
        }, 5000);
        setErrorPushing(error.message);
      },
    }
  );

  const calcTotal = (amount: number) => {
    let returnValue = {
      serviceFee: 0,
      total: 0,
    };

    const fee = Math.round((1.5 / 100) * amount * 100) / 100;
    returnValue.serviceFee = fee;
    returnValue.total = Number(amount) + Number(fee);

    return returnValue;
  };

  const body = (
    <div className={`${classes.paper} add-plan dimension-1`}>
      {isLoading && <ModalLoader />}
      {errorPushing !== "" && (
        <Notification message={errorPushing} success={false} auto={true} />
      )}
      {successPushing !== "" && (
        <Notification message={successPushing} success={true} auto={true} />
      )}
      {verifying && (
        <div>
          <div className="title-mpesa">Waiting for Payment</div>
          <div className="status-section">
            <span>We are verifying your payment</span>
            <VerifyLoader />
          </div>
        </div>
      )}
      {errorResp && (
        <div>
          <div className="title-mpesa">
            <span>Payment Failed!</span>
          </div>
          <div className="status-section">
            <span>We are sorry but your payment wasn't successful</span>
            <img src={ErrorImage} alt="" />
            <div className="buttons">
              <button
                className="btns1"
                onClick={() => {
                  setErrorResp(false);
                  setViewInput(true);
                }}
              >
                Retry
              </button>
              <button
                className="btns2"
                onClick={() => {
                  mpesa.setMpesa(false);
                }}
              >
                Cancel
              </button>
            </div>
          </div>
        </div>
      )}
      {succResp && (
        <div>
          <div className="title-mpesa">
            <span>Payment Success</span>
          </div>
          <div className="status-section">
            <span>We've received your payment</span>
            <img src={SuccessImage} alt="" />
            <p>Redirecting...</p>
          </div>
        </div>
      )}
      {viewInput && (
        <div>
          <div className="title-mpesa">
            <span>Make Payments</span>
          </div>
          <div className="mpesa-body">
            <label htmlFor="phoneNumber">
              To make payment enter M-pesa number
            </label>
            <input
              id="phoneNumber"
              placeholder="254xxxxxxxx"
              className={`${error.phoneNumber !== "" && "input-error"}`}
              name="phoneNumber"
              type="text"
              value={phoneNumber}
              onChange={onChange}
            />
            {error.phoneNumber !== "" && (
              <small className="error">{error.phoneNumber}</small>
            )}
            <label htmlFor="amount">Please enter amount</label>
            <input
              id="amount"
              className={`${error.amount !== "" && "input-error"}`}
              name="amount"
              type="number"
              value={amount}
              onChange={onChangeAmount}
            />
            {error.amount !== "" && (
              <small className="error">{error.amount}</small>
            )}
            {amount > 0 && (
              <div className="disclaimer">
                <small>
                  You are about to pay {amount} and a service fee of{" "}
                  {calcTotal(amount).serviceFee}
                </small>
                <small>Total: {calcTotal(amount).total}/=</small>
              </div>
            )}
            <div className="buttons">
              <button
                className="btn btn1"
                onClick={() => {
                  if (phoneNumber === "") {
                    setError({
                      ...error,
                      phoneNumber: "Please Enter M-pesa Number",
                    });
                    return setTimeout(() => {
                      setError({
                        ...error,
                        phoneNumber: "",
                      });
                    }, 3000);
                  }
                  if (isNaN(Number(phoneNumber))) {
                    setError({
                      ...error,
                      phoneNumber: "Please Enter Digits Only",
                    });
                    return setTimeout(() => {
                      setError({
                        ...error,
                        phoneNumber: "",
                      });
                    }, 3000);
                  }
                  if (String(phoneNumber).slice(0, 3) !== "254") {
                    setError({
                      ...error,
                      phoneNumber: "Please Begin with 254",
                    });
                    return setTimeout(() => {
                      setError({
                        ...error,
                        phoneNumber: "",
                      });
                    }, 3000);
                  }
                  if (
                    String(phoneNumber).slice(3, phoneNumber.length).length !==
                    9
                  ) {
                    setError({
                      ...error,
                      phoneNumber: "Please Confirm your Number",
                    });
                    return setTimeout(() => {
                      setError({
                        ...error,
                        phoneNumber: "",
                      });
                    }, 3000);
                  }
                  if (Number(amount) === 0) {
                    setError({
                      ...error,
                      amount: "Please Enter the Amount",
                    });
                    return setTimeout(() => {
                      setError({
                        ...error,
                        amount: "",
                      });
                    }, 3000);
                  }

                  const authToken = localStorage.__pangaMalipo__;
                  const socket = io(
                    "https://api.pangamalipo.co.ke/payment-status",
                    {
                      transports: ["websocket"],
                      auth: {
                        token: `${authToken}`,
                      },
                    }
                  );

                  socket.on("connect", () => {
                    console.log("Connected to WebSocket server");
                    socket.on("error", (error) => {
                      console.error("WebSocket connection error:", error);
                    });

                    const accountNumber = mpesa.systemAccountNumber;

                    socket.emit("join", accountNumber, (response: any) => {
                      console.log(response);
                    });
                  });

                  socket.on("paymentFailure", (data) => {
                    console.log("Payment failure:", data);
                    const accountNumber = mpesa.systemAccountNumber;
                    socket.emit("leave", accountNumber, (response: any) => {
                      console.log(response);
                    });
                    setErrorResp(true);
                    setVerifying(false);
                  });

                  socket.on("paymentSuccess", (data) => {
                    console.log(data);
                    console.log("Payment success:", data);
                    const accountNumber = mpesa.systemAccountNumber;
                    socket.emit("leave", accountNumber, (response: any) => {
                      console.log(response);
                    });
                    setTimeout(() => {
                      setSuccResp(false);
                      setViewInput(true);
                      mpesa.refetch();
                      mpesa.setMpesa(false);
                      socket.on("disconnect", () => {
                        console.log("Disconnected from WebSocket server");
                      });
                    }, 5000);
                    setSuccResp(true);
                    setViewInput(false);
                    setVerifying(false);
                  });

                  // socket.on('disconnect', () => {
                  //     console.log('Disconnected from WebSocket server');
                  // });

                  sendMpesaSTK();
                }}
              >
                Send STK Push
              </button>
              <button className="btn btn2" onClick={() => handleClose()}>
                Cancel
              </button>
            </div>
          </div>
        </div>
      )}
    </div>
  );
  const handleClose = () => {
    mpesa.setMpesa(false);
  };

  return (
    <div>
      <Modal
        open={mpesa.mpesa}
        onClose={handleClose}
        aria-labelledby="simple-modal-title"
        aria-describedby="simple-modal-description"
      >
        {body}
      </Modal>
    </div>
  );
};

export default MpesaModel;
