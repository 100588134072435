import Spinner from "../../assets/images/spinner.gif";
import { useQuery } from "react-query";
import Company from "../../models/Company";
import { useDispatch } from "react-redux";
import { getCompanies, loadCompanies } from "../../store/slices/clientSlice";
import { getCompanies as callCompanies } from "../../data/queries/client";
import { useAppSelector } from "../../store/hooks";
import paths from "../../routes/paths";
import { useHistory } from "react-router-dom";


const ClientDashboard = () => {
    const dispatch = useDispatch();
    const history = useHistory();
    const companies = useAppSelector(getCompanies);

    const { isLoading } = useQuery(['get companies'], callCompanies, {
        onSuccess(datas: Company[]){
            dispatch(loadCompanies(datas));
        }
    });

    return (
        <div className="client-dashboard">
            <div className="wrapper">
                <div>
                    <h4>Residences</h4>
                </div>
                {
                    isLoading && (
                        <div className="loader">
                            <img
                                src={Spinner}
                                alt=""
                            />
                        </div>
                    )
                }
                <div className="companies">
                    {
                        companies && companies.length > 0 && companies.map((company, i) => 
                            <div
                                className="card"
                                key={i}
                                onClick={() => history.push(paths.companyaccounts.replace(':companyId', `${company.id}`))}
                                >
                                <img
                                    src={company.logoUrl}
                                    alt=""
                                />
                                <div className="typo">{company.name}</div>
                            </div>
                        )
                    }
                </div>
            </div>
        </div>
    );
}

export default ClientDashboard;