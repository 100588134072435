import axios from "axios";
import { ILogin } from "../components/auth/Login";
import { baseUrl } from "./base";
import { IChangePassword } from "../components/auth/ChangePassword";


const authApi = {
    signup: (company: any) => {
        return axios.post(`${baseUrl}/auth/create-account`, company);
    },
    login: (company: ILogin) => {
        return axios.post(`${baseUrl}/auth/login`, company);
    },
    activateEmail: (token: string) => {
        return axios.patch(`${baseUrl}/auth/verify-email`, { confirmationCode: token });
    },
    forgotPassword: (payload: { email: string }) => {
        return axios.post(`${baseUrl}/auth/password-reset-request`, payload);
    },
    resetPassword: (password: { newPassword: string }, token: string) => {
        return axios.patch(`${baseUrl}/auth/reset-password`, { newPassword: password.newPassword, passwordResetToken: token });
    },
    sendEmail: () => {
        return axios.post(`${baseUrl}/users/sendEmail`);
    },
    sendSms: () => {
        return axios.post(`${baseUrl}/users/sendSms`);
    },
    verifyCode: (code: string) => {
        return axios.patch(`${baseUrl}/users/smsVerify/${code}`);
    },
    changePassword: (payload: IChangePassword) => {
        return axios.patch(`${baseUrl}/auth/change-password/`, payload);
    }
}

export default authApi;