import clientsApi from "../../api/clients";
import Account, { CompanyAccount } from "../../models/Account";
import errorHandler from "../../services/errorHandler";


const getClients = async(params: any) => {
    const [, { state }] = params.queryKey;

    try {
        let resp = await (await clientsApi.getClients(state)).data.data as Account[];        
        return resp;
    } catch (error) {
        errorHandler(error)
    }
}

const getClientAccounts = async(params: any) => {
    const [, { clientId }] = params.queryKey;
    
    try {
        let resp = await (await clientsApi.getClientAccounts(clientId)).data.data as CompanyAccount[];             
        return resp;
    } catch (error) {
        errorHandler(error)
    }
}

const searchClient = async(searchString: string) => {
    try {
        let resp = await (await clientsApi.searchClient(searchString)).data.data;
        return resp;
    } catch (error) {
        errorHandler(error);
    }
}

export { getClients, getClientAccounts, searchClient };