import React from "react";

const AuthNotification = ({ title, message, success, auto }: { title: string, message: string, success: string, auto: boolean }) => {
    return(
        <div className={`notification-wrap ${!auto && 'auto-margin'}`}>
            <div
                className={`notification-inner ${auto && 'auto-position'} ${success}`}
            >
                <div className="title">
                    {title}
                </div>
                <div className="message">
                    {message}
                </div>
            </div>
        </div>
    );
};

export default AuthNotification;