/* eslint-disable array-callback-return */
import React, { useState, ChangeEvent, FormEvent } from "react";
import { motion } from "framer-motion";
import { useMutation } from "react-query";
import { useHistory } from "react-router-dom";
import Checkbox from '@material-ui/core/Checkbox';
import ReactFlagsSelect from 'react-flags-select';
import Company from "../../models/Company";
import AuthNotification from "../../common/AuthNotification";
import formValidator from "../../services/formValidator";
import { signup } from "../../data/mutations/auth";
import paths from "../../routes/paths";
import Loading from "../../common/Loading";
import { useMediaQuery } from "@material-ui/core";
// import Countries from "../../assets/data/countries";

const Signup = () => {
    const matches = useMediaQuery('(max-width:1024px)');
    const [state, setState] = useState<Company>({
        name: "",
        email: "",
        phoneNumber: "",
        acceptedTermsAndConditions: false,
        password: ""
      });
    const [selected] = useState('KE');
    const [code] = useState('+254');
    const [formError, setFormError] = useState({
        status: false,
        name: '',
        message: ''
    });
    const [confirmPassword, SetConfirmPassword] = useState('');
    const [error, setError] = useState('');
    const [visiblePassword, setVisiblePassword] = useState(false);
    const [visibleConfirmPassword, setVisibleConfirmPassword] = useState(false);

    const history = useHistory();

    const { mutate: signUp, isLoading } = useMutation(signup, {
            onError(error: {message: string}) {
                setTimeout(() => {
                    setError('');
                }, 5000);
                setError(error.message);      
            },
            onSuccess() {
                history.push(paths.login);
                localStorage.setItem('__loginInitial__', 'yes');
            }
    });
    
    const onChange = (e: ChangeEvent<HTMLInputElement>) => {
        if (e.target.name === 'shortCode' && isNaN(Number(e.target.value))) {
            setTimeout(() => {
                setFormError({
                    status: false,
                    name: '',
                    message: ''
                })
            }, 5000);
            return setFormError({
                status: true,
                name: 'shortCode',
                message: 'Value must be a digit'
            })
        }
        setState({
            ...state,
            [e.target.name]: e.target.value
        });
    }

    const onSubmit = (e: FormEvent) => {
        e.preventDefault();

        const { status, name, message } = formValidator(state);
        if (status) {
            setTimeout(() => {
                setFormError({
                    status: false,
                    name: '',
                    message: ''
                })
            }, 5000);
            return setFormError({
                status,
                name,
                message 
            });
        }

        if (confirmPassword === '') {
            setTimeout(() => {
                setFormError({
                    status: false,
                    name: '',
                    message: ''
                })
            }, 5000);
            return setFormError({
                status: true,
                name: 'confirm-password',
                message: 'Confirm password cannot be empty' 
            });
        }
        
        if (state.password !== confirmPassword) {
            setTimeout(() => {
                setFormError({
                    status: false,
                    name: '',
                    message: ''
                })
            }, 5000);
            return setFormError({
                status: true,
                name: 'confirm-password',
                message: 'Passwords do not match' 
            });
        }

        if (!state.acceptedTermsAndConditions) {
            setTimeout(() => {
                setFormError({
                    status: false,
                    name: '',
                    message: ''
                })
            }, 5000);
            return setFormError({
                status: true,
                name: 'terms',
                message: 'Please accept Terms and Conditions' 
            });
        }

        const company = {
            name: state.name,
            email: state.email,
            phoneNumber: `${code}${state.phoneNumber}`,
            acceptedTermsAndConditions: state.acceptedTermsAndConditions,
            password: state.password,
            kraPin: 'aaa29l'
        }
        // send the request
        signUp(company);
    }

    return(
        <motion.div
            className="auth-right"
            animate={{ x: matches ? 0 : 40 }}
            transition={{ ease: 'easeOut' }}
        >
            {
                isLoading && <Loading />
            }
            {
                error !== '' && (
                    <AuthNotification
                        title='Error'
                        message={error}
                        success={'error'}
                        auto={true}
                    />
            )}
             {
                formError.name === 'terms' && (
                    <AuthNotification
                        title='Error'
                        message={formError.message}
                        success={'error'}
                        auto={true}
                    />
            )}
             {
                formError.name === 'terms' && (
                    <AuthNotification
                        title='Error'
                        message={formError.message}
                        success={'error'}
                        auto={true}
                    />
            )}
            <div className="sign-padding">
                <div className="top">
                    <h3>Create an Account</h3>
                    <p>Please enter company details</p>
                </div>
                <form onSubmit={onSubmit}>
                    <div className="center">
                        <div>
                            <label>Name</label>
                            <input
                                className={`${formError.name === 'name' && 'input-error'}`}
                                name="name"
                                placeholder="Enter Name"
                                type="text"
                                value={state.name}
                                onChange={onChange}
                            />
                            {
                                formError.name === 'name' && (
                                    <small>{formError.message}</small>
                                )
                            }
                        </div>             
                        <div>
                            <label>Email</label>
                            <input
                                className={`${formError.name === 'email' && 'input-error'}`}
                                name="email"
                                placeholder="Enter Email"
                                type="text"
                                value={state.email}
                                onChange={onChange}
                            />
                            {
                                formError.name === 'email' && (
                                    <small>{formError.message}</small>
                                )
                            }
                        </div>
                        <div>
                            <label>Telephone Number</label>
                            <div className="flag">
                                <ReactFlagsSelect
                                    selected={selected}
                                    onSelect={code => {
                                        // setSelected(code);
                                        // const found = Countries.find((element: { isoCode: string; }) => element.isoCode === code);
                                        // setCode(found.dialCode);
                                    }}
                                    fullWidth={false}
                                    optionsSize={13}
                                    selectedSize={13}
                                />
                                <div className="input-wrap">
                                    <small className="code-input">{code}</small>
                                    <input
                                        className={`phone-input ${formError.name === 'phoneNumber' && 'input-error'}`}
                                        name="phoneNumber"
                                        placeholder="7XXXXXXXX"
                                        type="text"
                                        value={state.phoneNumber}
                                        onChange={onChange}
                                    />
                                </div>
                            </div>
                            {
                                formError.name === 'phoneNumber' && (
                                    <small>{formError.message}</small>
                                )
                            }
                        </div>
                        <div className="password-wrap">
                            <label>Password</label>
                            <input
                                className={`${formError.name === 'password' && 'input-error'}`}
                                name="password"
                                placeholder="Password"
                                type={visiblePassword ? "text" : "password"}
                                value={state.password}
                                onChange={onChange}
                            />
                            {
                                formError.name === 'password' && (
                                    <small>{formError.message}</small>
                                )
                            }
                            {
                                visiblePassword ? (
                                    <div className="input-pass-icon">
                                        <span className="material-icons" onClick={() => setVisiblePassword(!visiblePassword)}>visibility_off</span>
                                    </div>
                                ) : (
                                    <div className="input-pass-icon">
                                        <span className="material-icons" onClick={() => setVisiblePassword(!visiblePassword)}>visibility</span>
                                    </div>
                                )
                            }
                        </div>
                        <div className="password-wrap">
                            <label>Confirm Password</label>
                            <input
                                className={`${formError.name === 'confirm-password' && 'input-error'}`}
                                name="confirmPassword"
                                placeholder="Confirm Password"
                                type={visibleConfirmPassword ? "text" : "password"}
                                value={confirmPassword}
                                onChange={(e) => SetConfirmPassword(e.target.value)}
                            />
                            {
                                formError.name === 'confirm-password' && (
                                    <small>{formError.message}</small>
                                )
                            }
                            {
                                visibleConfirmPassword ? (
                                    <div className="input-pass-icon">
                                        <span className="material-icons" onClick={() => setVisibleConfirmPassword(!visibleConfirmPassword)}>visibility_off</span>
                                    </div>
                                ) : (
                                    <div className="input-pass-icon">
                                        <span className="material-icons" onClick={() => setVisibleConfirmPassword(!visibleConfirmPassword)}>visibility</span>
                                    </div>
                                )
                            }
                        </div>
                    </div>
                    <div className="bottom">
                        <div className="accept">
                            <span>
                                <Checkbox
                                    inputProps={{ 'aria-label': 'primary checkbox' }}
                                    onChange={(e) => {
                                        setState({
                                            ...state,
                                            acceptedTermsAndConditions: e.target.checked
                                        })
                                    }}
                                    />
                            </span>
                            <span className="terms"> Accept to the <b>Terms and Conditions</b></span>
                        </div>
                        <div>
                            <button className="button-primary btn-w-2" type="submit">
                                Next
                            </button>
                            <span className="terms forgot-pass">
                                <span onClick={() => history.push(paths.login)}>Login</span>
                            </span>
                        </div>
                    </div>
                </form>
            </div>               
        </motion.div>
    )
}

export default Signup;