import authApi from "../../api/auth";
import errorHandler from "../../services/errorHandler";
import { LOGGIN, LOGOUT } from "../../store/actions/types";
import setAuthToken from "../../services/setAuthToken";
import { ILogin } from "../../components/auth/Login";
import jwtDecode from "jwt-decode";
import { IToken } from "../..";
import { IChangePassword } from "../../components/auth/ChangePassword";
import paths from "../../routes/paths";


const signup = async(company: any) => {
    try {
        let resp = await (await authApi.signup(company)).data;
        return resp;
    } catch (error) {
        errorHandler(error)
    }
}

const login = async(company: ILogin, dispatch: Function) => {
    try {
        let resp = await (await authApi.login(company)).data;
        let token = resp.data.accessToken;        
        let passStatus = resp.data.payload.changePassword;
        
        const decoded: IToken = jwtDecode(token);
        setAuthToken(token);
        dispatch({
            type: LOGGIN,
            payload: decoded
        });
        localStorage.setItem('__pangaMalipo__', token);
        if (!passStatus) {
            localStorage.setItem('__passStatus__', `passStatus`);            
        }

        return resp;
    } catch (error) {
        errorHandler(error)
    }
}

const logout = (dispatch: Function) => {
    dispatch({
        type: LOGOUT,
        payload: null
    });
    localStorage.removeItem('__pangaMalipo__');
    localStorage.removeItem('__passStatus__');
}

const forgotPassword = async(payload: { email: string }) => {
    try {
        let resp = await (await authApi.forgotPassword(payload)).data;
        return resp;
    } catch (error) {
        errorHandler(error)
    }
}

const resetPassword = async(password: { newPassword: string }, token: string) => {
    try {
        let resp = await (await authApi.resetPassword(password, token)).data;
        return resp;
    } catch (error) {
        errorHandler(error)
    }
}

const sendEmail = async() => {
    try {
        let resp = await (await authApi.sendEmail()).data;
        return resp;
    } catch (error) {
        errorHandler(error)
    }
}

const sendSms = async() => {
    try {
        let resp = await (await authApi.sendSms()).data;
        return resp;
    } catch (error) {
        errorHandler(error)
    }
}

const verifyCode = async(code: string) => {
    try {
        let resp = await (await authApi.verifyCode(code)).data;
        return resp;
    } catch (error) {
        errorHandler(error)
    }
}

const changePassword = async(payload: IChangePassword, history: any) => {
    try {
        let resp = await (await authApi.changePassword(payload)).data;
        if (resp) {
            if (localStorage.__passStatus__) {
                localStorage.removeItem('__passStatus__');                
            }
            history.push(paths.clientdashboard);
        }
        return resp;
    } catch (error) {
        errorHandler(error)
    }
}

export { signup, login, forgotPassword, resetPassword, logout, sendEmail, sendSms, verifyCode, changePassword };