const paths = {
    landing: '/',
    signup: '/signup',
    login: '/login',
    verify: '/verify',
    renewsub:'/renewal/payment/:mpesaPhone/:balance/:companyName/:emailData',
    renewsubsuccess: '/renewal/payment/success',
    emailnotification: '/users/verify/:token',
    smsnotification: '/sms-notification',
    forgotpassword: '/forgot-password',
    resetpassword: '/users/reset/:token',
    changepassword: '/change-password',
    plans: '/plans',
    accounts: '/accounts/:planId',
    about: '/about',
    blog: 'blog',
    careers: '/careers',
    support: '/support',
    editprofile: '/editprofile',
    clients: '/clients',
    viewclient: '/viewclient/:clientId',
    clientplanaccounts: '/client/:planId/:clientId/accounts',
    // clients
    clientdashboard: '/client/dashboard',
    companyaccounts: '/company/accounts/:companyId',
    ledger: '/company/ledger/:accountId',
    viewledger: '/viewledger/ledger/:accountId',
    accledger:'/viewledger/:accountId',
    // demo
    demo: '/demo',
    // admin
    admindashboard: '/admin/dashboard',
    adminsinglecompany: '/admin/company/:companyId',
    adminviewpackage: '/admin/company/package/:planId',
    viewadminclient: '/viewadminclient/:clientId',

}

export default paths;