import clientApi from "../../api/client";
import { CompanyAccount, Ledger } from "../../models/Account";
import Company from "../../models/Company";
import errorHandler from "../../services/errorHandler";


const getCompanies = async() => {    
    try {
        let resp = await (await clientApi.getCompanies()).data.data as Company[];        
        return resp;
    } catch (error) {
        errorHandler(error)
    }
}

const getCompanyAccounts = async(params: any) => {    
    const [, { companyId }] = params.queryKey;
    try {
        let resp = await (await clientApi.getCompanyAccounts(companyId)).data.data as CompanyAccount[];        
        return resp;
    } catch (error) {
        errorHandler(error)
    }
}

const getLedger = async(params: any) => {    
    const [, { accountId }] = params.queryKey;
    try {
        let resp = await (await clientApi.getLedger(accountId)).data.data as Ledger;        
        return resp;
    } catch (error) {
        errorHandler(error)
    }
}

export { getCompanies, getCompanyAccounts, getLedger };