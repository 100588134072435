import React, { useState } from 'react';
import 'react-calendar/dist/Calendar.css';
import dayjs from 'dayjs'; // Import dayjs for date manipulation
import MyCalendar from './MyCalendar'; // Import your custom calendar component

const DateRangePicker = () => {
    const [startDate, setStartDate] = useState<Date | null>(null);
    const [endDate, setEndDate] = useState<Date | null>(null);
    const [showDatePicker, setShowDatePicker] = useState<boolean>(false);

    const toggleDatePicker = () => {
        setShowDatePicker(!showDatePicker);
    };

    const handleDateChange = (startDate: Date, endDate: Date) => {
        setStartDate(startDate);
        setEndDate(endDate);
    };

    const handleApply = () => {
        console.log('Selected date range:', startDate, endDate);
        setShowDatePicker(false);
        // Perform any additional actions here
    };

    const formatDate = (date: Date | null) => {
        return date ? dayjs(date).format('MMM D, YYYY') : ''; // Format date using dayjs
    };

    return (
        <div className="relative inline-block">
            <input
                type="text"
                readOnly
                className="appearance-none border rounded w-48 py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline"
                placeholder="Select a date range"
                value={startDate && endDate ? `${formatDate(startDate)} - ${formatDate(endDate)}` : ''}
                onClick={toggleDatePicker}
            />
            {showDatePicker && (
                <div className="absolute bg-white border rounded mt-2 shadow-lg">
                    <div className="flex justify-between px-4 py-2 border-b">
                        <button
                            className="text-gray-700 hover:text-gray-900"
                            onClick={() => handleDateChange(new Date(), new Date())} // Pass current date
                        >
                            Today
                        </button>
                        <button
                            className="text-gray-700 hover:text-gray-900"
                            onClick={toggleDatePicker}
                        >
                            Close
                        </button>
                    </div>
                    <div className="p-4">
                        {/* Render the MyCalendar component */}
                        {/* <MyCalendar
                            onChange={handleDateChange}
                            startDate={startDate}
                            endDate={endDate}
                        /> */}
                        <button className="btn btn-primary mt-2" onClick={handleApply}>
                            Apply
                        </button>
                    </div>
                </div>
            )}
        </div>
    );
};

export default DateRangePicker;
