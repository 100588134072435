import { combineReducers } from "redux";
import auth from "./reducers/auth";
import profile from "./slices/profileSlice";
import plan from "./slices/planSlice";
import account from "./slices/accountSlice";
import client from "./slices/clientSlice";
import clients from "./slices/clientsSlice";
import admin from "./slices/adminSlice";

const rootReducer = combineReducers({
    auth,
    profile,
    plan,
    account,
    client,
    clients,
    admin
});

export default rootReducer;

export type RootState = ReturnType<typeof rootReducer>;