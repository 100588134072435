import profileApi from "../../api/profile";
import errorHandler from "../../services/errorHandler";
import { ICompanyEdit } from "../../models/Profile";

const editLogo = async(logo: any) => {
    
    try {
        let resp = await (await profileApi.editLogo(logo)).data.data;        
        return resp;
    } catch (error) {
        errorHandler(error)
    }
}

const editProfile = async(id: string, profile: ICompanyEdit) => {
    
    try {
        let resp = await (await profileApi.editProfile(id, profile)).data.data;        
        return resp;
    } catch (error) {
        errorHandler(error)
    }
}

export { editLogo, editProfile };