import React, { useState, ChangeEvent, FormEvent } from "react";
import { useMutation } from "react-query";
import { useParams, useHistory } from "react-router-dom";
import { motion } from "framer-motion";
import AuthNotification from "../../common/AuthNotification";
import { resetPassword } from "../../data/mutations/auth";
import formValidator from "../../services/formValidator";
import paths from "../../routes/paths";
import Loading from "../../common/Loading";


const ResetPassword = () => {
    const [state, setState] = useState({
        newPassword: ''
    });

    const [formError, setFormError] = useState({
        status: false,
        name: '',
        message: ''
    });

    const [passwordConfirm, setPasswordConfirm] = useState('');

    const [success, setSuccess] = useState('');
    const [visiblePassword, setVisiblePassword] = useState(false);
    const [visibleConfirmPassword, setVisibleConfirmPassword] = useState(false);

    const { token } = useParams<{ token: string }>();
    const history = useHistory();

    const { mutate: resetPass, isLoading } = useMutation(
        () =>
        resetPassword(state, token), {
          onSuccess(){
            setTimeout(() => {
                history.push(paths.login)
            }, 5000);
            setSuccess('The password reset was successful');
        }}
    );

    const onChange = (e: ChangeEvent<HTMLInputElement>) => setState({
        ...state,
        [e.target.name]: e.target.value
    });

    const onSubmit = (e: FormEvent) => {
        e.preventDefault();

        const { status, name, message } = formValidator(state);
        if (status) {
            setTimeout(() => {
                setFormError({
                    status: false,
                    name: '',
                    message: ''
                })
            }, 5000);
            return setFormError({
                status,
                name,
                message 
            });
        }
        // validate confirm password
        if (passwordConfirm === '') {
            setTimeout(() => {
                setFormError({
                    status: false,
                    name: '',
                    message: ''
                })
            }, 5000);
            return setFormError({
                status: true,
                name: 'passwordConfirm',
                message: 'Password confirm cannot be empty'
            });
        }
        if (passwordConfirm !== state.newPassword) {
            setTimeout(() => {
                setFormError({
                    status: false,
                    name: '',
                    message: ''
                })
            }, 5000);
            return setFormError({
                status: true,
                name: 'passwordConfirm',
                message: 'Passwords do not match'
            });
        }
        // send request
        resetPass()
    }

    return(
        <motion.div
            className="auth-right"
            animate={{ x: 40 }}
            transition={{ ease: 'easeOut' }}
        >
            {
                isLoading && <Loading />
            }
            {
                success !== '' && (
                    <AuthNotification
                        title="Success"
                        message={success}
                        success={'success'}
                        auto={true}
                    />
            )}
            <div className="verify-middle">
                {
                    true && (
                        <AuthNotification
                            title="Reset password"
                            message="Please reset your password here"
                            success={'default'}
                            auto={false}
                        />
                )}
                <form onSubmit={onSubmit}>
                    <div className="center">
                        <div className="password-wrap">
                            <label htmlFor="newPassword">Password</label>
                            <input
                                className={`${formError.name === 'newPassword' && 'input-error'}`}
                                id="newPassword"
                                type={visiblePassword ? "text" : "password"}
                                name="newPassword"
                                value={state.newPassword}
                                onChange={onChange}
                            />
                            {
                                formError.name === 'newPassword' && (
                                    <small>{formError.message}</small>
                                )
                            }
                            {
                                visiblePassword ? (
                                    <div className="input-pass-icon">
                                        <span className="material-icons" onClick={() => setVisiblePassword(!visiblePassword)}>visibility_off</span>
                                    </div>
                                ) : (
                                    <div className="input-pass-icon">
                                        <span className="material-icons" onClick={() => setVisiblePassword(!visiblePassword)}>visibility</span>
                                    </div>
                                )
                            }
                        </div>
                        <div className="password-wrap">
                            <label htmlFor="passwordConfirm">Confirm Password</label>
                            <input
                                className={`${formError.name === 'passwordConfirm' && 'input-error'}`}
                                id="passwordConfirm"
                                type={visibleConfirmPassword ? "text" : "password"}
                                name="passwordConfirm"
                                value={passwordConfirm}
                                onChange={e => setPasswordConfirm(e.target.value)}
                            />
                            {
                                formError.name === 'passwordConfirm' && (
                                    <small>{formError.message}</small>
                                )
                            }
                            {
                                visibleConfirmPassword ? (
                                    <div className="input-pass-icon">
                                        <span className="material-icons" onClick={() => setVisibleConfirmPassword(!visibleConfirmPassword)}>visibility_off</span>
                                    </div>
                                ) : (
                                    <div className="input-pass-icon">
                                        <span className="material-icons" onClick={() => setVisibleConfirmPassword(!visibleConfirmPassword)}>visibility</span>
                                    </div>
                                )
                            }
                        </div>
                        <div className="verify-buttons">
                            <button className="button-primary btn-w-1" type="submit">
                                Reset
                            </button>
                        </div>
                    </div>
                </form>
            </div>
        </motion.div>
    );
};

export default ResetPassword;