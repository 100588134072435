import Table from "@material-ui/core/Table";
import TableBody from "@material-ui/core/TableBody";
import TableCell from "@material-ui/core/TableCell";
import TableRow from "@material-ui/core/TableRow";
import TableHead from "@material-ui/core/TableHead";
import { capitalize } from "../../../services/formValidator";
import { useState } from "react";
import { IControllers } from "../../dashboard/plan/Plans";
import { useHistory, useParams } from "react-router-dom";
import { useQuery } from "react-query";

import { getPackagesAdmin as allPackages } from "../../../data/queries/admin";
import {
  getAdminPlans,
  getAdminPlansCount,
  loadAdminPlans,
  loadAdminPlansCount,
} from "../../../store/slices/adminSlice";
import { useAppDispatch, useAppSelector } from "../../../store/hooks";
import Plan from "../../../models/Plan";
import paths from "../../../routes/paths";
import { TablePagination } from "@material-ui/core";

const headCellsPackage = [
  { id: "pknm", numeric: true, disablePadding: true, label: "Name" },
  { id: "pkprc", numeric: true, disablePadding: false, label: "Price" },
  { id: "pkactn", numeric: true, disablePadding: false, label: "" },
];

const Packages = () => {
  const history = useHistory();
  const dispatch = useAppDispatch();
  const plans = useAppSelector(getAdminPlans);
  const count = useAppSelector(getAdminPlansCount);
  const { companyId } = useParams<{ companyId: string }>();

  const [state, setState] = useState<IControllers>({
    page: 1,
    rowsPerPage: 10,
    limit: 10,
    skip: 0,
    companyId,
  });

  const { data, refetch, isLoading } = useQuery(
    ["get packages", { state }],
    allPackages,
    {
      onSuccess(datas: { total: number; plans: Plan[] }) {
        dispatch(loadAdminPlans(datas.plans));
        dispatch(loadAdminPlansCount(datas.total));
      },
    }
  );

  const handleChangePage = (event: any, newPage: any) => {
    setState({
      ...state,
      page: newPage,
      skip: newPage * state.limit,
    });
    refetch();
  };

  const handleChangeRowsPerPage = (event: any) => {
    setState({
      ...state,
      rowsPerPage: parseInt(event.target.value, 10),
      page: 1,
      limit: parseInt(event.target.value, 10),
      skip: 0,
    });
    refetch();
  };

  return (
    <div className="tables">
      <Table
        aria-labelledby="tableTitle"
        aria-label="enhanced table"
        className="table"
      >
        <TableHead className="table-header">
          <TableRow>
            {/* <TableCell style={{position: 'absolute', border: 'none'}}></TableCell>
                                        <TableCell padding="checkbox" align="right"> */}

            {/* </TableCell> */}
            {headCellsPackage.map((headCell: any) => (
              <TableCell
                className="cell"
                key={headCell.id}
                align="left"
                padding={"normal"}
              >
                {headCell.label}
              </TableCell>
            ))}
          </TableRow>
        </TableHead>
        {/* {
                                    isLoading && (
                                        <DashLoader />
                                    )
                                } */}
        <TableBody>
          {data?.plans?.map((row) => {
            return (
              <TableRow
                hover
                // tabIndex={-1}
                key={row.id}
              >
                {/* <TableCell style={{position: 'absolute', border: 'none'}}></TableCell>
                                            <TableCell padding="checkbox" align="left">
                                                
                                            </TableCell> */}
                <TableCell className="clickable" align="left">
                  {capitalize(`${row.planName}`)}
                </TableCell>
                <TableCell align="left">Kshs.&nbsp;{row.price}</TableCell>
                <TableCell
                  align="left"
                  onClick={() =>
                    history.push(
                      paths.adminviewpackage.replace(":planId", `${row.id}`)
                    )
                  }
                >
                  View
                </TableCell>
                {/* <TableCell
                                                className="activate"
                                                align="right"
                                                >
                                                <button
                                                    className='act'
                                                    >
                                                    <span className="material-icons">circle</span>
                                                    <span>Inactive</span>
                                                </button>
                                            </TableCell> */}
              </TableRow>
            );
          })}
        </TableBody>
      </Table>

      <div className="pagination">
        <TablePagination
          component="div"
          count={count}
          page={state.page}
          onPageChange={handleChangePage}
          rowsPerPage={state.rowsPerPage}
          onRowsPerPageChange={handleChangeRowsPerPage}
        />
      </div>
    </div>
  );
};

export default Packages;
