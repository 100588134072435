import { createSlice, PayloadAction } from '@reduxjs/toolkit'
import type { RootState } from '../rootReducer';
import Profile from "../../models/Profile";

interface ProfileState {
  user: Profile | null;
}

const initialState: ProfileState = {
  user: null,

}

export const profileSlice = createSlice({
  name: 'profile',
  initialState,
  reducers: {
    loadProfile: (state, action: PayloadAction<Profile>) => {
      state.user = action.payload;
    }
  },
});

export default profileSlice.reducer;

export const { loadProfile } = profileSlice.actions;
export const getProfile = (state: RootState) => state.profile.user;
