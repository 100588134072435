import { useMediaQuery } from "@material-ui/core";
import Button from "@material-ui/core/Button";
import { useDispatch } from "react-redux";
import { matchPath, useHistory, useLocation, useParams } from "react-router-dom";
import LogoImage from "../../assets/images/logo.png";
import { logout } from "../../data/mutations/auth";
import paths from "../../routes/paths";
import { useAdmin, useClient, useCompany } from "../../utils/hooks";


const Sidebar = ({ toggleDrawer }: { toggleDrawer: Function }) => {
    const location = useLocation();
    const history = useHistory();
    const dispatch = useDispatch();
    const matches = useMediaQuery('(max-width: 1024px)');
    const isCompany = useCompany();
    const isClient = useClient();
    const isAdmin = useAdmin();
    const { planId } = useParams<{ planId: string }>();

    const isActive = (path: string) => {
        if (location.pathname === path) {
            return true;
        }
        return false;
    }

    const match = (path: string) => {
        let returnValue = false;

        if(matchPath(location.pathname, { path, exact: true })?.isExact && planId !== 'all') {
            returnValue =  true;
        }
        return returnValue;   
    }


    const links: { name: string, icon: string, path: string, view: boolean, subPath: string }[] = [
        {
            name: 'Companies',
            icon: 'grid_view',
            path: paths.admindashboard,
            view: isAdmin,
            subPath: ''
        },
        {
            name: 'Package',
            icon: 'grid_view',
            path: paths.plans,
            view: isCompany,
            subPath: paths.accounts
        },
        {
            name: 'Accounts',
            icon: 'switch_account',
            path: paths.accounts.replace(':planId', 'all'),
            view: isCompany,
            subPath: paths.viewledger
        },
        {
            name: 'Clients',
            icon: 'people_outline',
            path: paths.clients,
            view: isCompany,
            subPath: paths.viewclient
        },
        {
            name: 'Home',
            icon: 'home',
            path: paths.clientdashboard,
            view: isClient,
            subPath: ''
        }
    ]

    return(
        <div className="sidebar">
            <div className="header">
                {
                    matches && (
                        <Button className="mn-btn" onClick={toggleDrawer('left', false)}>
                            <span className="material-icons">menu</span>
                        </Button>
                    )
                }
                {
                    !matches && (
                        <div className="logo-wrap">
                            <img
                                src={LogoImage}
                                onClick={() => {                                    
                                    if (isAdmin) {
                                        history.push(paths.admindashboard)
                                    } else if (isCompany) {
                                        history.push(paths.plans)
                                    } else {
                                        history.push(paths.clientdashboard)
                                    }
                                }}
                                alt="logo"
                            />
                        </div>
                    )
                }   
            </div>
            <div className="links">
                {
                    links.filter(link => link.view).map((link, i) => (
                        <button
                            className={`${(isActive(link.path) || match(link.subPath)) ? 'active' : 'inactive'}`}
                            onClick={() => history.push(link.path)}
                            key={i}
                        >
                            <span className="material-icons icon">{link.icon}</span>
                            <span>{link.name}</span>
                        </button>
                    ))
                }
            </div>
            <div className="footer">
                <button onClick={() => logout(dispatch)}>
                    <span className="material-icons icon">logout</span>
                    <span>Logout</span>
                </button>
            </div>
        </div>
    );
};

export default Sidebar;