import profileApi from "../../api/profile";
import errorHandler from "../../services/errorHandler";



const getProfile = async(params: any) => {
    const [, { isCompany }] = params.queryKey;
    try {
        let resp = await (await profileApi.getCompany(isCompany)).data.data;        
        return resp;
    } catch (error) {
        errorHandler(error)
    }
}

export { getProfile };