import React from 'react';
import ReactDOM from 'react-dom';
import { BrowserRouter } from 'react-router-dom';
import { Provider } from "react-redux";
import jwtDecode from "jwt-decode";
import { QueryClient, QueryClientProvider } from 'react-query'
import * as Sentry from '@sentry/react';
import './index.css';
import 'react-calendar/dist/Calendar.css';
import './styles/auth.scss';
import './styles/defaults.scss';
import './styles/notification.scss';
import './styles/companydash.scss';
import './styles/layout.scss';
import "./styles/plans.scss";
import './styles/output.css'
import "./styles/loading.scss";
import "./styles/editprofile.scss";
import "./styles/clientdashboard.scss";
import "./styles/admindashboard.scss";
import App from './App';
import reportWebVitals from './reportWebVitals';
import { LOGGIN, LOGOUT } from "./store/actions/types";
import setAuthToken from "./services/setAuthToken";
import store from "./store/store";


export interface IToken {
  exp: number,
  role: string,
  email: string
}

const queryClient = new QueryClient()

if (localStorage.__pangaMalipo__) {
  let token = localStorage.__pangaMalipo__;
  let decoded: IToken = jwtDecode(token);
  let timeNow = Date.now() / 1000;

  // check if token is expired
  if (decoded.exp < timeNow) {
    store.dispatch({
      type: LOGOUT,
      payload: null
    });
    localStorage.removeItem('__pangaMalipo__');
    localStorage.removeItem('__passStatus__');
    window.location.href = '/';
    window.location.reload();
  }

  // set user/company
  const session : IToken = decoded;
  store.dispatch({
    type: LOGGIN,
    payload: session
  });

  // set headers
  setAuthToken(token)
}

Sentry.init({
  environment: "production",
  dsn: "https://64303bdca6e24c958dbb2b1da207936a@o1300597.ingest.sentry.io/4505238394568704",
  integrations: [new Sentry.BrowserTracing(), new Sentry.Replay()],
  // Performance Monitoring
  tracesSampleRate: 1.0, // Capture 100% of the transactions, reduce in production!
  // Session Replay
  replaysSessionSampleRate: 0.1, // This sets the sample rate at 10%. You may want to change it to 100% while in development and then sample at a lower rate in production.
  replaysOnErrorSampleRate: 1.0, // If you're not already sampling the entire session, change the sample rate to 100% when sampling sessions where errors occur.
});

ReactDOM.render(
  <React.StrictMode>
    <QueryClientProvider client={queryClient}>
      <Provider store={store}>
        <BrowserRouter>
          <App />
        </BrowserRouter>
      </Provider>
    </QueryClientProvider>
  </React.StrictMode>,
  document.getElementById('root')
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
