import { useState } from "react";
import Accounts from "./tables/Accounts";
import { capitalize } from "../../services/formValidator";
import Packages from "./tables/Packages";
import Clients from "./tables/Clients";
import { useQuery } from "react-query";
import { IControllers } from "../dashboard/plan/Plans";
import Plan from "../../models/Plan";
import {
  getPackagesAdmin as callPackages,
  getAdminAnalytics as getAllStats,
} from "../../data/queries/admin";
import { useAppDispatch, useAppSelector } from "../../store/hooks";
import {
  getAdminPlans,
  getAdminPlansCount,
  loadAdminPlans,
  loadAdminPlansCount,
} from "../../store/slices/adminSlice";
import StatTab from "../ui/dashboard/StatTab";
import { IoAddCircleOutline } from "react-icons/io5";
import { CgMenuMotion } from "react-icons/cg";
import { BsBuildings } from "react-icons/bs";
import { PiUsersFill } from "react-icons/pi";
import { useParams } from "react-router-dom";
import {
  Box,
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  makeStyles,
} from "@material-ui/core";
import TransactionHistory from "./tables/TransactionHistory";

const useStyles = makeStyles({
  table: {
    minWidth: 650,
  },
});

function createData(
  name: string,
  calories: number,
  fat: number,
  carbs: number,
  protein: number
) {
  return { name, calories, fat, carbs, protein };
}

const rows = [
  createData("Frozen yoghurt", 159, 6.0, 24, 4.0),
  createData("Ice cream sandwich", 237, 9.0, 37, 4.3),
  createData("Eclair", 262, 16.0, 24, 6.0),
  createData("Cupcake", 305, 3.7, 67, 4.3),
  createData("Gingerbread", 356, 16.0, 49, 3.9),
];

const AdminCompany = () => {
  const dispatch = useAppDispatch();
  const plans = useAppSelector(getAdminPlans);
  const count = useAppSelector(getAdminPlansCount);
  const { companyId } = useParams<{ companyId: string }>();
  const classes = useStyles();
  const [state, setState] = useState<IControllers>({
    page: 0,
    rowsPerPage: 10,
    limit: 10,
    skip: 0,
    companyId,
  });

  const [viewing, setViewing] = useState("packages");

  const views: { name: string; isActive: boolean }[] = [
    {
      name: "Packages",
      isActive: viewing === "packages",
    },
    {
      name: "Accounts",
      isActive: viewing === "accounts",
    },
    {
      name: "Clients",
      isActive: viewing === "clients",
    },
  ];

  const changeView = (i: number) => {
    if (i === 0) {
      setViewing("packages");
    } else if (i === 1) {
      setViewing("accounts");
    } else {
      setViewing("clients");
    }
  };

  const { data, refetch, isLoading } = useQuery(
    ["get stats", { state }],
    getAllStats,
    {
      onSuccess(data: {
        revenue: number;
        packages: number;
        accounts: number;
        clients: number;
      }) {},
    }
  );

  return (
    <div className="admin-company-dashboard">
      <div className="wrapper">
        <div className="h4-div">
          <p>Blue Inc</p>
        </div>

        <section className="statTabs">
          <section className="tabs">
            <StatTab
              icon={<IoAddCircleOutline className="icon" />}
              amount={`Ksh ${data?.revenue}` ?? ""}
              subtext={"Revenue"}
              loading={isLoading}
            />
            <StatTab
              icon={<CgMenuMotion className="icon" />}
              amount={`${data?.packages}`}
              subtext={"Packages"}
              loading={isLoading}
            />
            <StatTab
              icon={<BsBuildings className="icon" />}
              amount={`${data?.accounts}`}
              subtext={"Accounts"}
              loading={isLoading}
            />
            <StatTab
              icon={<PiUsersFill className="icon" />}
              amount={`${data?.clients}`}
              subtext={"Clients"}
              loading={isLoading}
            />
          </section>
        </section>

        <div className="company-content">
          <div className="table-wrap">
            <div className="header-section">
              <div className="l-div">
                {views.map((view, i) => (
                  <div
                    key={i}
                    className={`view ${view.isActive ? "active" : "inactive"}`}
                  >
                    <p onClick={() => changeView(i)}>{view.name}</p>
                  </div>
                ))}
                <div className="space"></div>
              </div>
              <div className="t-div">
                <p>{viewing.toUpperCase()}</p>
              </div>
            </div>
            {viewing === "packages" && <Packages />}
            {viewing === "accounts" && <Accounts />}
            {viewing === "clients" && <Clients />}
          </div>
        </div>
      </div>
      <Box
        sx={{
          marginTop: 100,
          p:2,
          height:'max-content'
        }}
        bgcolor={'primary.default'}
        className={'rounded-xl'}
      >
        <TransactionHistory />
      </Box>
    </div>
  );
};

export default AdminCompany;
