import axios from "axios";
import { baseUrl } from "./base";
import { IControllers } from "../components/dashboard/plan/Plans";


const adminApi = {
    getCompaniesAdmin: (state: IControllers) => {
        return axios.get(`${baseUrl}/admin-manager/companies`);
    },
    getPackagesAdmin: (state: IControllers) => {
        return axios.get(`${baseUrl}/plans/company?take=${state.limit}&skip=${state.skip}&companyId=${state.companyId}`);
    },
    getPackageAdmin: (state: IControllers) => {
        return axios.get(`${baseUrl}/plan-manager/{${state.planId}/accounts}`);
    },
    getAccountsAdmin: (state: IControllers) => {
        return axios.get(`${baseUrl}/admin-manager/accounts?${state.companyId}`);
    },
    getClientsAdmin: (state: IControllers) => {
        return axios.get(`${baseUrl}/admin-manager/clients?${state.companyId}`);
    },
    getClientAccountsAdmin: (clientId: string) => {
        // add pagination with state
        return axios.get(`${baseUrl}/plan-manager/${clientId}/all-accounts`);
    },
    searchClientAdmin: (queryString: string) => {        
        return axios.get(`${baseUrl}/admin-manager/clients?search=${queryString}&take=${5}&skip=${0}`);
    },
    getAdminAnalytics: (state: IControllers) => {        
        return axios.get(`${baseUrl}/admin-manager/analytics/${state.companyId}`);
    },
    getTransactionHistory: (state: IControllers) => {        
        return axios.get(`${baseUrl}/payments-receipt/transactions/${state.companyId}`);
    },
}

export default adminApi;