import React, { ReactType } from "react";
import { Route, Redirect } from "react-router-dom";
import Sidebar from "../components/layout/Sidebar";
import Appbar from "../components/layout/Appbar";
import { useAuth, useCompany } from "../utils/hooks/index";
import paths from "./paths";
import { useMediaQuery } from "@material-ui/core";


interface IProps {
    component: ReactType;
    path: string;
    exact: boolean;
}

const UserRoute = ({ component: Component, ...rest}: IProps) => {
    const isAuthenticated = useAuth();
    const isCompany = useCompany();
    const matches = useMediaQuery('(max-width: 1024px)');

    return <Route
        {...rest}
        render={props => (
            (isAuthenticated && isCompany) ? (
                <div className="dashboard-cover">
                    {
                        !matches && (
                            <Sidebar
                                toggleDrawer={() => {}}
                            />
                        )
                    }
                    <div className="content">
                        <Appbar />
                        <Component {...props} />
                    </div>
                </div>
            ) : (
                <Redirect to={paths.landing} />
            )
        )
    }
  />
};

export default UserRoute;