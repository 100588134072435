import accountsApi from "../../api/account";
import Account, { MiscDetails } from "../../models/Account";
import errorHandler from "../../services/errorHandler";



const newAccount = async(planId: string, account: Account) => {
    try {
        let resp = await (await accountsApi.newAccount(planId, account)).data.data;
        return resp;
    } catch (error) {
        errorHandler(error)
    }
}

const editAccount = async(id: string, account: Account) => {
    try {
        let resp = await (await accountsApi.editAccount(id, account)).data.data;
        return resp;
    } catch (error) {
        errorHandler(error)
    }
}

const deleteAccount = async(planId: string, accountIds: string[]) => {
    try {
        let resp = await (await accountsApi.deleteAccount(planId, accountIds)).data.data;
        return resp;
    } catch (error) {
        errorHandler(error)
    }
}

const uploadCSV = async(creatingId: string, CSV: any, setProgress: Function) => {
    try {
        let resp = await (await accountsApi.uploadCSV(creatingId, CSV, setProgress)).data.data;        
        return resp;
    } catch (error) {
        errorHandler(error)
    }
}

const addMisc = async(accountId: string, miscValue: MiscDetails) => {
    try {
        let resp = await (await accountsApi.addMisc(accountId, miscValue)).data.data;
        return resp;
    } catch (error) {
        errorHandler(error);
    }
}

export { newAccount, editAccount, deleteAccount, uploadCSV, addMisc };