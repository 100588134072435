import React from "react";
import { Switch, Route, useLocation } from "react-router-dom";
// Route wrappers
import AuthRoute from "./AuthRoute";
import UserRoute from "./UserRoute";
import VerifyRoute from "./VerifyRoute";
import ClientRoute from "./ClientRoute";
// Landing page
import paths from "./paths";
// Auth pages
import Landing from "../components/layout/Landing";
import Signup from "../components/auth/Signup";
import Verify from "../components/auth/Verify";
import EmailNotification from "../components/auth/EmailNotification";
import SMSNotification from "../components/auth/SMSNotification";
import Login from "../components/auth/Login";
import ForgotPassword from "../components/auth/ForgotPassword";
import ResetPassword from "../components/auth/ResetPassword";
// Dashboard pages
import Plans from "../components/dashboard/plan/Plans";
import Accounts from "../components/dashboard/account/Accounts";
import EditProfile from "../components/dashboard/profile/EditProfile";
// Client pages
import ClientDashboard from "../components/clientcomponents/Dashboard";
import CompanyAccounts from "../components/clientcomponents/company";
import Ledger from "../components/clientcomponents/Ledger";
import ClientPlanAccounts from "../components/dashboard/account/ClientPlanAccounts";
import CompanyLedgerComponent from "../components/dashboard/account/Ledger";
import Clients from "../components/dashboard/client/Clients";
import ViewClient from "../components/dashboard/client/ViewClient";
import ChangePassword from "../components/auth/ChangePassword";
import ChangeRoute from "./ChangeRoute";
import DemoPage from "../components/demo/DemoPage";
import AdminDashboard from "../components/admin/AdminDashboard";
import AdminRoute from "./AdminRoute";
import AdminCompany from "../components/admin/AdminCompany";
import AdminViewPackage from "../components/admin/packages/AdminViewPackage";
import AdminCompanyLedger from "../components/admin/account/AdminLedger";
import AdminViewClient from "../components/admin/client/AdminViewClient";
import RenewSub from "../components/subscriptions/RenewSub";
import RenewSubSuccess from "../components/subscriptions/RenewSubSuccess";

const Routes = () => {
    const location = useLocation();

    return(
        <div>
            <Switch location={location} key={location.key}>
                <Route path={paths.landing} component={Landing} exact />
                <Route path={paths.renewsub} component={RenewSub} exact />
                <Route path={paths.renewsubsuccess} component={RenewSubSuccess} exact />
                <AuthRoute path={paths.signup} component={Signup} exact />
                <VerifyRoute path={paths.verify} component={Verify} exact />
                <AuthRoute path={paths.emailnotification} component={EmailNotification} exact />
                <VerifyRoute path={paths.smsnotification} component={SMSNotification} exact />
                <AuthRoute path={paths.login} component={Login} exact />
                <AuthRoute path={paths.forgotpassword} component={ForgotPassword} exact />
                <AuthRoute path={paths.resetpassword} component={ResetPassword} exact />
                <ChangeRoute path={paths.changepassword} component={ChangePassword} exact />
                <UserRoute path={paths.plans} component={Plans} exact />
                <UserRoute path={paths.accounts} component={Accounts} exact />
                <UserRoute path={paths.editprofile} component={EditProfile} exact />
                <UserRoute path={paths.clientplanaccounts} component={ClientPlanAccounts} exact />
                <UserRoute path={paths.viewledger} component={CompanyLedgerComponent} exact />
                
                <UserRoute path={paths.clients} component={Clients} exact />
                <UserRoute path={paths.viewclient} component={ViewClient} exact />
                <ClientRoute path={paths.clientdashboard} component={ClientDashboard} exact />
                <ClientRoute path={paths.companyaccounts} component={CompanyAccounts} exact />
                <ClientRoute path={paths.ledger} component={Ledger} exact />
                <AuthRoute path={paths.demo} component={DemoPage} exact />
                <AdminRoute path={paths.admindashboard} component={AdminDashboard} exact />
                <AdminRoute path={paths.adminsinglecompany} component={AdminCompany} exact />
                <AdminRoute path={paths.adminviewpackage} component={AdminViewPackage} exact />
                <AdminRoute path={paths.accledger} component={AdminCompanyLedger} exact />
                <AdminRoute path={paths.viewadminclient} component={AdminViewClient} exact />
            </Switch>
        </div>
    );
};

export default Routes;