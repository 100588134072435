import React, { Fragment, useState } from "react";
import { useQuery } from "react-query";
import { useHistory } from "react-router-dom";
import { getProfile } from "../../data/queries/profile";
import Profile from "../../models/Profile";
import { useAppDispatch } from "../../store/hooks";
import { loadProfile } from "../../store/slices/profileSlice";
import { useCompany, useGetProfile } from "../../utils/hooks";
import paths from "../../routes/paths";
import Button from "@material-ui/core/Button";
import SwipeableDrawer from "@material-ui/core/SwipeableDrawer";
import Sidebar from "./Sidebar";
import { useMediaQuery } from "@material-ui/core";


type Anchor = 'left';

const Appbar = () => {
    const dispatch = useAppDispatch();
    const history = useHistory();
    const matches = useMediaQuery('(max-width: 1024px)');
    const isCompany = useCompany();
    const [state, setState] = useState({
        left: false
    });
    const [viewMenu, setViewMenu] = useState(false);

    useQuery(['getProfile', { isCompany }], getProfile, {
        onSuccess(data: Profile) {
            dispatch(loadProfile(data));            
        }
    });

    const profile = useGetProfile();

    const toggleDrawer = (anchor: Anchor, open: boolean) =>
        (event: React.KeyboardEvent | React.MouseEvent) => {
        if (
            event &&
            event.type === 'keydown' &&
            ((event as React.KeyboardEvent).key === 'Tab' ||
            (event as React.KeyboardEvent).key === 'Shift')
        ) {
            return;
        }

        setState({ ...state, [anchor]: open });
    };
    
    const handleMenu = () => {
        setViewMenu(!viewMenu);
    }

    const handleCloseMenu = () => {
        setViewMenu(false);
    }
    
    return(
        <div className="appbar">
            <div className="left">
                {
                    matches && (
                    <div className="app-menu">
                        <Fragment>
                            <Button className="border-btn" onClick={toggleDrawer('left', true)}>
                                <span className="material-icons">menu</span>
                            </Button>
                            <SwipeableDrawer
                                anchor={'left'}
                                open={state['left']}
                                onClose={toggleDrawer('left', false)}
                                onOpen={toggleDrawer('left', true)}
                                >
                                    <div className="dash-modal">
                                        <Sidebar
                                            toggleDrawer={toggleDrawer}
                                        />
                                    </div>
                            </SwipeableDrawer>
                        </Fragment>
                    </div>
                    )}
                <div className="search">
                    {/* <input
                        type="text"
                        placeholder="Search"
                    /> */}
                </div>
            </div>
            
            <div className="right">
                <div className="notification" onBlur={() => handleCloseMenu()}>
                    {/* <span className="material-icons">notifications</span> */}
                </div>
                <div
                    className="settings"
                    onClick={() => handleMenu()}
                    // onBlur={() => handleCloseMenu()}
                    tabIndex={0}
                >
                    <div className="icon">
                        {
                            isCompany ? (
                                profile && (
                                    <img
                                        src={profile.company.logoUrl}
                                        alt=""
                                    />
                                )
                            ) : (
                                profile && (
                                    <div className="client-abb">
                                        <span>{profile.firstName.charAt(0)} {profile.lastName.charAt(0)}</span>
                                    </div>
                                )
                            )
                        }
                    </div>
                    <div className="details">
                        {
                            isCompany ? (
                                <small className="title">{profile && profile.company.name}</small>
                            ) : (
                                <small className="title">{profile && profile.firstName} {profile && profile.lastName}</small>
                            )
                        }
                        {
                            isCompany ? (
                                <small className="desc"> </small>
                            ) : (
                                <small className="desc">{profile && profile.email}</small>
                            )
                        }
                    </div>
                    <div className="more">
                        <Button
                            className='btn'
                        >
                            <span className="material-icons icon">expand_more</span>
                        </Button>
                    </div>
                </div>
                {
                    viewMenu && (
                    <div className="menu-section">
                        <p
                            onClick={() => {
                                handleCloseMenu();
                                history.push(paths.editprofile);
                            }}
                        >
                            <span className="material-icons icon">folder</span><span>Settings</span>
                        </p>
                    </div>
                )}
            </div>
        </div>
    );
};

export default Appbar;