import Modal from '@material-ui/core/Modal';
import { makeStyles } from '@material-ui/core/styles';
import { ChangeEvent, FormEvent, useEffect, useState } from 'react';
import { useMutation } from "react-query";
// import Checkbox from '@material-ui/core/Checkbox';
import ModalLoader from "../../../common/ModalLoader";
import Notification from "../../../common/Notification";
import { editPlan, newPlan } from "../../../data/mutations/plan";
import Plan from "../../../models/Plan";
import formValidator from "../../../services/formValidator";


interface IAddService {
    edit: boolean;
    carrier: { plan: Plan, setPlan: Function, id: string };
    adding: { adding: boolean, setAdding: Function };
    setMessage: Function;
    refetch: Function;
}

const useStyles = makeStyles((theme) => ({
  paper: {
    position: 'absolute',
    backgroundColor: theme.palette.background.paper,
    borderRadius: '8px',
    padding: theme.spacing(2, 4, 3),
    top: '20%',
    left: '35%',
    '@media (max-width: 780px)': {
      left: '1%',
    }
  },
}));

const AddPlan = ({ edit, carrier, adding, setMessage, refetch }: IAddService) => {
  const classes = useStyles();  

  const [error, setError] = useState('');
  const [formError, setFormError] = useState({
    status: false,
    name: '',
    message: ''
  });

  const { mutate: addPlan, isLoading } = useMutation(newPlan, {
    onSuccess() {
      adding.setAdding(false);
      setTimeout(() => {
        setMessage('');
      }, 5000);
      setMessage('The plan has been added!');
      refetch();
    },
    onError(error: {message: string}) {
      setTimeout(() => {
        setError('');
      }, 5000);
      setError(error.message);
    }
  });

  const { mutate: updatePlan, isLoading: loading } = useMutation(
    () => 
    editPlan(carrier.id, carrier.plan), {
      onSuccess() {
        adding.setAdding(false);
        setTimeout(() => {
          setMessage('');
        }, 5000);
        setMessage('The plan has been edited!');
        refetch();
      }
    }
    );

  useEffect(() => {
    if (edit) {
      carrier.setPlan({
        ...carrier.plan
      });      
    }
    carrier.setPlan({
      ...carrier.plan,
      paymentPeriod: 'monthly'
    });
  },
  // eslint-disable-next-line react-hooks/exhaustive-deps
  []);

  const handleClose = () => {
    adding.setAdding(false);
  };

  // const handleChange = (event: ChangeEvent<HTMLInputElement>) => {
  //   carrier.setPlan({
  //     ...carrier.plan,
  //     prorated: event.target.checked
  //   })
  // };

  const onChange = (e: ChangeEvent<HTMLInputElement | HTMLSelectElement>) => carrier.setPlan({
    ...carrier.plan,
    [e.target.name]: e.target.value
  });

  const onSubmit = (e: FormEvent) => {
    e.preventDefault();

    const { status, name, message } = formValidator(carrier.plan);
        if (status) {
            setTimeout(() => {
                setFormError({
                    status: false,
                    name: '',
                    message: ''
                })
            }, 5000);
            return setFormError({
                status,
                name,
                message 
            });
        }
    // create plan or edit one
    if (edit) {
      updatePlan();
    } else {
      addPlan(carrier.plan);
    }    
  }

  const body = (
    <div className={`${classes.paper} add-plan dimension-1`}>
      {
        (isLoading || loading) && (
          <ModalLoader />
        )
      }
      {
        error !== '' && (
          <Notification
            message={error}
            success={false}
            auto={false}
          />
        )
      }
      <div className="title">
          <span>{edit ? 'Edit' : 'Add a'} Package</span>
      </div>
      <div className="body">
          <form onSubmit={onSubmit}>
              <div>
                  <label htmlFor="name">
                    Name
                  </label>
                  <input
                    className={`${formError.name === 'planName' && 'input-error'}`}
                    name="planName"
                    type="text"
                    placeholder="Package Name"
                    value={carrier.plan.planName}
                    onChange={onChange}
                  />
                  {
                    formError.name === 'planName' && (
                      <small className="m-1">{formError.message}</small>
                    )
                  }              
              </div>
              <div>
                  <label htmlFor="name">
                    Price
                  </label>
                  <input
                    className={`${formError.name === 'price' && 'input-error'}`}
                    name="price"
                    type="number"
                    placeholder="Package Price"
                    value={carrier.plan.price}
                    onChange={onChange}
                  />
                  {
                    formError.name === 'price' && (
                      <small className="m-1">{formError.message}</small>
                    )
                  }  
              </div>
              <div>
                  <label htmlFor="name">
                      Period
                  </label>
                  <select
                    className={`${formError.name === 'paymentPeriod' && 'input-error'}`}
                    onChange={onChange}
                    name="paymentPeriod"
                    value={carrier.plan.paymentPeriod}
                  >
                    <option value="" >Select Period</option>
                    <option value="monthly">Monthly</option>
                  </select>
                  {
                    formError.name === 'paymentPeriod' && (
                      <small className="m-1">{formError.message}</small>
                    )
                  }              
              </div>
              {/* <div className="protate-wrap">
                  <label htmlFor="name">
                      Prorated
                  </label>
                  <div className="checkbox">
                  <Checkbox
                    className="protate"
                    checked={carrier.plan.prorated}
                    onChange={handleChange}
                    inputProps={{ 'aria-label': 'primary checkbox' }}
                    />
                  </div>
              </div> */}
              <div className="footer">
                <button className="button-action btn-2">
                    {edit ? 'Save' : 'Submit'}
                </button>
              </div>
          </form>
      </div>
      
    </div>
  );

  return (
    <div>
      <Modal
        open={adding.adding}
        onClose={handleClose}
        aria-labelledby="simple-modal-title"
        aria-describedby="simple-modal-description"
      >
        {body}
      </Modal>
    </div>
  );
};

export default AddPlan;