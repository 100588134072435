import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableRow from '@material-ui/core/TableRow';
import { motion } from "framer-motion";
import { ChangeEvent, useEffect, useState } from "react";
import { useMutation, useQuery } from "react-query";
import { useHistory } from "react-router-dom";
// import Checkbox from '@material-ui/core/Checkbox';
import TableHead from '@material-ui/core/TableHead';
import TablePagination from '@material-ui/core/TablePagination';
import DashLoader from "../../../common/DashLoader";
import Notification from "../../../common/Notification";
import SmallLoader from "../../../common/SmallLoader";
import { activatePlan } from "../../../data/mutations/plan";
import { getPlans as callPlans, searchPlan } from "../../../data/queries/plan";
import Plan from "../../../models/Plan";
import paths from "../../../routes/paths";
import { capitalize } from "../../../services/formValidator";
import { useAppDispatch, useAppSelector } from "../../../store/hooks";
import { getPlans, getPlansCount, loadCount, loadPlans } from "../../../store/slices/planSlice";
import BreadCrumb from "../../layout/Breadcrumb";
import AddPlan from "./AddPlan";


export interface IControllers {
    page: number,
    rowsPerPage: number,
    limit: number,
    skip: number,
    companyId?: string,
    planId?:string
}

const Plans = () => {
    const history = useHistory();
    const dispatch = useAppDispatch();
    const plans = useAppSelector(getPlans);
    const count = useAppSelector(getPlansCount);

    const [state, setState] = useState<IControllers>({
        page: 0,
        rowsPerPage: 10,
        limit: 10,
        skip: 0,
        companyId:'',
    });
    const [adding, setAdding] = useState(false);
    const [edit, setEdit] = useState(false);
    const [id, setId] = useState<string>('');
    // const [selected, setSelected] = useState<string[]>([]);
    const [message, setMessage] = useState('');
    const [searchString, setSearchString] = useState('');
    const [foundPlan, setFoundPlan] = useState<Plan[]>([]);
    const [plan, setPlan] = useState<Plan>({
        planName: "",
        price: "",
        paymentPeriod: "",
        prorated: false
    });

    const { data, refetch, isLoading } = useQuery(['get plans', { state }], callPlans, {
        onSuccess(datas: { total: number, plans: Plan[] }){
            dispatch(loadPlans(datas.plans));
            dispatch(loadCount(datas.total));
        }
    });

    const { mutate: activatingPlan, isLoading: activateLoading } = useMutation(
        async ({ id, isActive } : { id: string, isActive: boolean }) => 
        await activatePlan(id, isActive), {
          async onSuccess() {
            setTimeout(() => {
              setMessage('');
            }, 5000);
            setMessage('Request was successful');
            let ref = await refetch();
            if (ref) {
                setId('')
            }
          }
    });

    // const { mutate: remove, isLoading: loading } = useMutation(deletePlan, {
    //     onSuccess() {
    //         setTimeout(() => {
    //             setMessage('');
    //         }, 5000);
    //         setMessage(`Plan(s) deleted successfully`);
    //         refetch();
    //     }
    // });

    useEffect(() => {
        if (data && data.plans.length === 0) {
            setMessage('Your account is ready, please add a package!')
        }
    }, [data])

    // const handleChangeCheck = (event: ChangeEvent<HTMLInputElement>) => {
    //     let check = selected.includes(event.target.value);

    //     if (check) {
    //         let array = selected;
    //         let index = array.indexOf(event.target.value);
    //         array.splice(index, 1);
    //         setSelected([...array]);
    //     } else {
    //         setSelected([...selected, event.target.value]);    
    //     }        
    // }

    // const onSelectAllClick = (event: ChangeEvent<HTMLInputElement>) => {        
    //     if (event.target.checked) {
    //         const newSelecteds: string[] = plans.map(plan => plan.id!);
    //         setSelected(newSelecteds);
    //         return;
    //     }
    //     setSelected([]);
    // }

    const manageThrottle = (cb: Function, d: number) => {
        let lastTime = 0;

        return (...args: any) => {
            let now = new Date().getTime();

            if (now - lastTime < d) return;
            lastTime = now;
            return cb(...args);
        }
    }

    const onSearchChange = (e: ChangeEvent<HTMLInputElement>) => {        
        setSearchString(e.target.value);
        
        manageThrottle(async () => {
            let resp = await searchPlan(searchString);
            if (resp) {
                setFoundPlan(resp);
            }
        }, 1000)();
    }

    const handleChangePage = (event: any, newPage: any) => {
        setState({
            ...state,
            page: newPage,
            skip: newPage * state.limit
        });
        refetch();
    };

    const handleChangeRowsPerPage = (event: any) => {
        setState({
            ...state,
            rowsPerPage: parseInt(event.target.value, 10),
            page: 0,
            limit: parseInt(event.target.value, 10),
            skip: 0
        });
        refetch();
    };

    const handleOpen = () => {
        setAdding(true);
        setEdit(false);
        setId("");
        setPlan({
            "planName": "",
            "price": "",
            "paymentPeriod": "",
            "prorated": false
        });
    };

    const headCells = [
        { id: 'pname', numeric: true, disablePadding: true, label: 'Name' },
        { id: 'pprice', numeric: true, disablePadding: false, label: 'Price' },
        // { id: 'pmethod', numeric: true, disablePadding: false, label: 'Payment Method' },
        { id: 'pedit', numeric: true, disablePadding: false, label: '' },
        { id: 'pdeactivate', numeric: true, disablePadding: false, label: '' },
      ];

    return (
        <motion.div
            className="plans"
            animate={{ x: 25 }}
            transition={{ ease: 'easeOut' }}
            >
            {
                message!== '' && (
                    <Notification
                        message={message}
                        success={true}
                        auto={true}
                    />
                )
            }
            {
                adding && (
                    <AddPlan
                        edit={edit}
                        carrier={{ plan, setPlan, id }}
                        adding={{ adding, setAdding }}
                        setMessage={setMessage}
                        refetch={refetch}
                    />
                )
            }
            <div className="add-service-wrap">
                <div className="bread-crumb">
                    <BreadCrumb />
                </div>
                <div className="add-service">
                    <button
                        className="button-action btn-1"
                        onClick={handleOpen}
                        >
                        <span>New Package</span>
                        <span className="material-icons">add</span>
                    </button>
                </div>
            </div>
            {/* <div className="title">
                <span>Packages</span>
            </div> */}
            <div className="wrap">
                <div className="table-search">
                    <div className="left">
                        {/* <div className="filter-table">
                            <span className="material-icons">
                                filter_alt
                            </span>
                            <span>SORT</span>
                        </div> */}
                        <div className="search-input">
                            <input
                                type="text"
                                placeholder="Search"
                                value={searchString}
                                onChange={onSearchChange}
                            />
                        </div>
                        {/* absolute end */}
                        <div className={`left-dropdown ${searchString.length > 0 ? 'view-drop' : 'hide-drop'}`}>
                            {foundPlan.length > 0 && foundPlan.map((pl, i) => (
                                <p
                                    key={i}
                                    onClick={() => {
                                        dispatch(loadPlans(foundPlan.filter(fp => fp.id === pl.id)));
                                        dispatch(loadCount(0));
                                        setSearchString('');
                                    }}  
                                    >
                                    <span className="material-icons">search</span>
                                    <span className="span1">{pl.planName}</span>
                                </p>
                            ))}
                            {
                                foundPlan.length === 0 && (
                                    <p>
                                        <span className="material-icons">search</span>
                                        <span className="span1">No Data</span>
                                    </p>
                                )
                            }
                        </div>
                        {/* absolute end */}
                    </div>
                    {/* <div className="right">
                        {
                            selected.length > 0 && (
                                <button
                                    className="button-action btn-2"
                                    onClick={() => remove(selected)}
                                >
                                    DELETE
                                </button>
                            )
                        }
                    </div> */}
                </div>
                <div className="tables">
                    <Table
                        aria-labelledby="tableTitle"
                        aria-label="enhanced table"
                        className="table"
                    >
                        <TableHead className="table-header">
                            <TableRow>
                                <TableCell style={{position: 'absolute', border: 'none'}}></TableCell>
                                {/* <TableCell padding="checkbox" align="left">
                                    <Checkbox
                                        indeterminate={plans && selected.length > 0 && selected.length < plans.length}
                                        checked={plans && plans.length > 0 && selected.length === plans.length}
                                        onChange={onSelectAllClick}
                                        inputProps={{ 'aria-label': 'select all desserts' }}
                                    />
                                </TableCell> */}
                                {headCells.map((headCell: any) => (
                                <TableCell
                                    className="cell"
                                    key={headCell.id}
                                    align='center'
                                    padding={headCell.disablePadding ? 'none' : 'normal'}
                                >
                                    {headCell.label}
                                </TableCell>
                                ))}
                            </TableRow>
                        </TableHead>
                        {
                            isLoading && (
                                <DashLoader />
                            )
                        }
                        <TableBody>
                            {plans && plans.length > 0 && plans.map((row: Plan, index) => {

                            return (
                                <TableRow
                                    hover
                                    tabIndex={-1}
                                    key={index}
                                >
                                    {
                                        (id === row.id! && activateLoading) ? (
                                            <SmallLoader />
                                        ) : (
                                            <TableCell style={{position: 'absolute', border: 'none'}}></TableCell>
                                        )
                                    }
                                    {/* <TableCell padding="checkbox" align="left">
                                        <Checkbox
                                            checked={selected.includes(row.id!)}
                                            value={row.id}
                                            onChange={handleChangeCheck}
                                            inputProps={{ 'aria-labelledby': 'primary checkbox' }}
                                        />
                                    </TableCell> */}
                                    <TableCell
                                        className="clickable"
                                        align="center"
                                        onClick={() => history.push(paths.accounts.replace(":planId", `${row.id}`))}
                                        >
                                            {capitalize(row.planName)}
                                    </TableCell>
                                    <TableCell align="center">Kshs.&nbsp;{row.price}</TableCell>
                                    {/* <TableCell align="center">{capitalize(row.paymentPeriod)}</TableCell> */}
                                    <TableCell
                                        align="center"
                                        className="edit"
                                        onClick={() => {
                                            setAdding(true);
                                            setEdit(true);
                                            setId(row.id!);
                                            setPlan({
                                                ...plan,
                                                planName: row.planName,
                                                price: row.price,
                                                paymentPeriod: row.paymentPeriod,
                                                prorated: row.prorated
                                            });
                                        }}
                                        >
                                            Edit Details
                                    </TableCell>
                                    <TableCell
                                        className="activate"
                                        align="center"
                                        >
                                        <button
                                            className={`${row.isActive ? 'deact' : 'act'}`}
                                            onClick={() => {
                                                setId(row.id!);
                                                activatingPlan({ id: row.id!, isActive: !row.isActive })
                                            }}
                                            >
                                            <span className="material-icons">circle</span>
                                            <span>{row.isActive ? 'Deactivate' : `Activate`}</span>
                                        </button>
                                    </TableCell>
                                </TableRow>
                            );
                            })}
                        </TableBody>
                    </Table>
                    <div className="pagination">
                        <TablePagination
                            component="div"
                            count={count}
                            page={state.page}
                            onPageChange={handleChangePage}
                            rowsPerPage={state.rowsPerPage}
                            onRowsPerPageChange={handleChangeRowsPerPage}
                        />
                    </div>
                </div>
            </div>
        </motion.div>
    );
}

export default Plans;
