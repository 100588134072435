import React from 'react'
import paths from '../../routes/paths'

const RenewSubSuccess = () => {
  return (
       <main className="w-full md:h-screen xs:h-full md:overflow-hidden flex flex-col justify-center items-center gap-6 font-poppins md:pt-0 xs:pt-16">
       <header className="md:text-xl xs:text-lg font-bold text-[#264653] text-center">
         Your subscription has been charged successfully!
       </header>
       <p className="text-sm font-normal text-[#66666699]/60 break-words">
         Thank you for making Payment using Panga Malipo
       </p>
       <img
         src={"/stksuccess/cuate.png"}
         alt=""
         className="md:w-max xs:w-[300px]   h-max"
       />
       <a href={paths.login} className="py-8 text-primary cursor-pointer ">
         Click here to redirect
       </a>
     </main>
  )
}

export default RenewSubSuccess