import React from "react";
import Routes from "./routes/Routes";
import './styles/input.css'
import { ThemeProvider } from "@material-ui/core";
import { lightTheme } from "./themes/muitheme";
import { ToastContainer } from "react-toastify";
  import 'react-toastify/dist/ReactToastify.min.css';



const App = () => {
  return (
    <ThemeProvider theme={lightTheme}>
    <div className="index">
      <Routes />
    </div>
    <ToastContainer />

    </ThemeProvider>
  );
}

export default App;
