import React, { useCallback, useEffect, useRef, useState } from "react";
import { useHistory, useParams } from "react-router-dom";
import Logo from "../../assets/images/logo-inv.png";
import Logo2 from "../../assets/images/logo.png";
// import "react-phone-input-2/lib/style.css";
import "react-phone-input-2/lib/material.css";
import PhoneInput from "react-phone-input-2";
import { FaLock } from "react-icons/fa6";
import { renewSubscription } from "../../data/mutations/client";
import { useMutation } from "react-query";
import { toast } from "react-toastify";
import { FiLoader } from "react-icons/fi";
import { io } from "socket.io-client";
import paths from "../../routes/paths";

const WEBSOCKET_SERVER_URL = "https://staging-api.pangamalipo.cloud";

const RenewSub = () => {
  const socketRef = useRef<ReturnType<typeof io> | null>(null);
  const history = useHistory();

  const { mpesaPhone, balance, emailData, companyName } = useParams<{
    mpesaPhone: string;
    emailData: string;
    balance: any;
    companyName: string;
  }>();
  const [phone, setPhone] = useState(mpesaPhone);
  const [successPushing, setSuccessPushing] = useState("");
  const [errorPushing, setErrorPushing] = useState("");
  const [errorResp, setErrorResp] = useState(false);
  const [succResp, setSuccResp] = useState(false);
  const [verifying, setVerifying] = useState(false);
  const [key1, setKey1] = useState("");
  const [key2, setKey2] = useState("");

  const renewSubscriptionMutation = useMutation(
    () =>
      renewSubscription({
        mpesaPhone: phone.startsWith("+") ? phone : `+${phone}`,
        emailData: emailData,
      }),
    {
      onSuccess: (data) => {
        setSuccessPushing(data);
        setKey1(data.mpesaConfirmationToken);
        setKey2(data.mpesaKey);
        console.log(data);
        setTimeout(() => setSuccessPushing(""), 5000);

        // Now calling setupWebSocketConnection here
        setupWebSocketConnection(data.mpesaConfirmationToken, data.mpesaKey);
      },
      onError: ({ message }) => {
        setErrorPushing(message);
        toast.info(message);

        setTimeout(() => {
          if (message === "You balance has been cleared for this account") {
            history.push(paths.login);
          }
          setErrorPushing("");
        }, 5000);
      },
    }
  );

  function setupWebSocketConnection(authToken: string, accountNumber: string) {
    const socket = io(`${WEBSOCKET_SERVER_URL}/payment-status`, {
      transports: ["websocket"],
      auth: { token: `${authToken}` },
    });

    socket.on("connect", () => {
      console.log("Connected to WebSocket server");

      socket.on("error", (error) => {
        console.error("WebSocket connection error:", error);
      });

      socket.emit("join", accountNumber, (response: any) => {
        console.log(response);
      });
    });

    socket.on("paymentFailure", (data: any) => {
      toast.error(data?.mpesaMessage);
      socket.emit("leave", accountNumber, (response: any) => {
        console.log(response);
      });
      setErrorResp(true);
      setVerifying(false);
    });

    socket.on("paymentSuccess", (data) => {
      console.log("Payment success:", data);
      socket.emit("leave", accountNumber, (response: any) => {
        console.log(response, "SUCCESS");
      });
      setTimeout(() => {
        setSuccResp(false);
        socket.close(); // Use socket.close() for disconnecting
      }, 5000);
      setSuccResp(true);
      setVerifying(false);
    });

    // socket.on("disconnect", () => {
    //   console.log("Disconnected from WebSocket server");
    // });
  }

  const {
    mutate: sendMpesaEmailSTK,
    isLoading,
    isError,
    isSuccess,
    error,
  } = renewSubscriptionMutation;
  useEffect(() => {
    if (isError) {
      toast.error("Error");
    }

    if (isSuccess) {
      toast.success("STK push sent successfully !");
    }

    if (succResp) {
      history.push(paths.renewsubsuccess);
    }
  }, [isError, error, isSuccess, succResp, errorResp]);

  useEffect(() => {
    return () => {
      if (socketRef.current) {
        socketRef.current.close();
      }
    };
  }, []);

  return (
    <main className="grid md:grid-cols-2 xs:grid-cols-1 md:w-screen xs:w-full md:h-screen xs:h-full font-poppins overflow-x-hidden">
      <section className="bg-[#00CDAC] md:grid xs:hidden  text-white pt-36">
        <div>
          <div className="flex justify-center items-center top-36">
            <img src={Logo} alt="" className="w-28 h-28" />
          </div>
          <p className="font-medium text-base text-center text-white pt-6">
            Monthly Subscription on {companyName ?? " "}
          </p>
          <div className="text-center flex justify-center items-center">
            <p className="text-4xl font-bold">
              {balance ? (
                <span>{`Ksh. ${balance}`}</span>
              ) : (
                <>
                  {" "}
                  Ksh.<span className="text-xl font-normal"> __</span>
                </>
              )}
            </p>
            <p className="text-base font-medium">/month</p>
          </div>
          <div className="px-12 xl:pt-36 md:pt-28">
            <hr className="border border-dashed border-[#D9D9D9] px-9" />
            <div className="flex justify-between ">
              <p className="font-semibold text-base">Total Due Today</p>
              <p className="font-bold text-xl">
                {balance ? (
                  <span>{`Ksh. ${balance}`}</span>
                ) : (
                  <>
                    {" "}
                    Ksh.<span className="text-xl font-normal"> __</span>
                  </>
                )}
              </p>
            </div>
          </div>
        </div>

        <p className="lg:bottom-3 md:bottom-0 px-10 flex  justify-start items-end align-bottom">
          Powerd by Panga Malipo
        </p>
      </section>

      <section className="md:pt-36 xs:pt-8 grid xl:px-16 md:px-12 md:justify-start   xs:justify-start xs:px-4">
        <div>
          <div className="xs:flex md:hidden pb-5 justify-center items-center top-36">
            <img src={Logo2} alt="" className="w-28 h-28" />
          </div>
          <header className="md:text-xl xs:text-lg font-bold text-[#2A9D8F]">
            MPESA Payment using Panga Malipo
          </header>
          <p className="text-[#666666]/60 md:text-base xs:text-xs font-normal">
            To Make Payment enter MPESA number to receive STK push
          </p>

          <form className=" flex gap-4 flex-col xs:justify-between md:justify-start ">
            <div className=" xs:py-10 md:py-3">
              <label
                htmlFor="phoneNumber"
                className="text-base font-normal text-[#264653] "
              >
                Phone number
              </label>
              <PhoneInput
                inputProps={{
                  name: "phone",
                  required: true,
                  autoFocus: true,
                }}
                country={"ke"}
                value={phone}
                onChange={(phone) => setPhone(phone)}
                disabled={isLoading}
              />
            </div>

            <button
              className={` w-full bg-primary md:py-4 xs:py-3 border-0 rounded-lg text-base text-white my-3 cursor-pointer disabled:bg-primary/30 disabled:cursor-not-allowed hover:drop-shadow-xl ${
                isLoading && "drop-shadow-xl"
              } transition ease-in-out delay-150`}
              type="button"
              onClick={() => sendMpesaEmailSTK()}
              disabled={isLoading}
            >
              {isLoading ? (
                <div>
                  <FiLoader className="animate-spin text-2xl" />
                </div>
              ) : (
                "Pay"
              )}
            </button>
          </form>
        </div>

        <div className=" bottom-3 flex justify-end items-center flex-col text-center">
          <div className="  flex  justify-center items-center  my-12 ">
            <span className="md:w-96 xs:w-max h-12 bg-[#00CDAC33]/20 rounded-2xl flex items-center px-6 ">
              <div className="rounded-full h-6 w-6 bg-primary flex justify-center items-center p-0.5 ">
                <FaLock />
              </div>
              <p className="px-3 md:text-base xs:text-xs  text-primary">
                Secure payment gateway for all your bills
              </p>
            </span>
          </div>
          <p className="text-center text-[10px] text-[#264653] md:px-16 xs:px-2">
            {" "}
            Panga Malipo is a subscription management platform that will help
            you make secure payments. Access your payments subscriptions on
            Panga Malipo using the credentials we’ve sent you. Renew and manage
            subscription fro Panga Malipo platform,
          </p>
        </div>
      </section>
    </main>
  );
};

export default RenewSub;
