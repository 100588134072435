import plansApi from "../../api/plans";
import Plan from "../../models/Plan";
import errorHandler from "../../services/errorHandler";



const newPlan = async(plan: Plan) => {
    try {
        let resp = await (await plansApi.newPlan(plan)).data.data;
        return resp;
    } catch (error) {
        errorHandler(error)
    }
}

const editPlan = async(id: string, plan: Plan) => {
    try {
        let resp = await (await plansApi.editPlan(id, plan)).data.data;
        return resp;
    } catch (error) {
        errorHandler(error)
    }
}

const activatePlan = async(id: string, isActive: boolean) => {
    try {
        let resp = await (await plansApi.activatePlan(id, isActive)).data.data;
        return resp;
    } catch (error) {
        errorHandler(error)
    }
}

const deletePlan = async(planIds: string[]) => {
    try {
        let resp = await (await plansApi.deletePlan(planIds)).data.data;
        return resp;
    } catch (error) {
        errorHandler(error)
    }
}


export { newPlan, editPlan, activatePlan, deletePlan };