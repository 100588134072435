import { useState, useEffect, ChangeEvent, FormEvent } from "react";
import { useParams } from "react-router-dom";
import { useQuery, useMutation } from "react-query";
import { useAppDispatch } from "../../../store/hooks";
import { loadProfile } from "../../../store/slices/profileSlice";
import { useGetProfile } from "../../../utils/hooks";
import { getProfile } from "../../../data/queries/profile";
import { editLogo, editProfile } from "../../../data/mutations/profile";
import Profile, { ICompanyEdit } from "../../../models/Profile";
import Loading from "../../../common/ImageUpload";
import Notification from "../../../common/Notification";
import ModalLoader from "../../../common/ModalLoader";


const EditProfile = () => {
    const dispatch = useAppDispatch();
    const [message, setMessage] = useState('');
    const [error, setError] = useState('');
    const [logo, setLogo] = useState({
        image: '',
        new: false
    });
    const [email, setEmail] = useState('');
    const [state, setState] = useState<ICompanyEdit>({
        name: '',
        phoneNumber: '',
        shortCode: '',
    });

    const { id } = useParams<{id: string}>();

    const { refetch } = useQuery('getProfile', getProfile, {
        onSuccess(data: Profile) {
            dispatch(loadProfile(data));
        }
    });

    const profile = useGetProfile();

    useEffect(() => {        
        if (profile) {
            setLogo({
                ...logo,
                new: false,
                image: profile.company.logoUrl!
            });
            setEmail(profile.email);
            setState({
                ...state,
                name: profile.company.name,
                phoneNumber: profile.company.phoneNumber,
                shortCode: profile.company.shortCode!
            });
        }
    },
    // eslint-disable-next-line 
    [profile]);

    const { mutate: updateLogo, isLoading } = useMutation(editLogo, {
        onSuccess() {
            refetch();
        }
    });

    const { mutate: updateProfile, isLoading: loading } = useMutation(
        () => 
        editProfile(id, state), {
          onSuccess() {
            setTimeout(() => {
              setMessage('');
            }, 5000);
            setMessage('Details saved!');
            refetch();
          },
          onError(error: {message: string}) {
            setTimeout(() => {
              setError('');
            }, 5000);
            setError(error.message);
          }
        }
        );

    const onChange = (e: ChangeEvent<HTMLInputElement>) => setState({
        ...state,
        [e.target.name]: e.target.value
    })

    const onSubmit = (e: FormEvent) => {
        e.preventDefault();

        updateProfile();
    }

    return(
        <div className="edit-profile">
            {
                message !== '' && (
                <Notification
                    message={message}
                    success={true}
                    auto={false}
                />
                )
            }
            {
                error !== '' && (
                <Notification
                    message={error}
                    success={false}
                    auto={false}
                />
                )
            }
            {
                loading && (
                <ModalLoader />
                )
            }
             <div className="edit-label">
                            {
                                (logo.image !== '' && logo.new) && (
                                    <div className="cancel-button">
                                        <button 
                                            className="upload"
                                            onClick={() => {
                                                const formData = new FormData();
                                                formData.append('file', logo.image);

                                                // send request
                                                updateLogo(formData);
                                            }}
                                            disabled={isLoading}
                                            >
                                            <span className="material-icons">upload</span>
                                            <span>
                                                Upload Image
                                            </span>
                                        </button>
                                        <button
                                            type="button"
                                            onClick={() => {
                                                if (profile && profile.logoUrl !== '') {
                                                    return setLogo({
                                                        ...logo,
                                                        image: profile.logoUrl!,
                                                        new: false
                                                    })
                                                }
                                                setLogo({
                                                    ...logo,
                                                    image: ''
                                                })
                                            }}
                                        >
                                            <span>
                                                Cancel
                                            </span>
                                            <span className="material-icons">close</span>
                                        </button>
                                    </div>
                                )
                            }
                            <label className={`upload-label`} htmlFor="upload">
                                {
                                    logo.image !== '' ? (
                                        <img
                                            src={logo.new ? `${URL.createObjectURL(new Blob([logo.image], {
                                                type: 'text/plain'
                                            }))}` : `${logo.image}`}
                                            alt="preview"
                                        />
                                    ) : (
                                        <span>
                                            <span className="material-icons">
                                                upload
                                            </span>
                                            <span className="placeholder">
                                                Upload Logo
                                            </span>
                                        </span>
                                    )
                                }
                                {
                                    logo.image !== '' && (
                                        <div className="faded">
                                            <span>Select</span>
                                        </div>
                                    )
                                }
                                {
                                    isLoading && (
                                        <div className="loader">
                                            <Loading />
                                        </div>
                                    )
                                }
                            </label>
                            <input
                                id="upload"
                                name="logo"
                                placeholder="Upload"
                                type="file"
                                onChange={(e: any) => {
                                    if (e.target.files.length === 0) {
                                        return setLogo({
                                            ...logo,
                                            image: '',
                                            new: false
                                        });
                                    }
                                    
                                    setLogo({
                                        ...logo,
                                        image: e.target?.files[0],
                                        new: true
                                    });
                                }}
                            />
            </div>
            <div className="edit-wrap">
                <div className="body">
                <form onSubmit={onSubmit}>
                    <div className="aligner">
                        <label htmlFor="name">
                            Company Name
                        </label>
                        <input
                            name="name"
                            type="text"
                            placeholder="Name"
                            value={state.name}
                            onChange={onChange}
                        />            
                    </div>
                    <div className="aligner email-nochange">
                        <label htmlFor="email">
                            Email
                        </label>
                        <input
                            name="email"
                            type="text"
                            placeholder="Email"
                            defaultValue={email}
                            disabled
                        />
                        <small className="small">Email cannot be changed</small>        
                    </div>
                    <div  className="aligner">
                        <label htmlFor="name">
                            Telephone Number
                        </label>
                        <input
                            name="phoneNumber"
                            type="text"
                            placeholder="Phone Number"
                            value={state.phoneNumber}
                            onChange={onChange}
                        />
                    </div>
                    <div className="aligner">
                        <label htmlFor="name">
                            Short Code
                        </label>
                        <input
                            name="shortCode"
                            type="number"
                            placeholder="Till Number"
                            value={state.shortCode}
                            onChange={onChange}
                        />
                    </div>
                    <div className="aligner">
                        <button type="button" className="button-action-inv">
                            Cancel
                        </button>
                        <button type='submit' className="button-action btn-2">
                            Save
                        </button>
                    </div>
                </form>
            </div>
            </div>
        </div>
    )
}

export default EditProfile;