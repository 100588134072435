import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import { format } from "date-fns";
import { motion } from "framer-motion";
import { useEffect, useState } from "react";
import { useQuery } from "react-query";
import { useHistory, useParams } from "react-router-dom";
import DashLoader from "../../../common/DashLoader";
import { getClientAccountsAdmin as callClientAccounts } from "../../../data/queries/admin";
import { IClientFetch, MiscDetails } from "../../../models/Account";
import paths from "../../../routes/paths";
import { useAppDispatch, useAppSelector } from "../../../store/hooks";
import { getClientAccounts, loadClientAccounts } from "../../../store/slices/clientsSlice";
import BreadCrumb from "../../layout/Breadcrumb";
import AddMisc from '../../dashboard/account/AddMisc';
import { capitalize } from '../../../services/formValidator';


const headCells = [
       { id: 'vaccno', numeric: true, disablePadding: true, label: 'Account No' },
       { id: 'vdue', numeric: true, disablePadding: false, label: 'Due On' },
       { id: 'vstatus', numeric: true, disablePadding: false, label: 'Status' },
       { id: 'vview', numeric: true, disablePadding: false, label: 'Miscellaneous' },
       { id: 'vviews', numeric: true, disablePadding: false, label: '' }
     ];


const AdminViewClient = () => {
    const dispatch = useAppDispatch();
    const history = useHistory();
    const accounts = useAppSelector(getClientAccounts);
    const { clientId } = useParams<{ clientId: string }>();
    const [message, setMessage] = useState('');
    const [addingMisc, setAddingMisc] = useState(false);
    const [miscValue, setMiscValue] = useState<MiscDetails>({
        itemName: "",
        amount: 0,
        entryType: "",
        entryDate: new Date()
    });
    const [accountId, setAccountId] = useState('');

    useEffect(() => {
        if (message !== '') {
            console.log(message);
        }
    },
    // eslint-disable-next-line
    [])

    const { isLoading, refetch, data } = useQuery(['View client accounts', { clientId }], callClientAccounts, {
        onSuccess(datas: IClientFetch){
            dispatch(loadClientAccounts(datas.allAccounts));
        }
    });

    

    const handleOpenMisc = (valueId: string) => {
        setAddingMisc(true);
        setMiscValue({
            itemName: "",
            amount: 0,
            entryType: "",
            entryDate: new Date()
        });
        setAccountId(valueId);
    };

    return (
        <motion.div
            className="plans"
            animate={{ x: 25 }}
            transition={{ ease: 'easeOut' }}
            >
         
            <div className="add-service-wrap">
                <div className="bread-crumb">
                    {/* <BreadCrumb /> */}
                </div>
            </div>
            <div className="plan-details-wrap">
                <div className="plan-details">
                    <h5 className="plan-title">Client</h5>
                    <div className="border"></div>
                    <div className="details">
                        <p>
                            <span className="span1">Name</span>
                            <span className="span2">
                            {
                                isLoading ? (
                                   <p>...</p>
                                )
                                 : (
                                    `${data && capitalize(data?.client?.firstName!)} ${data && capitalize(data?.client?.lastName! ?? "")}`
                                )
                            }
                            </span>
                        </p>
                        <p>
                            <span className="span1">Email</span>
                            <span className="span2">
                            {
                                isLoading ? (
                                    '...'
                                ) : (
                                    `${data && data?.client?.email!}`
                                )
                            }
                            </span>
                        </p>
                        <p>
                            <span className="span1">Contact Number</span>
                            <span className="span2">
                            {
                                isLoading ? (
                                    '...'
                                ) : (
                                    `${data && capitalize(data.client?.mpesaPhone!)}`
                                )
                            }
                            </span>
                        </p>
                    </div>
                </div>
            </div>
            <div className="title">
                <span>Client Accounts</span>
            </div>
             <div className="wrap">
                <div className="tables">
                    <Table
                        aria-labelledby="tableTitle"
                        aria-label="enhanced table"
                        className="table"
                    >                         
                        <TableHead className="table-header">
                            <TableRow>
                                <TableCell style={{position: 'absolute', border: 'none'}}></TableCell>
                                {headCells.map((headCell: any) => (
                                <TableCell
                                    className="cell"
                                    key={headCell.id}
                                    align='center'
                                >
                                    {headCell.label}
                                </TableCell>
                                ))}
                            </TableRow>
                        </TableHead>
                        {
                            isLoading && (
                                <DashLoader />
                            )
                        }
                        <TableBody>
                            {accounts && accounts.length > 0 && accounts.map((row, index) => {
                            return (
                                <TableRow
                                    hover
                                    role="checkbox"
                                    tabIndex={-1}
                                    key={index}
                                >
                                    <TableCell style={{position: 'absolute', border: 'none'}}></TableCell>
                                    <TableCell
                                        className="clickable"
                                        align="center"
                                        onClick={() => history.push(paths.viewledger.replace(':accountId', `${row.id}`))}
                                        >
                                            {row.accountNumber}
                                    </TableCell>
                                    <TableCell align="center">{format(new Date(row.nextCharge),'yyyy-MM-dd')}</TableCell>
                                    <TableCell
                                        className="activate"
                                        align="right"
                                        >
                                        <button
                                            className={`${row.status === 'PAID' ? 'act' : 'deact'}`}
                                            >
                                            <span className="material-icons">circle</span>
                                            <span>{row.status === 'PAID' ? 'Paid' : `Not Paid`}</span>
                                        </button>
                                    </TableCell>
                                    <TableCell
                                        className="clickable"
                                        align="center"
                                        onClick={() => handleOpenMisc(row.id)}
                                    >
                                        Add
                                    </TableCell>
                                    <TableCell
                                        className="edit"
                                        align="center"
                                        onClick={() => history.push(paths.viewledger.replace(':accountId', `${row.id}`))}
                                        >
                                            View
                                    </TableCell>
                                </TableRow>
                            );
                            })}
                        </TableBody>
                    </Table>
                </div>
            </div> 
            
        </motion.div>
    );
}

export default AdminViewClient;