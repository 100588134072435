import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import { format } from "date-fns";
import { motion } from "framer-motion";
import { useState } from "react";
import { useQuery } from "react-query";
import { useParams } from "react-router-dom";
import DashLoader from "../../common/DashLoader";
import { getLedger as callLedger } from "../../data/queries/client";
import { Ledger } from "../../models/Account";
import { capitalize } from "../../services/formValidator";
import { useAppDispatch, useAppSelector } from "../../store/hooks";
import { getLedger, loadLedger } from "../../store/slices/clientSlice";
import { getProfile } from "../../store/slices/profileSlice";
import MpesaModel from "./MpesaModel";


const LedgerComponent = () => {
    const dispatch = useAppDispatch();
    const ledger = useAppSelector(getLedger);
    const client = useAppSelector(getProfile);
    const { accountId } = useParams<{ accountId: string }>();
    const [mpesa, setMpesa] = useState(false);
    const [message, setMessage] = useState('');
    const [systemAccountNumber, setSystemAccountNumber] = useState('');
    console.log(message);
    
    
    const { isLoading, refetch } = useQuery(['get ledger', { accountId }], callLedger, {
        onSuccess(datas: Ledger){
            dispatch(loadLedger(datas));
            setSystemAccountNumber(datas.systemAccountNumber);
        }
    });

    const wrapBalance = (balance: string) => {
        const balToNumber = Number(balance);
        let returnValue = '';

        if (balToNumber > 0) {
            returnValue = `(${balToNumber})`;
        } else if (balToNumber < 0) {
            returnValue = `${balToNumber * -1}`;
        } else {
            returnValue = `0`;
        }

        return returnValue;
    };

    const headCells = [
        { id: 'llname', numeric: true, disablePadding: true, label: 'Date' },
        { id: 'llprice', numeric: true, disablePadding: false, label: 'Description' },
        { id: 'llmethod', numeric: true, disablePadding: false, label: 'Debit' },
        { id: 'lledit', numeric: true, disablePadding: false, label: 'Credit' },
        { id: 'lldeactivate', numeric: true, disablePadding: false, label: 'Balance' },
      ];

    return (
        <motion.div
            className="plans"
            animate={{ x: 25 }}
            transition={{ ease: 'easeOut' }}
            >
            {
                mpesa && (
                    <MpesaModel
                        mpesa={{ mpesa, setMpesa, systemAccountNumber, refetch }}
                        setMessage={setMessage}
                    />
                )
            }
            <div className="plan-details-wrap">
                <div className="plan-details">
                    <h5 className="plan-title">Client</h5>
                    <div className="border"></div>
                    <div className="details">
                        <p>
                            <span className="span1">Name</span>
                            <span className="span2">
                            {
                                isLoading ? (
                                    '...'
                                ) : (
                                    `${client && client.firstName} ${client && client.lastName}`
                                )
                            }
                            </span>
                        </p>
                        <p>
                            <span className="span1">Email</span>
                            <span className="span2">
                            {
                                isLoading ? (
                                    '...'
                                ) : (
                                    `${client && client.email}`
                                )
                            }
                            </span>
                        </p>
                        <p>
                            <span className="span1">Mpesa Number</span>
                            <span className="span2">
                            {
                                isLoading ? (
                                    '...'
                                ) : (
                                    `${client && capitalize(client.mpesaPhone!)}`
                                )
                            }
                            </span>
                        </p>
                    </div>
                </div>
                <div className="plan-details">
                    <h5 className="plan-title">Billing and Package</h5>
                    <div className="border"></div>
                    <div className="details">
                        <p>
                            <span className="span1">Package</span>
                            <span className="span2">
                            {
                                isLoading ? (
                                    '...'
                                ) : (
                                    `${ledger && ledger.plan.planName}`
                                )
                            }
                            </span>
                        </p>
                        <p>
                            <span className="span1">Price</span>
                            <span className="span2">
                            {
                                isLoading ? (
                                    '...'
                                ) : (
                                    `${ledger && ledger.plan.price}/=`
                                )
                            }
                            </span>
                        </p>
                        <p>
                            <span className="span1">Billing Cycle</span>
                            <span className="span2">
                            {
                                isLoading ? (
                                    '...'
                                ) : (
                                    `${ledger && capitalize(ledger.plan.paymentPeriod!)}`
                                )
                            }
                            </span>
                        </p>
                    </div>
                </div>
            </div>
            <div className="ledger-details">
                <div className="balance">
                    <h5 className="bal-title">
                        Account Balance
                    </h5>
                    <div className="border"></div>
                    <div className="bal-value">
                        <span>Payment Due</span>
                        <span>
                            KES&nbsp;&nbsp;
                            {
                                isLoading ? (
                                    '...'
                                ) : (
                                    `${ledger && wrapBalance(ledger.balance)}/=`
                                )
                            }
                        </span>
                    </div>
                    <p><span className="span1" onClick={() => setMpesa(true)}>Make a payment </span><span className="span2">immediately to avoid service disruption</span></p>
                </div>
                <div className="mpesa">
                    <div className="mpesa-title">
                        <h5>Payment Methods</h5>
                        {/* <h5 className="h-colored">Add Payment Method</h5> */}
                    </div>
                    <div className="border"></div>
                    <div className="mpesa-btn">
                        <button
                            onClick={() => setMpesa(true)}
                        >
                            <span>MPESA</span>
                            <span>Make Payments</span>
                        </button>
                    </div>
                </div>
            </div>
            <div className="title">
                <span>Ledger</span>
            </div>
            <div className="wrap">
                <div className="tables">
                    <Table
                        aria-labelledby="tableTitle"
                        aria-label="enhanced table"
                        className="table"
                    >
                        <TableHead className="table-header">
                            <TableRow>
                                <TableCell style={{position: 'absolute', border: 'none'}}></TableCell>
                                <TableCell padding="checkbox" align="left">
                                    
                                </TableCell>
                                {headCells.map((headCell: any) => (
                                <TableCell
                                    className="cell"
                                    key={headCell.id}
                                    align='center'
                                    padding={headCell.disablePadding ? 'none' : 'normal'}
                                >
                                    {headCell.label}
                                </TableCell>
                                ))}
                            </TableRow>
                        </TableHead>
                        {
                            isLoading && (
                                <DashLoader />
                            )
                        }
                        <TableBody>
                            {
                                ledger && ledger.ledgerWithBalance && ledger.ledgerWithBalance.length > 0 && ledger.ledgerWithBalance.map((row: any, index: number) => {
                            return (
                                <TableRow
                                    hover
                                    tabIndex={-1}
                                    key={index}
                                >
                                    <TableCell style={{position: 'absolute', border: 'none'}}></TableCell>
                                    <TableCell padding="checkbox" align="left">
                                        
                                    </TableCell>
                                    <TableCell align="center">{format(new Date(row.createdOn),'yyyy-MM-dd')}</TableCell>
                                    <TableCell align="center"><i>{row.description}</i></TableCell>
                                    <TableCell align="center">{row.DR}</TableCell>
                                    <TableCell align="center">{row.CR}</TableCell>
                                    <TableCell align="center">{row.balanceLedger}</TableCell>
                                </TableRow>
                            );
                            })}
                        </TableBody>
                    </Table>
                </div>
            </div>
        </motion.div>
    );
}

export default LedgerComponent;