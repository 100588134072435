import { useState, FormEvent, ChangeEvent } from "react";
import { useMutation } from "react-query";
import { motion } from "framer-motion";
import AuthNotification from "../../common/AuthNotification";
import { changePassword } from "../../data/mutations/auth";
import formValidator from "../../services/formValidator";
import Loading from "../../common/Loading";
import { useMediaQuery } from "@material-ui/core";
import { useHistory } from "react-router-dom";


export interface IChangePassword {
    oldPassword: string,
    newPassword: string
}

const ChangePassword = () => {
    const matches = useMediaQuery('(max-width:1024px)');
    const history = useHistory();
    const [state, setState] = useState<IChangePassword>({
        oldPassword: '',
        newPassword: ''
    });
    const [confirmPassword, SetConfirmPassword] = useState('');

    const [formError, setFormError] = useState({
        status: false,
        name: '',
        message: ''
    });

    const [error, setError] = useState('');

    const { mutate: changePass, isLoading } = useMutation(
        () =>
        changePassword(state, history), {
          onError: (error: {message: string}) => {
            setTimeout(() => {
                setError('');
            }, 5000);
            setError(error.message);   
          }
        }
      );

    const onChange = (e: ChangeEvent<HTMLInputElement>) => setState({
        ...state,
        [e.target.name]: e.target.value
    });

    const onSubmit = (e: FormEvent) => {
        e.preventDefault();

        const { status, name, message } = formValidator(state);

        if (state.newPassword !== confirmPassword) {
            setTimeout(() => {
                setFormError({
                    status: false,
                    name: '',
                    message: ''
                })
            }, 5000);
            return setFormError({
                status: true,
                name: 'confirm-password',
                message: 'Passwords do not match' 
            });
        }

        if (status) {
            setTimeout(() => {
                setFormError({
                    status: false,
                    name: '',
                    message: ''
                })
            }, 5000);
            return setFormError({
                status,
                name,
                message 
            });
        }
        // changepass
        changePass();
    }

    return(
        <motion.div
            className="auth-right"
            animate={{ x: matches ? 0 : 40 }}
            transition={{ ease: 'easeOut' }}
        >
            {
                isLoading && <Loading />
            }
            {
                error !== '' && (
                    <AuthNotification
                        title='Error'
                        message={error}
                        success={'error'}
                        auto={true}
                    />
            )}
            <div className="verify-middle">
                <div className="top">
                    <p>Please change your default password to continue</p>
                </div><br />
                <form onSubmit={onSubmit}>
                    <div className="center">         
                    <div>
                            <label>Old Password</label>
                            <input
                                className={`${formError.name === 'oldPassword' && 'input-error'}`}
                                name="oldPassword"
                                placeholder="Enter Old Password"
                                type="password"
                                value={state.oldPassword}
                                onChange={onChange}
                            />
                            {
                                formError.name === 'oldPassword' && (
                                    <small>{formError.message}</small>
                                )
                            }
                        </div>
                        <div>
                            <label>New Password</label>
                            <input
                                className={`${formError.name === 'newPassword' && 'input-error'}`}
                                name="newPassword"
                                placeholder="Enter New Password"
                                type="password"
                                value={state.newPassword}
                                onChange={onChange}
                            />
                            {
                                formError.name === 'newPassword' && (
                                    <small>{formError.message}</small>
                                )
                            }
                        </div>
                        <div>
                            <label>Confirm Password</label>
                            <input
                                className={`${formError.name === 'confirm-password' && 'input-error'}`}
                                name="confirmPassword"
                                placeholder="Confirm Password"
                                type="password"
                                value={confirmPassword}
                                onChange={(e) => SetConfirmPassword(e.target.value)}
                            />
                            {
                                formError.name === 'confirm-password' && (
                                    <small>{formError.message}</small>
                                )
                            }
                        </div>
                    </div>
                    <div className="bottom">
                        <div>
                            <button className="button-primary btn-w-2" type="submit">
                                Next
                            </button>
                        </div>
                    </div>
                </form>
            </div>               
        </motion.div>
    )
}

export default ChangePassword;