import React from 'react'

interface IStatTab{
       icon: any;
       amount:string;
       subtext:string;
       loading:boolean;
}

const StatTab = ({ icon,
       amount,
       subtext,loading}:IStatTab) => {
  return (
       <section className="stat-tabs">
       <div className="stat-icon">{icon}
</div>
       <div className="stat-info">
         <p className="stat-amount">{loading ? '...' : amount}</p>
         <p className="stat-subtext">{ subtext}</p>
       </div>
     </section>
)}

export default StatTab
