import { configureStore } from '@reduxjs/toolkit'
import rootReducer from "./rootReducer";

const env = process.env.NODE_ENV === "development";

const store = configureStore({
    reducer: rootReducer,
    devTools: env
});

export default store;
export type AppDispatch = typeof store.dispatch;