import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import { format } from "date-fns";
import { motion } from "framer-motion";
import { useQuery } from "react-query";
import { useHistory, useParams } from "react-router-dom";
import DashLoader from "../../common/DashLoader";
import { getCompanyAccounts as callCompanyAccounts } from "../../data/queries/client";
import { CompanyAccount } from "../../models/Account";
import paths from "../../routes/paths";
import { useAppDispatch, useAppSelector } from "../../store/hooks";
import { getCompanyAccounts, loadCompanyAccounts } from "../../store/slices/clientSlice";


const CompanyAccounts = () => {
    const history = useHistory();
    const dispatch = useAppDispatch();
    const companyAccounts = useAppSelector(getCompanyAccounts);    
    const { companyId } = useParams<{ companyId: string }>();

    const { isLoading } = useQuery(['get company accounts', { companyId }], callCompanyAccounts, {
        onSuccess(datas: CompanyAccount[]){
            dispatch(loadCompanyAccounts(datas));
        }
    });

    const headCells = [
        { id: 'cccaccno', numeric: true, disablePadding: true, label: 'Account Number' },
        { id: 'cccplan', numeric: true, disablePadding: false, label: 'Package' },
        { id: 'cccdue', numeric: true, disablePadding: false, label: 'Due On' },
        { id: 'cccstatus', numeric: true, disablePadding: false, label: 'Status' },
        { id: 'cccview', numeric: true, disablePadding: false, label: '' },
      ];

    return (
        <motion.div
            className="plans"
            animate={{ x: 25 }}
            transition={{ ease: 'easeOut' }}
            >
            <div className="title">
                <span>Accounts</span>
            </div>
            <div className="wrap">
                <div className="table-search">
                    <div className="left">
                        <div className="search-input">
                            <input
                                type="text"
                                placeholder="Search"
                            />
                        </div>
                    </div>
                </div>
                <div className="tables">
                    <Table
                        aria-labelledby="tableTitle"
                        aria-label="enhanced table"
                        className="table"
                    >
                        <TableHead className="table-header">
                            <TableRow>
                                <TableCell style={{position: 'absolute', border: 'none'}}></TableCell>
                                <TableCell padding="checkbox" align="left">
                                    
                                </TableCell>
                                {headCells.map((headCell: any) => (
                                <TableCell
                                    className="cell"
                                    key={headCell.id}
                                    align='center'
                                    padding={headCell.disablePadding ? 'none' : 'normal'}
                                >
                                    {headCell.label}
                                </TableCell>
                                ))}
                            </TableRow>
                        </TableHead>
                        {
                            isLoading && (
                                <DashLoader />
                            )
                        }
                        <TableBody>
                            {companyAccounts && companyAccounts.length > 0 && companyAccounts.map((row, index) => {

                            return (
                                <TableRow
                                    hover
                                    tabIndex={-1}
                                    key={index}
                                >
                                    <TableCell style={{position: 'absolute', border: 'none'}}></TableCell>
                                    <TableCell padding="checkbox" align="left">
                                        
                                    </TableCell>
                                    <TableCell
                                        className="clickable"
                                        align="center"
                                        onClick={() => history.push(paths.ledger.replace(':accountId', `${row.id}`))}
                                        >
                                            {row.accountNumber}
                                    </TableCell>
                                    <TableCell align="center">{row.plan.planName}</TableCell>
                                    <TableCell align="center">{format(new Date(row.nextCharge),'yyyy-MM-dd')}</TableCell>
                                    <TableCell
                                        className="activate"
                                        align="right"
                                        >
                                        <button
                                            className={`${row.isActive ? 'act' : 'deact'}`}
                                            >
                                            <span className="material-icons">circle</span>
                                            <span>{row.isActive ? 'Active' : `Inactive`}</span>
                                        </button>
                                    </TableCell>
                                    <TableCell
                                        className="edit"
                                        align="center"
                                        onClick={() => history.push(paths.ledger.replace(':accountId', `${row.id}`))}
                                        >
                                            View
                                    </TableCell>
                                </TableRow>
                            );
                            })}
                        </TableBody>
                    </Table>
                </div>
            </div>
        </motion.div>
    );
}

export default CompanyAccounts;