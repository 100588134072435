import { ReactType } from "react";
import { Route, Redirect } from "react-router-dom";
import Sidebar from "../components/layout/Sidebar";
import Appbar from "../components/layout/Appbar";
import { useAuth, useClient } from "../utils/hooks/index";
import paths from "./paths";
import { useMediaQuery } from "@material-ui/core";


interface IProps {
    component: ReactType;
    path: string;
    exact: boolean;
}

const ClientRoute = ({ component: Component, ...rest}: IProps) => {
    const isAuthenticated = useAuth();
    const isClient = useClient();
    const matches = useMediaQuery('(max-width: 1024px)');

    // const passStatus = localStorage.__passStatus__;    

    return <Route
        {...rest}
        render={props => (
            (isAuthenticated && isClient) ? (
                // !passStatus ? (
                    <div className="dashboard-cover">
                        {
                            !matches && (
                                <Sidebar
                                    toggleDrawer={() => {}}
                                />
                            )
                        }
                        <div className="content">
                            <Appbar />
                            <Component {...props} />
                        </div>
                    </div>
                // ) : (
                //     <Redirect to={paths.changepassword} />
                // )
            ) : (
                <Redirect to={paths.landing} />
            )
        )
    }
  />
};

export default ClientRoute;