import axios from "axios";
import { baseUrl } from "./base";
import { IMpesaPayload } from "../components/clientcomponents/MpesaModel";

export interface IEmailStk {
  mpesaPhone: string;
  emailData: string;
}
const clientApi = {
  getCompanies: () => {
    return axios.get(`${baseUrl}/client-manager/companies`);
  },
  getCompanyAccounts: (companyId: string) => {
    return axios.get(`${baseUrl}/client-manager/${companyId}/accounts`);
  },
  getLedger: (accountId: string) => {
    return axios.get(`${baseUrl}/client-manager/${accountId}/ledger`);
  },
  sendSTK: (isCompany: boolean, accountId: string, payload: IMpesaPayload) => {
    const endpoint = isCompany
      ? `/plan-manager/mpesa/${accountId}`
      : `/plan-manager/mpesa/${accountId}`;
    return axios.post(`${baseUrl}${endpoint}`, payload);
  },
  renewSubscription: (payload: IEmailStk) => {
    return axios.post(`${baseUrl}/plan-manager/mpesa/email-stk/push`, payload);
  },
};

export default clientApi;
