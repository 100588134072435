import adminApi from "../../api/admin";
import Account, { CompanyAccount } from "../../models/Account";
import errorHandler from "../../services/errorHandler";


const getCompaniesAdmin = async(params: any) => {
    const [, { state }] = params.queryKey;

    try {
        let resp = await (await adminApi.getCompaniesAdmin(state)).data.data as Account[];        
        return resp;
    } catch (error) {
        errorHandler(error)
    }
}

const getPackagesAdmin = async(params: any) => {
    const [, { state }] = params.queryKey;

    try {
        let resp = await (await adminApi.getPackagesAdmin(state)).data.data as Account[];        
        return resp;
    } catch (error) {
        errorHandler(error)
    }
}

const getPackageAdmin = async(params: any) => {
    const [, { state }] = params.queryKey;

    try {
        let resp = await (await adminApi.getPackageAdmin(state)).data.data as Account[];        
        return resp;
    } catch (error) {
        errorHandler(error)
    }
}

const getAccountsAdmin = async(params: any) => {
    const [, { state }] = params.queryKey;

    try {
        let resp = await (await adminApi.getAccountsAdmin(state)).data.data as Account[];        
        return resp;
    } catch (error) {
        errorHandler(error)
    }
}

const getClientsAdmin = async(params: any) => {
    const [, { state }] = params.queryKey;

    try {
        let resp = await (await adminApi.getClientsAdmin(state)).data.data as Account[];        
        return resp;
    } catch (error) {
        errorHandler(error)
    }
}

    const searchAdminClient = async(searchString: string) => {
        try {
            let resp = await (await adminApi.searchClientAdmin(searchString)).data.data;
            return resp;
        } catch (error) {
            errorHandler(error);
        }
}

const getClientAccountsAdmin = async(params: any) => {
    const [, { clientId }] = params.queryKey;
    
    try {
        let resp = await (await adminApi.getClientAccountsAdmin(clientId)).data.data as CompanyAccount[];             
        return resp;
    } catch (error) {
        errorHandler(error)
    }
}

const getAdminAnalytics = async(params: any) => {
    const [, { state }] = params.queryKey;

    try {
        let resp = await (await adminApi.getAdminAnalytics(state)).data.data as Account[];        
        return resp;
    } catch (error) {
        errorHandler(error)
    }
}

const getTransactionHistory = async(params: any) => {
    const [, { state }] = params.queryKey;

    try {
        let resp = await (await adminApi.getTransactionHistory(state)).data.data as Account[];        
        return resp;
    } catch (error) {
        errorHandler(error)
    }
}

export { getCompaniesAdmin, getPackagesAdmin, getAccountsAdmin, getClientsAdmin ,getPackageAdmin,searchAdminClient,getClientAccountsAdmin,getAdminAnalytics,getTransactionHistory};