import React, { ReactType } from "react";
import { Route, Redirect } from "react-router-dom";
import { motion } from "framer-motion";
import { useAuth, useGetProfile } from "../utils/hooks/index";
import paths from "./paths";


interface IProps {
    component: ReactType;
    path: string;
    exact: boolean;
}

const VerifyRoute = ({ component: Component, ...rest}: IProps) => {
    const isAuthenticated = useAuth();
    const profile = useGetProfile();

    return <Route
        {...rest}
        render={props => (
            (isAuthenticated && (profile === null || (profile && true))) ? (
                <div className="auth-cover">
                    <div className="auth-white-cover">
                        <div className="auth-float">
                            <motion.div className="auth-left" whileHover={{ scale: 1 }} whileTap={{scale: 0}} ></motion.div>
                            <Component {...props} />
                        </div>
                    </div>
                </div>
            ) : (
                <Redirect to={paths.landing} />
            )
        )
    }
  />
};

export default VerifyRoute;