import React, { useState, ChangeEvent } from "react";
import { useHistory } from "react-router-dom";
import { useMutation } from "react-query";
import { motion } from "framer-motion";
import { verifyCode, sendSms } from "../../data/mutations/auth";
import AuthNotification from "../../common/AuthNotification";
import paths from "../../routes/paths";
import Loading from "../../common/Loading";
import formValidator from "../../services/formValidator";


const SMSNotification = () => {
    const history = useHistory();

    const [success, setSuccess] = useState('');

    const [state, setState] = useState({
        code: ''
    });

    const [formError, setFormError] = useState({
        status: false,
        name: '',
        message: ''
    });

    const { mutate: verify, isLoading } = useMutation(verifyCode, {
        onSuccess(){
            setSuccess('The code has been verified!');
            setTimeout(() => {
                history.push(paths.plans)
            }, 3000);
        }
    });

    const { mutate: resendCode, isLoading: loading } = useMutation(sendSms, {
        onSuccess(){
            setSuccess('Request resent! Please check your phone');
        }
    });

    const onChange = (e: ChangeEvent<HTMLInputElement>) => setState({
        ...state,
        [e.target.name]: e.target.value
    })

    return(
        <motion.div
            className="auth-right"
            animate={{ x: 40 }}
            transition={{ ease: 'easeOut' }}
        >
            {
                (isLoading || loading) && <Loading />
            }
            {
                success !== '' && (
                    <AuthNotification
                        title="Success"
                        message={success}
                        success={'success'}
                        auto={true}
                    />
            )}
            <div className="verify-middle">
                {
                    true && (
                        <AuthNotification
                            title="Phone verification code sent"
                            message="Enter the code below to verify"
                            success={'default'}
                            auto={false}
                        />
                )}
                <div className="center">
                    <div>
                        <input
                            name="code"
                            type="number"
                            value={state.code}
                            onChange={onChange}
                        />
                        {
                                formError.name === 'code' && (
                                    <small>{formError.message}</small>
                                )
                            }
                    </div>
                    <div className="terms">
                        <p>Didn't receive code? <b onClick={() => resendCode()}>Resend</b></p>
                    </div>
                    <div className="verify-buttons">
                        <button 
                            className="button-primary btn-w-1"
                            onClick={() => {
                                const { status, name, message } = formValidator(state);
                                if (status) {
                                    setTimeout(() => {
                                        setFormError({
                                            status: false,
                                            name: '',
                                            message: ''
                                        })
                                    }, 5000);
                                    return setFormError({
                                        status,
                                        name,
                                        message 
                                    });
                                }
                                verify(state.code)
                            }}
                            >
                            Verify
                        </button>
                    </div>
                </div>
            </div>
            </motion.div>
    );
};

export default SMSNotification;