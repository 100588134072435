import Spinner from "../../assets/images/spinner.gif";
import { useQuery } from "react-query";
import Company from "../../models/Company";
import { useDispatch } from "react-redux";
import { getAdminCompanies, loadAdminCompanies } from "../../store/slices/adminSlice";
import { getCompaniesAdmin as callCompanies } from "../../data/queries/admin";
import { useAppSelector } from "../../store/hooks";
import paths from "../../routes/paths";
import { useHistory } from "react-router-dom";
import { IControllers } from "../dashboard/plan/Plans";
import { useState } from "react";


const AdminDashboard = () => {
    const dispatch = useDispatch();
    const history = useHistory();
    const companies = useAppSelector(getAdminCompanies);

    const [state, setState] = useState<IControllers>({
        page: 0,
        rowsPerPage: 10,
        limit: 10,
        skip: 0,
        companyId:''
    });

    const { refetch, isLoading } = useQuery(['get clients', { state }], callCompanies, {
        onSuccess(datas: { total: number, companies: Company[] }){
            dispatch(loadAdminCompanies(datas.companies));
            // dispatch(loadCount(datas.total));
        }
    });

    const bgColor = () => ['red','orange','yellow','green','blue','purple'][Math.random()*6|0];

    return (
        <div className="client-dashboard">
            <div className="wrapper">
                <div className="h4-div">
                    <p>Companies</p>
                </div>
                {
                    isLoading && (
                        <div className="loader">
                            <img
                                src={Spinner}
                                alt=""
                            />
                        </div>
                    )
                }
                <div className="companies">
                    {
                        companies && companies.length > 0 && companies.map((company, i) => 
                            <div
                                className="card"
                                key={i}
                                onClick={() => history.push(paths.adminsinglecompany.replace(':companyId', `${company.id}`))}
                                >
                                <div className="image-div">
                                    {
                                        company.logoUrl ? (
                                            <img
                                                src={company.logoUrl}
                                                alt=""
                                            />
                                        ) : (
                                            <p className={`img-alt ${bgColor()}`}>{company.name.toUpperCase().charAt(0)}</p>
                                        )
                                    }
                                </div>
                                <div className="typo"><span>{company.name}</span></div>
                            </div>
                        )
                    }
                </div>
            </div>
        </div>
    );
}

export default AdminDashboard;