// src/themes.js

import { createTheme } from "@material-ui/core";

// import { createTheme } from '@mui/material';
// import { blueGrey, cyan } from '@mui/material/colors';

export const lightTheme = createTheme({
  breakpoints: {
    values: {
      xs: 0,
      sm: 600,
      md: 900,
      lg: 1200,
      xl: 1536,
    },
  },
//   shdows: ['0px 4px 19px rgba(119, 147, 65, 0.3)'],
//   typography: {
//     fontFamily: `'Poppins', sans-serif`,

//     button: {
//       textTransform: 'none',
//       fontSize: '12px',
//       fontWeight: 400,
//     },
//   },
  components: {
    MuiButton: {
      styleOverrides: {
        root: {
          borderRadius: '8px',
          textTransform: 'titleCase ',

          // border-radius: 10px,
        },
      },
    },
  },
  shape: {
    borderRadius: 0,
  },
  palette: {
    mode: 'light',
    background: {
      default: '#FBFDFF',
      white: '#FFFFFF',
    },
    primary: {
      main: '#00CDAC',
      default: 'white',
      iconDefaultColor: '#A3A3A5',
      textColor: '#808191',
      badgeColor: '#FF754C',
      otherTextColor: '#979797',
    },
    secondary: {
      main: '#2F3CED',
    },
    badgeColor: {
      main: '#FF754C',
    },
  },
});

