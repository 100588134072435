import {
  Box,
  Grid,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
  Typography,
} from "@material-ui/core";
import React, { useState } from "react";
import Plans, { IControllers } from "../../dashboard/plan/Plans";
import Accounts from "../../dashboard/account/Accounts";
import { useHistory, useParams } from "react-router-dom";
import { useQuery } from "react-query";
import { useAppDispatch, useAppSelector } from "../../../store/hooks";
import { loadAdminPlans } from "../../../store/slices/adminSlice";
import { getPackageAdmin as getPackage } from "../../../data/queries/admin";
import { getAccounts as allAccounts } from "../../../data/queries/account";
import { CompanyAccount } from "../../../models/Account";
import {
  getAccounts,
  getAccountsCount,
  loadAccounts,
  loadPlanName,
} from "../../../store/slices/accountSlice";
import { loadCount } from "../../../store/slices/planSlice";
import { format } from "date-fns";
import paths from "../../../routes/paths";

const headCells = [
  {
    id: "aaccno",
    numeric: true,
    disablePadding: true,
    label: "Account Number",
  },
  { id: "aname", numeric: true, disablePadding: false, label: "Client" },
  { id: "adue", numeric: true, disablePadding: false, label: "Due On" },
  { id: "astatus", numeric: true, disablePadding: false, label: "Status" },
  { id: "aview", numeric: true, disablePadding: false, label: "Miscellaneous" },
  { id: "aviews", numeric: true, disablePadding: false, label: "" },
];

const AdminViewPackage = () => {
  const dispatch = useAppDispatch();
  const history = useHistory();
  const accounts = useAppSelector(getAccounts);
  const count = useAppSelector(getAccountsCount);
  const { planId } = useParams<{ planId: string }>();
  const [state, setState] = useState<IControllers>({
    page: 0,
    rowsPerPage: 10,
    limit: 10,
    skip: 0,
    companyId: "",
  });

  const { data, refetch, isLoading } = useQuery(
    ["get accounts", { planId, state }],
    allAccounts,
    {
      onSuccess(datas: {
        total: number;
        planName?: string;
        accounts: CompanyAccount[];
      }) {
        dispatch(loadAccounts(datas.accounts));
        dispatch(loadCount(datas.total));
        dispatch(loadPlanName(datas.planName!));
      },
    }
  );


  return (
    <main className="admin-company-dashboard ">
      <div className="wrapper">
        <div className="h4-div">
          <p>Accounts on Package </p>
        </div>

        <Box
          component="section"
          sx={{
            padding: "1rem 1rem",
          }}
        >
          <Box
            sx={{
              // height: "187px",
              // width:'100%',
              border: "1px solid #A2A4B9C7",
              borderRadius: "1rem",
              p: 4,
            }}
            bgcolor="background.white"
          >
            <Typography variant="h6" gutterBottom className="statHeader">
              {data?.planName}
            </Typography>
            <div className="pstat-info">
              <p>5,000</p>
              <p>Pro-rated</p>
            </div>
          </Box>
        </Box>

      
       <div className="wrap">
       <Box bgcolor={'primary.default'} >
          <div className="tables">
            <Table
              aria-labelledby="tableTitle"
              aria-label="enhanced table"
              className="table"
            >
              <TableHead className="table-header">
                <TableRow>
                  <TableCell
                    style={{ position: "absolute", border: "none" }}
                  ></TableCell>
                  {headCells.map((headCell: any) => (
                    <TableCell
                      className="cell"
                      key={headCell.id}
                      align="center"
                    >
                      {headCell.label}
                    </TableCell>
                  ))}
                </TableRow>
              </TableHead>
              {isLoading && <h1></h1>}
              <TableBody>
                {accounts &&
                  accounts.length > 0 &&
                  accounts.map((row: any) => {
                    return (
                      <TableRow
                        hover
                        role="checkbox"
                        tabIndex={-1}
                        key={row?.id}
                      >
                        <TableCell
                          style={{ position: "absolute", border: "none" }}
                        ></TableCell>
                        <TableCell
                          className="clickable"
                          align="center"
                          // onClick={() => history.push(paths.viewledger.replace(':accountId', `${row.id}`))}
                        >
                          {row.accountNumber}
                        </TableCell>
                        <TableCell className="clickable" align="center">
                          {row.client?.firstName} {row.client?.lastName}
                        </TableCell>
                        <TableCell align="center">
                          {format(new Date(row.nextCharge), "yyyy-MM-dd")}
                        </TableCell>
                        <TableCell className="activate" align="right">
                          <button
                            className={`${
                              row.status === "PAID" ? "act" : "deact"
                            }`}
                          >
                            <span className="material-icons">circle</span>
                            <span>
                              {row.status === "PAID" ? "Paid" : `Not Paid`}
                            </span>
                          </button>
                        </TableCell>
                        {/* <TableCell
                                        className="clickable"
                                        align="center"
                                        onClick={() => handleOpenMisc(row.id)}
                                        >
                                            Add
                                    </TableCell> */}
                        <TableCell className="edit" align="center" onClick={() => history.push(paths.accledger.replace(':accountId', `${row.id}`))}>
                          View
                        </TableCell>
                      </TableRow>
                    );
                  })}
              </TableBody>
            </Table>
            {/* <div className="pagination">
                        <TablePagination
                            component="div"
                            count={count}
                            page={state.page}
                            onPageChange={handleChangePage}
                            rowsPerPage={state.rowsPerPage}
                            onRowsPerPageChange={handleChangeRowsPerPage}
                        />
                    </div> */}
          </div>
          </Box>
        </div>
     
      </div>
    </main>
  );
};

export default AdminViewPackage;
