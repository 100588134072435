import clientApi, { IEmailStk } from "../../api/client";
import { IMpesaPayload } from "../../components/clientcomponents/MpesaModel";
import errorHandler from "../../services/errorHandler";

const sendSTK = async(isCompany: boolean, accountId: string, payload: IMpesaPayload) => {
    
    try {
        let resp = await (await clientApi.sendSTK(isCompany, accountId, payload)).data.data;        
        return resp;
    } catch (error) {
        errorHandler(error)
    }
}

const renewSubscription = async(payload: IEmailStk) => {
    
    try {
        let resp = await (await clientApi.renewSubscription( payload)).data.data;        
        return resp;
    } catch (error) {
        errorHandler(error)
    }
}

export { sendSTK ,renewSubscription};