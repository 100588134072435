import { useState, ChangeEvent, FormEvent } from "react";
import { useMutation } from "react-query";
import { useHistory } from "react-router-dom";
import { motion } from "framer-motion";
import AuthNotification from "../../common/AuthNotification";
import { sendSTKDemo } from "../../data/mutations/demo";
import { demoValidator } from "../../services/formValidator";
import paths from "../../routes/paths";
import Loading from "../../common/Loading";
import { useMediaQuery } from "@material-ui/core";

export interface IDemo {
    phoneNumber: string;
}

const DemoPage = () => {
    const matches = useMediaQuery('(max-width:1024px)');
    const [state, setState] = useState({
        phoneNumber: ''
    });

    const [formError, setFormError] = useState({
        status: false,
        name: '',
        message: ''
    });

    const [success, setSuccess] = useState('');

    const history = useHistory();

    const { mutate: sendMpesaSTKDemo, isLoading } = useMutation(
        () => 
        sendSTKDemo({ phoneNumber: `+${state.phoneNumber}` }), {
          onSuccess(datas: { customerMessage: string }) {
            setTimeout(() => {
                setSuccess('');
              }, 5000);
              setSuccess('Request was successful!');
          },
          onError(error: {message: string}) {
            setTimeout(() => {
            setFormError({
                status: false,
                name: '',
                message: ''
            });
            }, 5000);
            setFormError({
                status: true,
                name: 'phoneNumber',
                message: error.message
            });
          }
        }
    );

    const onChange = (e: ChangeEvent<HTMLInputElement>) => setState({
        ...state,
        [e.target.name]: e.target.value
    });

    const onSubmit = (e: FormEvent) => {
        e.preventDefault();

        const { status, name, message } = demoValidator(state);
        if (status) {
            setTimeout(() => {
                setFormError({
                    status: false,
                    name: '',
                    message: ''
                })
            }, 5000);
            return setFormError({
                status,
                name,
                message 
            });
        }
        // send request
        sendMpesaSTKDemo();
    }

    return(
        <motion.div
            className="auth-right"
            animate={{ x: matches ? 0 : 40 }}
            transition={{ ease: 'easeOut' }}
        >
            {
                isLoading && <Loading />
            }
            {
                success !== '' && (
                    <AuthNotification
                        title="Success"
                        message={success}
                        success={'success'}
                        auto={true}
                    />
            )}
            <div className="verify-middle">
                {
                    true && (
                        <AuthNotification
                            title="Want a Demo?"
                            message="Please enter the phone number to continue"
                            success={'default'}
                            auto={false}
                        />
                )}
                <form onSubmit={onSubmit}>
                    <div className="center">
                        <div>
                            <label htmlFor="phoneNumber">Phone Number</label>
                            <input
                                className={`${formError.name === 'phoneNumber' && 'input-error'}`}
                                id="phoneNumber"
                                type="text"
                                placeholder="Phone number"
                                name="phoneNumber"
                                value={state.phoneNumber}
                                onChange={onChange}
                            />
                            {
                                formError.name === 'phoneNumber' && (
                                    <small>{formError.message}</small>
                                )
                            }
                        </div>
                        <div className="bottom">
                        <div>
                            <button className="button-primary btn-w-2" type="submit">
                                Submit
                            </button>
                            <span className="terms forgot-pass">
                                <span onClick={() => history.push(paths.login)}>Login</span>
                            </span>
                        </div>
                    </div>
                    </div>
                </form>
            </div>
        </motion.div>
    );
};

export default DemoPage;