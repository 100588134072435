import { createSlice, PayloadAction } from '@reduxjs/toolkit'
import type { RootState } from '../rootReducer';
import Company from '../../models/Company';
import { CompanyAccount, Ledger } from '../../models/Account';

interface ClientState {
  companies: Company[];
  companyAccounts: CompanyAccount[];
  ledger: Ledger | null;
}

const initialState: ClientState = {
  companies: [],
  companyAccounts: [],
  ledger: null

}

export const clientSlice = createSlice({
  name: 'client',
  initialState,
  reducers: {
    loadCompanies: (state, action: PayloadAction<Company[]>) => {
      state.companies = action.payload;
    },
    loadCompanyAccounts: (state, action: PayloadAction<CompanyAccount[]>) => {
      state.companyAccounts = action.payload;
    },
    loadLedger: (state, action: PayloadAction<Ledger>) => {
      state.ledger = action.payload;
    }
  },
});

export default clientSlice.reducer;

export const { loadCompanies, loadCompanyAccounts, loadLedger } = clientSlice.actions;
export const getCompanies = (state: RootState) => state.client.companies;
export const getCompanyAccounts = (state: RootState) => state.client.companyAccounts;
export const getLedger = (state: RootState) => state.client.ledger;