import { createSlice, PayloadAction } from '@reduxjs/toolkit'
import type { RootState } from '../rootReducer';
import { CompanyAccount } from "../../models/Account";

interface AccountState {
  accounts: CompanyAccount[];
  count: number;
  planName?: string;
}

const initialState: AccountState = {
  accounts: [],
  count: 0,
  planName: ''
}

export const accountSlice = createSlice({
  name: 'account',
  initialState,
  reducers: {
    loadAccounts: (state, action: PayloadAction<CompanyAccount[]>) => {
      state.accounts = action.payload;
    },
    loadCount: (state, action: PayloadAction<number>) => {
      state.count = action.payload;
    },
    loadPlanName: (state, action: PayloadAction<string>) => {
      state.planName = action.payload;
    }
  },
});

export default accountSlice.reducer;

export const { loadAccounts, loadCount, loadPlanName } = accountSlice.actions;
export const getAccounts = (state: RootState) => state.account.accounts;
export const getAccountsCount = (state: RootState) => state.account.count;
export const getPlanName = (state: RootState) => state.account.planName;