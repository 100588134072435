import React, { useState, ChangeEvent, FormEvent } from "react";
import { useMutation } from "react-query";
import { useHistory } from "react-router-dom";
import { motion } from "framer-motion";
import AuthNotification from "../../common/AuthNotification";
import { forgotPassword } from "../../data/mutations/auth";
import formValidator from "../../services/formValidator";
import paths from "../../routes/paths";
import Loading from "../../common/Loading";
import { useMediaQuery } from "@material-ui/core";


const ForgotPassword = () => {
    const matches = useMediaQuery('(max-width:1024px)');
    const [state, setState] = useState({
        email: ''
    });

    const [formError, setFormError] = useState({
        status: false,
        name: '',
        message: ''
    });

    const [success, setSuccess] = useState('');

    const history = useHistory();

    const { mutate: forgotPass, isLoading } = useMutation(forgotPassword, {
        onSuccess(){
            setTimeout(() => {
                setSuccess('');
                history.push(paths.login);
            }, 5000);
            setSuccess('Request successful! Please check your email');
            setState({
                ...state,
                email: ''
            });
        }
    });

    const onChange = (e: ChangeEvent<HTMLInputElement>) => setState({
        ...state,
        [e.target.name]: e.target.value
    });

    const onSubmit = (e: FormEvent) => {
        e.preventDefault();

        const { status, name, message } = formValidator(state);
        if (status) {
            setTimeout(() => {
                setFormError({
                    status: false,
                    name: '',
                    message: ''
                })
            }, 5000);
            return setFormError({
                status,
                name,
                message 
            });
        }
        // send request
        forgotPass(state)
    }

    return(
        <motion.div
            className="auth-right"
            animate={{ x: matches ? 0 : 40 }}
            transition={{ ease: 'easeOut' }}
        >
            {
                isLoading && <Loading />
            }
            {
                success !== '' && (
                    <AuthNotification
                        title="Success"
                        message={success}
                        success={'success'}
                        auto={true}
                    />
            )}
            <div className="verify-middle">
                {
                    true && (
                        <AuthNotification
                            title="Forgot password?"
                            message="Please reset your password here"
                            success={'default'}
                            auto={false}
                        />
                )}
                <form onSubmit={onSubmit}>
                    <div className="center">
                        <div>
                            <label htmlFor="email">Email</label>
                            <input
                                className={`${formError.name === 'email' && 'input-error'}`}
                                id="email"
                                type="text"
                                placeholder="Company's Email"
                                name="email"
                                value={state.email}
                                onChange={onChange}
                            />
                            {
                                formError.name === 'email' && (
                                    <small>{formError.message}</small>
                                )
                            }
                        </div>
                        <div className="bottom">
                        <div>
                            <button className="button-primary btn-w-2" type="submit">
                                Reset
                            </button>
                            <span className="terms forgot-pass">
                                <span onClick={() => history.push(paths.login)}>Login</span>
                            </span>
                        </div>
                    </div>
                    </div>
                </form>
            </div>
        </motion.div>
    );
};

export default ForgotPassword;