import { createSlice, PayloadAction } from '@reduxjs/toolkit'
import type { RootState } from '../rootReducer';
import Client, { CompanyAccount } from '../../models/Account';

interface ClientsState {
  clients: Client[];
  count: number;
  accounts: CompanyAccount[],
}

const initialState: ClientsState = {
  clients: [],
  count: 0,
  accounts: [],
}

export const clientsSlice = createSlice({
  name: 'clients',
  initialState,
  reducers: {
    loadClients: (state, action: PayloadAction<Client[]>) => {
      state.clients = action.payload;
    },
    loadCount: (state, action: PayloadAction<number>) => {
      state.count = action.payload;
    },
    loadClientAccounts: (state, action: PayloadAction<CompanyAccount[]>) => {
      state.accounts = action.payload;
    },
  },
});

export default clientsSlice.reducer;

export const { loadClients, loadCount, loadClientAccounts } = clientsSlice.actions;
export const getClients = (state: RootState) => state.clients.clients;
export const getClientsCount = (state: RootState) => state.clients.count;
export const getClientAccounts = (state: RootState) => state.clients.accounts;
