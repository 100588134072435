import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableHead from '@material-ui/core/TableHead';
import TablePagination from '@material-ui/core/TablePagination';
import TableRow from '@material-ui/core/TableRow';
import { format } from "date-fns";
import { motion } from "framer-motion";
import { useEffect, useState } from "react";
import { useQuery } from "react-query";
import { useHistory, useParams } from "react-router-dom";
import DashLoader from "../../../common/DashLoader";
import Notification from "../../../common/Notification";
import { getAccounts as callAccounts } from "../../../data/queries/account";
import { CompanyAccount, MiscDetails } from "../../../models/Account";
import paths from "../../../routes/paths";
import { capitalize } from "../../../services/formValidator";
import { useAppDispatch, useAppSelector } from "../../../store/hooks";
import { getAccounts, getAccountsCount, loadAccounts, loadCount, loadPlanName } from "../../../store/slices/accountSlice";
import { IControllers } from "../plan/Plans";
import AddMisc from "./AddMisc";
import UploadTopbar from "./UploadTopbar";


const Accounts = () => {
    const dispatch = useAppDispatch();
    const history = useHistory();
    const accounts = useAppSelector(getAccounts);
    const count = useAppSelector(getAccountsCount);
    const { planId } = useParams<{ planId: string }>();

    const [state, setState] = useState<IControllers>({
        page: 0,
        rowsPerPage: 10,
        limit: 10,
        skip: 0,
        companyId: '',
    });
    const [addingMisc, setAddingMisc] = useState(false);
    const [accountId, setAccountId] = useState('');
    const [message, setMessage] = useState('');
    const [miscValue, setMiscValue] = useState<MiscDetails>({
        itemName: "",
        amount: 0,
        entryType: "",
        entryDate: new Date()
    });

    const { data, refetch, isLoading } = useQuery(['get accounts', { planId, state }], callAccounts, {
        onSuccess(datas: { total: number, planName?: string, accounts: CompanyAccount[] }){
            dispatch(loadAccounts(datas.accounts));
            dispatch(loadCount(datas.total));
            dispatch(loadPlanName(datas.planName!));
        }
    });
    
    useEffect(() => {
        if ((data && data.accounts.length === 0) && planId === 'all') {
            setMessage('No accounts found! Please add clients!')
        }
    }, [data, isLoading, planId]);
 
    const handleChangePage = (event: any, newPage: any) => {
        setState({
            ...state,
            page: newPage,
            skip: newPage * state.limit
        });
        refetch();
    };

    const handleChangeRowsPerPage = (event: any) => {
        setState({
            ...state,
            rowsPerPage: parseInt(event.target.value, 10),
            page: 0,
            limit: parseInt(event.target.value, 10),
            skip: 0
        });
        refetch();
    };

    const handleOpenMisc = (valueId: string) => {
        setAddingMisc(true);
        setMiscValue({
            itemName: "",
            amount: 0,
            entryType: "",
            entryDate: new Date()
        });
        setAccountId(valueId);
    };

    const headCells = [
        { id: 'aaccno', numeric: true, disablePadding: true, label: 'Account Number' },
        { id: 'aname', numeric: true, disablePadding: false, label: 'Client' },
        { id: 'adue', numeric: true, disablePadding: false, label: 'Due On' },
        { id: 'astatus', numeric: true, disablePadding: false, label: 'Status' },
        { id: 'aview', numeric: true, disablePadding: false, label: 'Miscellaneous' },
        { id: 'aviews', numeric: true, disablePadding: false, label: '' }
      ];
    return (
        <motion.div
            className="plans"
            animate={{ x: 25 }}
            transition={{ ease: 'easeOut' }}
            >
            {
                message!== '' && (
                    <Notification
                        message={message}
                        success={true}
                        auto={true}
                    />
                )
            }
            {
                addingMisc && (
                    <AddMisc
                        carrier={{ miscValue, setMiscValue, accountId, setAccountId }}
                        adding={{ addingMisc, setAddingMisc }}
                        setMessage={setMessage}
                        refetch={refetch}
                    />
                )
            }
            {/* Upload Top Bar */}
            <UploadTopbar
                setMessage={setMessage}
                refetch={refetch}
            />
            {
                planId !== 'all' && accounts.length > 0 && (
                    <div className="plan-label">
                        <div className="sects">
                            <h4>Package Name</h4>
                            <p>{accounts && accounts.length > 0 && accounts[0].plan.planName} @{accounts && accounts.length > 0 && accounts[0].plan.price}/=</p>
                        </div>
                        <div className="sects">
                            <h4>Period</h4>
                            <p>{accounts && accounts.length > 0 && capitalize(accounts[0].plan.paymentPeriod)}</p>
                        </div>
                    </div>
                )
            }
            {/* <div className="title">
                <span>Accounts</span>
            </div> */}
            <div className="wrap">
                <div className="table-search">
                    <div className="left">
                        {/* <div className="filter-table">
                            <span className="material-icons">
                                filter_alt
                            </span>
                            <span>Filter</span>
                        </div>
                        <div className="search-input">
                            <input
                                type="text"
                                placeholder="Search"
                            />
                        </div> */}
                    </div>
                    <div className="right"></div>
                </div>
                <div className="tables">
                    <Table
                        aria-labelledby="tableTitle"
                        aria-label="enhanced table"
                        className="table"
                    >
                        <TableHead className="table-header">
                            <TableRow>
                                <TableCell style={{position: 'absolute', border: 'none'}}></TableCell>
                                {headCells.map((headCell: any) => (
                                <TableCell
                                    className="cell"
                                    key={headCell.id}
                                    align='center'
                                >
                                    {headCell.label}
                                </TableCell>
                                ))}
                            </TableRow>
                        </TableHead>
                        {
                            isLoading && (
                                <DashLoader />
                            )
                        }
                        <TableBody>
                            {accounts && accounts.length > 0 && accounts.map((row, index) => {
                            return (
                                <TableRow
                                    hover
                                    role="checkbox"
                                    tabIndex={-1}
                                    key={index}
                                >
                                    <TableCell style={{position: 'absolute', border: 'none'}}></TableCell>
                                    <TableCell
                                        className="clickable"
                                        align="center"
                                        onClick={() => history.push(paths.viewledger.replace(':accountId', `${row.id}`))}
                                        >
                                            {row.accountNumber}
                                    </TableCell>
                                    <TableCell
                                        className="clickable"
                                        align="center"
                                        onClick={() => history.push(paths.viewclient.replace(':clientId', `${row.client?.id}`))}
                                        >
                                            {row.client?.firstName} {row.client?.lastName}
                                    </TableCell>
                                    <TableCell align="center">{format(new Date(row.nextCharge),'yyyy-MM-dd')}</TableCell>
                                    <TableCell
                                        className="activate"
                                        align="right"
                                        >
                                        <button
                                            className={`${row.status === 'PAID' ? 'act' : 'deact'}`}
                                            >
                                            <span className="material-icons">circle</span>
                                            <span>{row.status === 'PAID' ? 'Paid' : `Not Paid`}</span>
                                        </button>
                                    </TableCell>
                                    <TableCell
                                        className="clickable"
                                        align="center"
                                        onClick={() => handleOpenMisc(row.id)}
                                        >
                                            Add
                                    </TableCell>
                                    <TableCell
                                        className="edit"
                                        align="center"
                                        onClick={() => history.push(paths.viewledger.replace(':accountId', `${row.id}`))}
                                        >
                                            View
                                    </TableCell>
                                </TableRow>
                            );
                            })}
                        </TableBody>
                    </Table>
                    <div className="pagination">
                        <TablePagination
                            component="div"
                            count={count}
                            page={state.page}
                            onPageChange={handleChangePage}
                            rowsPerPage={state.rowsPerPage}
                            onRowsPerPageChange={handleChangeRowsPerPage}
                        />
                    </div>
                </div>
            </div>
        </motion.div>
    );
}

export default Accounts;