import React, { useState, FormEvent, ChangeEvent, useEffect } from "react";
import { useMutation } from "react-query";
import { motion } from "framer-motion";
import { useDispatch } from "react-redux";
import { useHistory } from "react-router-dom";
import AuthNotification from "../../common/AuthNotification";
import { login } from "../../data/mutations/auth";
import formValidator from "../../services/formValidator";
import Loading from "../../common/Loading";
import paths from "../../routes/paths";
import { useMediaQuery } from "@material-ui/core";


export interface ILogin {
    email: string,
    password: string
}

const Login = () => {
    const matches = useMediaQuery('(max-width:1024px)');    
    const [state, setState] = useState<ILogin>({
        email: '',
        password: ''
    });

    const [formError, setFormError] = useState({
        status: false,
        name: '',
        message: ''
    });

    const [error, setError] = useState('');

    const [initial, setInitial] = useState(false);
    const [visiblePassword, setVisiblePassword] = useState(false);

    const dispatch = useDispatch();
    const history = useHistory();

    const { mutate: logIn, isLoading } = useMutation(
        () =>
        login(state, dispatch), {
          onError: (error: {message: string}) => {
            setTimeout(() => {
                setError('');
            }, 5000);
            setError(error.message);   
          }
        }
      );

    const onChange = (e: ChangeEvent<HTMLInputElement>) => setState({
        ...state,
        [e.target.name]: e.target.value
    });

    const onSubmit = (e: FormEvent) => {
        e.preventDefault();

        const { status, name, message } = formValidator(state);
        if (status) {
            setTimeout(() => {
                setFormError({
                    status: false,
                    name: '',
                    message: ''
                })
            }, 5000);
            return setFormError({
                status,
                name,
                message 
            });
        }
        // login
        logIn();
    }

    useEffect(() => {
        if (localStorage.__loginInitial__) {
            setTimeout(() => {
                setInitial(false);
            }, 5000);
            setInitial(true);
            localStorage.removeItem('__loginInitial__');
        }
    }, []);

    return(
        <motion.div
            className="auth-right"
            animate={{ x: matches ? 0 : 40 }}
            transition={{ ease: 'easeOut' }}
        >
            {
                isLoading && <Loading />
            }
            {
                initial && (
                    <AuthNotification
                        title="Account created!"
                        message="Please check your email for account verification"
                        success={'success'}
                        auto={true}
                    />
            )}
            {
                error !== '' && (
                    <AuthNotification
                        title='Error'
                        message={error}
                        success={'error'}
                        auto={true}
                    />
            )}
            <div className="verify-middle">
                <div className="top">
                    <p className="welcome">Welcome Back!</p>
                    <p>Login to your account</p>
                </div><br />
                <form onSubmit={onSubmit}>
                    <div className="center">         
                        <div>
                            <label>Email</label>
                            <input
                                className={`${formError.name === 'email' && 'input-error'}`}
                                name="email"
                                placeholder="Enter Email"
                                type="text"
                                value={state.email}
                                onChange={onChange}
                            />
                            {
                                formError.name === 'email' && (
                                    <small>{formError.message}</small>
                                )
                            }
                        </div>
                        <div className="password-wrap">
                            <label>Password</label>
                            <input
                                className={`${formError.name === 'password' && 'input-error'}`}
                                name="password"
                                placeholder="Enter Password"
                                type={visiblePassword ? "text" : "password"}
                                value={state.password}
                                onChange={onChange}
                            />
                            {
                                formError.name === 'password' && (
                                    <small>{formError.message}</small>
                                )
                            }
                            {
                                visiblePassword ? (
                                    <div className="input-pass-icon">
                                        <span className="material-icons" onClick={() => setVisiblePassword(!visiblePassword)}>visibility_off</span>
                                    </div>
                                ) : (
                                    <div className="input-pass-icon">
                                        <span className="material-icons" onClick={() => setVisiblePassword(!visiblePassword)}>visibility</span>
                                    </div>
                                )
                            }
                        </div>
                    </div>
                    <div className="bottom" style={{ paddingTop: '30px' }}>
                        <div>
                            <button className="button-primary btn-w-2" type="submit">
                                Login
                            </button>
                            <span className="terms forgot-pass">
                                <span onClick={() => history.push(paths.forgotpassword)}>Forgot password?</span>
                            </span>
                        </div>
                        {/* <div className="accept signup-login">
                            <span className="terms">
                                Don't have an account?&nbsp;&nbsp;&nbsp;<b onClick={() => history.push(paths.signup)}>Signup</b>
                            </span>
                        </div> */}
                    </div>
                </form>
            </div>               
        </motion.div>
    )
}

export default Login;