import React, { useState } from "react";
import { useHistory, useParams } from "react-router-dom";
import { useQuery } from "react-query";
import { format } from "date-fns";
import Table from "@material-ui/core/Table";
import TableBody from "@material-ui/core/TableBody";
import TableCell from "@material-ui/core/TableCell";
import TableHead from "@material-ui/core/TableHead";
import TableRow from "@material-ui/core/TableRow";
import TablePagination from "@material-ui/core/TablePagination";
import { useAppDispatch, useAppSelector } from "../../../store/hooks";
import {
  getAccounts,
  getAccountsCount,
  loadAccounts,
  loadCount,
  loadPlanName,
} from "../../../store/slices/accountSlice";
import { getAccountsAdmin as callAdminAccounts } from "../../../data/queries/admin";
import paths from "../../../routes/paths";
import { CompanyAccount } from "../../../models/Account";

const headCells = [
  { id: "aaccnbr", numeric: true, disablePadding: true, label: "Account No" },
  { id: "acclnt", numeric: true, disablePadding: false, label: "Owner" },
  { id: "adn", numeric: true, disablePadding: false, label: "Due On" },
  { id: "astts", numeric: true, disablePadding: false, label: "Status" },
  { id: "view", numeric: true, disablePadding: false, label: "" },
];

const Accounts = () => {
  const dispatch = useAppDispatch();
  const history = useHistory();
  const accounts = useAppSelector(getAccounts);
  const count = useAppSelector(getAccountsCount);
  const { companyId } = useParams<{ companyId: string }>();

  const [state, setState] = useState({
    page: 0,
    rowsPerPage: 10,
    limit: 10,
    skip: 0,
    companyId,
  });

  const {data, refetch, isLoading } = useQuery(
    ["get accounts", { state }],
    callAdminAccounts,
    {
      onSuccess(data: {
        total: number;
        planName?: string;
        accounts: CompanyAccount[];
      }) {
        dispatch(loadAccounts(data.accounts));
        dispatch(loadCount(data.total));
        dispatch(loadPlanName(data.planName!));
      },
    }
  );

  const handleChangePage = (event: any, newPage: any) => {
    setState((prevState) => ({
      ...prevState,
      page: newPage,
      skip: newPage * prevState.limit,
    }));
    refetch();
  };

  const handleChangeRowsPerPage = (event: any) => {
    setState({
      ...state,
      rowsPerPage: parseInt(event.target.value, 10),
      page: 1,
      limit: parseInt(event.target.value, 10),
      skip: 0,
    });
    refetch();
  };
  console.log(data)

  return (
    <div className="tables">
      <Table aria-label="enhanced table" className="table">
        <TableHead className="table-header">
          <TableRow>
            {headCells.map((headCell) => (
              <TableCell className="cell" key={headCell.id} align="left">
                {headCell.label}
              </TableCell>
            ))}
          </TableRow>
        </TableHead>
        {isLoading && <TableBody>Loading...</TableBody>}
        <TableBody>
          {data?.accounts.map((row, index) => (
            <TableRow hover role="checkbox" tabIndex={-1} key={index}>
              <TableCell
                className="clickable"
                align="left"
                onClick={() =>
                  history.push(paths.viewledger.replace(":accountId", `${row.id}`))
                }
              >
                {row.accountNumber}
              </TableCell>
              <TableCell
                className="clickable"
                align="left"
                onClick={() =>
                  history.push(
                    paths.viewclient.replace(":clientId", `${row.client?.id}`)
                  )
                }
              >
                {`${row?.id} ${row.client?.lastName}`}
              </TableCell>
              <TableCell align="left">
                {format(new Date(row.nextCharge), "yyyy-MM-dd")}
              </TableCell>
              <TableCell className="activate" align="right">
                <button className={`${row.status === "PAID" ? "act" : "deact"}`}>
                  <span className="material-icons">circle</span>
                  <span>{row.status === "PAID" ? "Paid" : "Not Paid"}</span>
                </button>
              </TableCell>
              <TableCell
                className="edit"
                align="left"
                onClick={() =>
                  history.push(paths.viewledger.replace(":accountId", `${row.id}`))
                }
              >
                View
              </TableCell>
            </TableRow>
          ))}
        </TableBody>
      </Table>
      <TablePagination
        component="div"
        count={count}
        page={state.page}
        onPageChange={handleChangePage}
        rowsPerPage={state.rowsPerPage}
        onRowsPerPageChange={handleChangeRowsPerPage}
      />
    </div>
  );
};

export default Accounts;
