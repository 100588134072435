import Validator from "validator";


const capitalize = (value: string) => {
    return value.charAt(0).toUpperCase() + value.substr(1);
}

const formValidator = (obj: any) => {
    let error : { status: boolean, name: string, message: string } = {
        status: false,
        name: '',
        message: ''
    }
    let keys = Object.keys(obj).map((key) => key);
    keys.reverse().map(key => { 

        if (obj[key] === '') {
            error.status = true;
            error.name = key;
            error.message = `${capitalize(key)} cannot be empty`;
        } else {
            if ('phoneNumber' in obj && obj.phoneNumber !== '' && (obj.phoneNumber.length !== 9 || isNaN(Number(obj.phoneNumber)))) {
                error.status = true;
                error.name = 'phoneNumber';
                error.message = `${isNaN(Number(obj.phoneNumber)) ? 'PhoneNumber should be digits' : 'PhoneNumber is invalid'}`;
            }

            if ('mpesaPhone' in obj && obj.mpesaPhone !== '' && (obj.mpesaPhone.length !== 13 || isNaN(Number(obj.mpesaPhone.slice(4, obj.mpesaPhone.length))))) {
                error.status = true;
                error.name = 'mpesaPhone';
                error.message = `${isNaN(Number(obj.mpesaPhone.slice(4, obj.mpesaPhone.length))) ? 'MpesaPhone should be digits' : 'MpesaPhone is invalid'}`;
            }
            
            if ('mpesaPhone' in obj && obj.mpesaPhone !== '' && obj.mpesaPhone.slice(0, 4) !== '+254') {
                error.status = true;
                error.name = 'mpesaPhone';
                error.message = `MpesaPhone must start with +254 to represent 0`;
            }

            if ('contactPhone' in obj && obj.contactPhone !== '' && (obj.contactPhone.length !== 13 || isNaN(Number(obj.contactPhone.slice(4, obj.contactPhone.length))))) {
                error.status = true;
                error.name = 'contactPhone';
                error.message = `${isNaN(Number(obj.contactPhone.slice(4, obj.contactPhone.length))) ? 'ContactPhone should be digits' : 'ContactPhone is invalid'}`;
            }
            
            if ('contactPhone' in obj && obj.contactPhone !== '' && obj.contactPhone.slice(0, 4) !== '+254') {
                error.status = true;
                error.name = 'contactPhone';
                error.message = `ContactPhone must start with +254 to represent 0`;
            }

            if ('shortCode' in obj && (isNaN(Number(obj.shortCode)) || obj.shortCode === '0')) {
                error.status = true;
                error.name = 'shortCode';
                error.message = `${isNaN(Number(obj.shortCode)) ? 'ShortCode must be a digit' : 'ShortCode cannot be zero'}`;
            }

            if('email' in obj && obj.email !== '' && !Validator.isEmail(obj.email)) {
                error.status = true;
                error.name = 'email';
                error.message = `Email is invalid`;
            }
        }

        return key;
    });
    return error;
}

const demoValidator = (obj: any) => {
    let error : { status: boolean, name: string, message: string } = {
        status: false,
        name: '',
        message: ''
    }
    let keys = Object.keys(obj).map((key) => key);
    keys.reverse().map(key => { 

        if (obj[key] === '') {
            error.status = true;
            error.name = key;
            error.message = `${capitalize(key)} cannot be empty`;
        } else {
            if ('phoneNumber' in obj && obj.phoneNumber !== '' && (obj.phoneNumber.length !== 12 || isNaN(Number(obj.phoneNumber)))) {
                error.status = true;
                error.name = 'phoneNumber';
                error.message = `${isNaN(Number(obj.phoneNumber)) ? 'PhoneNumber should be digits' : 'PhoneNumber is invalid'}`;
            }
        }

        return key;
    });
    return error;
}

export default formValidator;
export { capitalize, demoValidator };