import Button from "@material-ui/core/Button";
import SwipeableDrawer from "@material-ui/core/SwipeableDrawer";
import useMediaQuery from "@material-ui/core/useMediaQuery";
import { motion } from "framer-motion";
import React, { Fragment, useState } from "react";
import { useDispatch } from "react-redux";
import { useHistory } from "react-router-dom";
import LogoImage from "../../assets/images/logo-inv.png";
import { logout } from "../../data/mutations/auth";
import paths from "../../routes/paths";
import { useAuth, useCompany } from "../../utils/hooks/index";

type Anchor = 'left';

const Landing = () => {
    const history = useHistory();
    const isAuthencticated = useAuth();
    const isCompany = useCompany();
    const dispatch = useDispatch();
    const matches = useMediaQuery('(min-width:1024px)');
    const [state, setState] = useState({
        left: false
    });

    const links: { name: string; id: number; link: string; image?: string }[] = [
        { name: 'Panga Malipo', id: 0, link: paths.landing, image: LogoImage },
        // { name: 'About', id: 1, link: paths.landing },
        // { name: 'Blog', id: 2, link: paths.landing },
        // { name: 'Careers', id: 3, link: paths.landing },
        // { name: 'Support', id: 4, link: paths.landing },
    ];

    const toggleDrawer = (anchor: Anchor, open: boolean) =>
        (event: React.KeyboardEvent | React.MouseEvent) => {
        if (
            event &&
            event.type === 'keydown' &&
            ((event as React.KeyboardEvent).key === 'Tab' ||
            (event as React.KeyboardEvent).key === 'Shift')
        ) {
            return;
        }

        setState({ ...state, [anchor]: open });
    };

    return(
        <div className="landing-container">
            <div className="top-nav">
                <div className="top-links">   
                    <ul>
                        <li className="mobile-menu">
                            <Fragment>
                                <Button className="border-btn" onClick={toggleDrawer('left', true)}>
                                    <span className="material-icons">menu</span>
                                </Button>
                                <SwipeableDrawer
                                    anchor={'left'}
                                    open={state['left']}
                                    onClose={toggleDrawer('left', false)}
                                    onOpen={toggleDrawer('left', true)}
                                >
                                    <div className="landing-modal">
                                        <div className="div-head">
                                            <div>
                                                <Button className="border-btn" onClick={toggleDrawer('left', false)}>
                                                    <span className="material-icons">menu</span>
                                                </Button>
                                            </div>
                                                {
                                                links.filter(link => link.id === 0).map((link, i) => (
                                                    <div
                                                        className={`${link.id === 0 && 'home'}`}
                                                        onClick={() => history.push(link.link)}
                                                        key={i}
                                                        >
                                                        <img
                                                            className="sm-logo"
                                                            src={link.image}
                                                            alt={link.name}
                                                        />
                                                    </div>
                                                ))
                                            }
                                        </div>
                                        <div className="div-content">
                                            <ul className="ul-content">
                                                {
                                                    links.filter(link => link.id !== 0).map((link, i) => (
                                                        <li
                                                            className={`${link.id === 0 && 'home'}`}
                                                            onClick={() => history.push(link.link)}
                                                            key={i}
                                                            >
                                                            {link.name}
                                                        </li>
                                                    ))
                                                }
                                            </ul>
                                        </div>
                                    </div>
                                </SwipeableDrawer>
                            </Fragment>
                        </li>
                        {
                            matches ? (
                                    links.map((link, i) => (
                                        <li
                                            className={`${link.id === 0 && 'home'}`}
                                            onClick={() => history.push(link.link)}
                                            key={i}
                                            >
                                            <img
                                                className="lg-logo"
                                                src={link.image}
                                                alt={link.name}
                                            />
                                        </li>
                                    ))
                            ) : (
                                    links.filter(link => link.id === 0).map((link, i) => (
                                        <li
                                            className={`${link.id === 0 && 'home'}`}
                                            onClick={() => history.push(link.link)}
                                            key={i}
                                            >
                                            <img
                                                className="sm-logo"
                                                src={link.image}
                                                alt={link.name}
                                            />
                                        </li>
                                    ))                            )
                        }
                    </ul>
                </div>
                {
                    isAuthencticated ? (
                        <div className="top-buttons">
                            {
                                isCompany ? (
                                    <button
                                        className="button-white btn-w-2"
                                        onClick={() => history.push(paths.plans)}
                                        >
                                        Packages
                                    </button>
                                ) : (
                                    <button
                                        className="button-white btn-w-2"
                                        onClick={() => history.push(paths.clientdashboard)}
                                        >
                                        Dashboard
                                    </button>
                                )
                            }
                            <button
                                className="button-clear btn-w-2"
                                onClick={() => logout(dispatch)}
                                >
                                Logout
                            </button>
                        </div>
                    ) : (
                        <div className="top-buttons">
                            {/* <button
                                className="button-white btn-w-2"
                                onClick={() => window.open("//api.whatsapp.com/send?phone=254742773625&text=", "_blank")}
                                >
                                Signup
                            </button> */}
                            <button
                                className="button-primary btn-w-2"
                                onClick={() => history.push(paths.login)}
                                >
                                Login
                            </button>
                        </div>
                    )
                }                    
            </div>
            <motion.div
                className="details-wrap"
                animate={{ x: 14 }}
                transition={{ ease: "easeOut", duration: 1 }}
                >
                <div className="details">
                    <div className="details-inner">
                        <h1 className="text-white">
                            Manage your Clients
                        </h1>
                        <p className="text-white">
                            A Kenyan-based client management system. Manage your clients and utility payments.
                        </p>
                        {
                            isAuthencticated ? (
                                <div className="landing-buttons">
                                    {
                                        isCompany ? (
                                            <button
                                                className="button-clear btn-w-1"
                                                onClick={() => history.push(paths.plans)}
                                                >
                                                Packages
                                            </button>
                                        ) : (
                                            <button
                                                className="button-clear btn-w-1"
                                                onClick={() => history.push(paths.clientdashboard)}
                                                >
                                                Dashboard
                                            </button>
                                        )
                                    }
                                </div>                                
                            ) : (
                                <div className="landing-buttons">
                                    <button
                                        className="button-primary btn-w-1"
                                        // onClick={() => window.open("//api.whatsapp.com/message/D4Y2VM2I7A7NB1?autoload=1&app_absent=0", "_blank")}
                                        onClick={() => window.open("https://wa.me/+254742773625", "_blank")}
                                        >
                                        Get Started
                                    </button>
                                    {/* <button
                                        className="button-clear btn-w-1"
                                        onClick={() => history.push(paths.login)}
                                        >
                                        Login
                                    </button> */}
                                </div>
                            )
                        }
                    </div>
                </div>
                <motion.div
                    className="screenshot"
                    animate={{ rotate: 360 }}
                    transition={{ duration: 1 }}
                ></motion.div>
            </motion.div>
        </div>
    );
};

export default Landing;
