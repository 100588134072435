import axios from "axios";
import { baseUrl } from "./base";
import { IControllers } from "../components/dashboard/plan/Plans";
import { EditDetails } from "../models/Account";


const clientsApi = {
    getClients: (state: IControllers) => {
        return axios.get(`${baseUrl}/plan-manager/clients?take=${state.limit}&skip=${state.skip}`);
    },

    activateClient: (id: string, isActive: boolean) => {
        return axios.patch(`${baseUrl}/clients/${id}`, { isActive });
    },
    getClientAccounts: (clientId: string) => {
        // add pagination with state
        return axios.get(`${baseUrl}/plan-manager/${clientId}/all-accounts`);
    },
    editClient: (id: string, editValue: EditDetails) => {
        return axios.patch(`${baseUrl}/clients/${id}`, { editValue });
    },
    searchClient: (queryString: string) => {        
        return axios.get(`${baseUrl}/clients/search?q=${queryString}&take=${5}&skip=${0}`);
    }
}

export default clientsApi;