import accountsApi from "../../api/account";
import { CompanyAccount } from "../../models/Account";
import { IPlan } from "../../components/dashboard/account/AddClient";
import errorHandler from "../../services/errorHandler";


const getAccounts = async(params: any) => {
    const [, { planId, state }] = params.queryKey;
    
    try {
        let resp = await (await accountsApi.getAccounts(planId, state)).data.data as { total: number, planName?: string, clients: CompanyAccount[] };             
        return resp;
    } catch (error) {
        errorHandler(error)
    }
}

const getAllPlans = async() => {    
    try {
        let resp = await (await accountsApi.getAllPlans()).data.data as { total: number, plans: IPlan[] };        
        return resp;
    } catch (error) {
        errorHandler(error)
    }
}

const searchClient = async(params: any) => {
    const [, { searchString }] = params.queryKey;
    try {
        let resp = await (await accountsApi.searchClient(searchString)).data.data;
        return resp;
    } catch (error) {
        errorHandler(error);
    }
}

const getClientAccounts = async(params: any) => {
    const [, { planId, clientId }] = params.queryKey;
    try {
        let resp = await (await accountsApi.getClientAccounts(planId, clientId)).data.data;

        return resp;
    } catch (error) {
        errorHandler(error);
    }
}

export { getAccounts, getAllPlans, searchClient, getClientAccounts };