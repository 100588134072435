import { matchPath, useHistory, useLocation, useParams } from "react-router-dom";
import paths from "../../routes/paths";
import { useClient, useCompany } from "../../utils/hooks";

interface ILink {
    name: string;
    icon: string;
    path: string;
    view: boolean;
    display: boolean;
    subName: string;
    subPath: string;
    activeSub: boolean;
}
const BreadCrumb = () => {    
    const location = useLocation();
    const history = useHistory();
    const isCompany = useCompany();
    const isClient = useClient();
    const { planId } = useParams<{ planId: string }>();

    const isActive = (path: string) => {
        if (location.pathname === path) {
            return true;
        }
        return false;
    }

    const matches = (path: string) => {
        let returnValue = false;

        if(matchPath(location.pathname, { path, exact: true })?.isExact && planId !== 'all') {
            returnValue =  true;
        }
        return returnValue;   
    }

    const links: ILink[] = [
        {
            name: 'Packages',
            icon: 'grid_view',
            path: paths.plans,
            view: isCompany,
            display: isActive(paths.plans),
            subName: 'Package Account',
            subPath: paths.accounts,
            activeSub: matches(paths.accounts)
        },
        {
            name: 'Accounts',
            icon: 'switch_account',
            path: paths.accounts.replace(':planId', 'all'),
            view: isCompany,
            display: isActive(paths.accounts.replace(':planId', 'all')),
            subName: 'View Ledger',
            subPath: paths.viewledger,
            activeSub: matches(paths.viewledger)
        },
        {
            name: 'Clients',
            icon: 'people_outline',
            path: paths.clients,
            view: isCompany,
            display: isActive(paths.clients),
            subName: 'View Client',
            subPath: paths.viewclient,
            activeSub: matches(paths.viewclient)
        },
        {
            name: 'Home',
            icon: 'home',
            path: paths.clientdashboard,
            view: isClient,
            display: isActive(paths.clientdashboard),
            // to be confirmed
            subName: 'Package Accounts',
            subPath: paths.viewledger,
            activeSub: isActive(paths.viewledger),
        }
    ];   
    
    return(
        <div className="bread-crumb">
            {
                links.filter(path => (path.display || path.activeSub)).map((path, i) => (
                    <p key={i}>
                        <span className="linkspan" onClick={() => history.push(path.path)}>{path.name}</span>
                        {
                            path.activeSub && (
                                <span>
                                    <span className="separator"> / </span>
                                    <span className="linkspan">{path.subName}</span>
                                </span>
                            )
                        }
                    </p>
                ))
            }
        </div>
    )
}

export default BreadCrumb;