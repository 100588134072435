import React from "react";
import Spinner from "../assets/images/spinner.gif";


const Loading = () => {
    return(
        <div className="loading-wrap">
            <div className="spinner">
                <img src={Spinner} alt='Loading...' />
            </div>
        </div>
    )
}

export default Loading;