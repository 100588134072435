import { createSlice, PayloadAction } from '@reduxjs/toolkit'
import type { RootState } from '../rootReducer';
import Plan from "../../models/Plan";

interface PlanState {
  plans: Plan[];
  count: number;
}

const initialState: PlanState = {
  plans: [],
  count: 0,
}

export const planSlice = createSlice({
  name: 'plan',
  initialState,
  reducers: {
    loadPlans: (state, action: PayloadAction<Plan[]>) => {
      state.plans = action.payload;
    },
    loadCount: (state, action: PayloadAction<number>) => {
      state.count = action.payload;
    }
  },
});

export default planSlice.reducer;

export const { loadPlans, loadCount } = planSlice.actions;
export const getPlans = (state: RootState) => state.plan.plans;
export const getPlansCount = (state: RootState) => state.plan.count;
