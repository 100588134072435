import clientsApi from "../../api/clients";
import { EditDetails } from "../../models/Account";
import errorHandler from "../../services/errorHandler";


const activateClient = async(id: string, isActive: boolean) => {
    try {
        let resp = await (await clientsApi.activateClient(id, isActive)).data.data;
        return resp;
    } catch (error) {
        errorHandler(error)
    }
}

const editClient = async(id: string, editValue: EditDetails) => {
    try {
        let resp = await (await clientsApi.editClient(id, editValue)).data.data;
        return resp;
    } catch (error) {
        errorHandler(error);
    }
}

export { activateClient, editClient };