import React, { useState } from "react";
import { useParams, useHistory } from "react-router-dom";
import { useQuery } from "react-query";
import { motion } from "framer-motion";
import AuthNotification from "../../common/AuthNotification";
import { activateEmail } from "../../data/queries/auth";
import paths from "../../routes/paths";
import Loading from "../../common/Loading";


const EmailNotification = () => {
    const [success, setSuccess] = useState('');

    const { token } = useParams<{token: string}>();
    
    const history = useHistory();

    const { isLoading } = useQuery(['token activation', { token }], activateEmail, {
        onSuccess(){
            setSuccess('Account has been activated');
            setTimeout(() => {
                history.push(paths.plans)
            }, 3000);
        },
        retry: false
    });

    return(
        <motion.div
            className="auth-right"
            animate={{ x: 40 }}
            transition={{ ease: 'easeOut' }}
        >
            {
                isLoading && <Loading />
            }
            <div className="verify-middle">
                {
                    success !== '' && (
                        <AuthNotification
                            title="Account verified"
                            message="Your account has been verified"
                            success={'success'}
                            auto={false}
                        />
                )}
                <div className="terms">
                    <p>Didn't receive email? <b>Resend</b></p>
                </div>
                <div className="verify-buttons">
                    <button
                        className="button-primary btn-w-1"
                        onClick={() => {
                            history.push(paths.login)
                        }}
                        >
                        Log in
                    </button>
                </div>
            </div>
        </motion.div>
    );
};

export default EmailNotification;