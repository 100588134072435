import axios from "axios";
import Account, { MiscDetails } from "../models/Account";
import { IControllers } from "../components/dashboard/plan/Plans";
import { baseUrl } from "./base";


const accountsApi = {
    getAllPlans: () => {
        return axios.get(`${baseUrl}/plans/company`);
    },
    newAccount: (planId: string, account: Account) => {
        account.initialBalance = 0;
        return axios.post(`${baseUrl}/plan-manager/${planId}/account`, account);
    },
    getAccounts: (planId: string, state: IControllers) => {
        const allClients = `/plan-manager/accounts?take=${state.limit}&skip=${state.skip}`;
        const plansById = `/plan-manager/${planId}/accounts?take=${state.limit}&skip=${state.skip}`;
        const endpoint = planId === 'all' ? allClients : plansById;
        return axios.get(`${baseUrl}${endpoint}`);
    },
    getClientAccounts: (planId: string, clientId: string) => {
        const endpoint = planId === 'all' ? `/plan-manager/${clientId}/all-accounts` : `/plan-manager/${planId}/${clientId}/accounts`;
        return axios.get(`${baseUrl}${endpoint}`);
    },
    editAccount: (id: string, plan: Account) => {
        return axios.patch(`${baseUrl}/plans/${id}`, plan);
    },
    deleteAccount: (planId: string, accountIds: string[]) => {
        return axios.delete(`${baseUrl}/plans/${planId}/accounts`, { data: { accountIds } });
    },
    // ::   Description
    // ::   Search from mpesa to autopopulate
    searchClient: (searchString: string) => {
        return axios.get(`${baseUrl}/clients/phone/${searchString}`);
    },
    uploadCSV: (creatingId: string, CSV: any, setProgress: Function) => {
        const config = {
            onUploadProgress: function(progressEvent: any) {
              let percentCompleted = Math.round((progressEvent.loaded * 100) / progressEvent.total);
              setProgress(percentCompleted);
            }
        };
        return axios.post(`${baseUrl}/upload/${creatingId}/client-csv`, CSV, config);
    },
    addMisc: (accountId: string, miscValue: MiscDetails) => {
        const payload = {
            itemName: miscValue.itemName,
            amount: Number(miscValue.amount),
            entryType: miscValue.entryType,
            entryDate: miscValue.entryDate.toDateString()
        }
        return axios.post(`${baseUrl}/plan-manager/${accountId}/add-miscellanous`, payload);
    },
}

export default accountsApi;