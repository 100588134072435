import demoAPI from "../../api/demo";
import { IDemo } from "../../components/demo/DemoPage";
import errorHandler from "../../services/errorHandler";

const sendSTKDemo = async(payload: IDemo) => {
    
    try {
        let resp = await (await demoAPI.sendSTKDemo(payload)).data.data;        
        return resp;
    } catch (error) {
        errorHandler(error)
    }
}

export { sendSTKDemo };