import plansApi from "../../api/plans";
import { Ledger } from "../../models/Account";
import Plan from "../../models/Plan";
import errorHandler from "../../services/errorHandler";


const getPlans = async(params: any) => {
    const [, { state }] = params.queryKey;
    
    try {
        let resp = await (await plansApi.getPlans(state)).data.data as { total: number, plans: Plan[] };        
        return resp;
    } catch (error) {
        errorHandler(error)
    }
}

const getCompanyLedger = async(params: any) => {    
    const [, { accountId }] = params.queryKey;
    try {
        let resp = await (await plansApi.getCompanyLedger(accountId)).data.data as Ledger;        
        return resp;
    } catch (error) {
        errorHandler(error)
    }
}

const searchPlan = async(searchString: string) => {
    try {
        let resp = await (await plansApi.searchPlan(searchString)).data.data;
        return resp;
    } catch (error) {
        errorHandler(error);
    }
}

export { getPlans, getCompanyLedger, searchPlan };