import axios from "axios";
import Plan from "../models/Plan";
import { IControllers } from "../components/dashboard/plan/Plans";
import { baseUrl } from "./base";


const plansApi = {
    newPlan: (plan: Plan) => {
        const payload = {
            planName: plan.planName,
            price: parseInt(plan.price),
            paymentPeriod: plan.paymentPeriod,
            prorated: plan.prorated
        }
        return axios.post(`${baseUrl}/plans/create`, payload);
    },
    getPlans: (state: IControllers) => {
        return axios.get(`${baseUrl}/plans/company?take=${state.limit}&skip=${state.skip}`);
    },
    editPlan: (id: string, plan: Plan) => {
        return axios.patch(`${baseUrl}/plans/patch/${id}`, plan);
    },
    activatePlan: (id: string, isActive: boolean) => {
        return axios.patch(`${baseUrl}/plans/patch/${id}`, { isActive });
    },
    deletePlan: (planIds: string[]) => {
        return axios.delete(`${baseUrl}/plans/delete`, { data: { planIds } });
    },
    getCompanyLedger: (accountId: string) => {
        return axios.get(`${baseUrl}/plan-manager/${accountId}/ledgerentries`);
    },
    searchPlan: (queryString: string) => {        
        return axios.get(`${baseUrl}/plans/search?q=${queryString}&take=${5}&skip=${0}`);
    }
}

export default plansApi;