import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableHead from '@material-ui/core/TableHead';
import TablePagination from '@material-ui/core/TablePagination';
import TableRow from '@material-ui/core/TableRow';
import { motion } from "framer-motion";
import { ChangeEvent, useEffect, useState } from "react";
import { useMutation, useQuery } from "react-query";
import { useHistory } from "react-router-dom";
import DashLoader from "../../../common/DashLoader";
import Notification from "../../../common/Notification";
import SmallLoader from "../../../common/SmallLoader";
import { activateClient } from "../../../data/mutations/clients";
import { getClients as callClients, searchClient } from "../../../data/queries/clients";
import Client from "../../../models/Account";
import paths from "../../../routes/paths";
import { capitalize } from "../../../services/formValidator";
import { useAppDispatch, useAppSelector } from "../../../store/hooks";
import { getClients, getClientsCount, loadClients, loadCount } from "../../../store/slices/clientsSlice";
import UploadTopbar from "../account/UploadTopbar";
import { IControllers } from "../plan/Plans";


const Clients = () => {
    const history = useHistory();
    const dispatch = useAppDispatch();
    const clients = useAppSelector(getClients);
    const count = useAppSelector(getClientsCount);

    const [state, setState] = useState<IControllers>({
        page: 0,
        rowsPerPage: 10,
        limit: 10,
        skip: 0
    });
    const [id, setId] = useState('');
    const [message, setMessage] = useState('');
    const [searchString, setSearchString] = useState('');
    const [foundClient, setFoundClient] = useState<Client[]>([]);

    const { data, refetch, isLoading } = useQuery(['get clients', { state }], callClients, {
        onSuccess(datas: { total: number, clients: Client[] }){
            dispatch(loadClients(datas.clients));
            dispatch(loadCount(datas.total));
        }
    });

    const { mutate: activatingClient, isLoading: activateLoading } = useMutation(
        async ({ id, isActive } : { id: string, isActive: boolean }) => 
        await activateClient(id, isActive), {
          async onSuccess() {
            setTimeout(() => {
              setMessage('');
            }, 5000);
            setMessage('Request was successful');
            let ref = await refetch();
            if (ref) {
                setId('')
            }
          }
    });

    useEffect(() => {
        if (data && data.clients.length === 0) {
            setMessage('Your account is ready, no clients found!')
        }
    }, [data]);

    const manageThrottle = (cb: Function, d: number) => {
        let lastTime = 0;

        return (...args: any) => {
            let now = new Date().getTime();

            if (now - lastTime < d) return;
            lastTime = now;
            return cb(...args);
        }
    }

    const onSearchChange = (e: ChangeEvent<HTMLInputElement>) => {        
        setSearchString(e.target.value);
        
        manageThrottle(async () => {
            let resp = await searchClient(searchString);
            
            if (resp) {
                setFoundClient(resp.clients);
            }
        }, 1000)();
    }

    const handleChangePage = (event: any, newPage: any) => {
        setState({
            ...state,
            page: newPage,
            skip: newPage * state.limit
        });
        refetch();
    };

    const handleChangeRowsPerPage = (event: any) => {
        setState({
            ...state,
            rowsPerPage: parseInt(event.target.value, 10),
            page: 0,
            limit: parseInt(event.target.value, 10),
            skip: 0
        });
        refetch();
    };

    const headCells = [
        { id: 'cname', numeric: true, disablePadding: true, label: 'Client Name' },
        { id: 'cmpesa', numeric: true, disablePadding: false, label: 'Mpesa' },
        { id: 'cemail', numeric: true, disablePadding: false, label: 'Email' },
        { id: 'cdeactivate', numeric: true, disablePadding: false, label: '' },
        { id: 'cview', numeric: true, disablePadding: false, label: '' },
      ];

      console.log(data)

    return (
        <motion.div
            className="plans"
            animate={{ x: 25 }}
            transition={{ ease: 'easeOut' }}
            >
            {
                message!== '' && (
                    <Notification
                        message={message}
                        success={true}
                        auto={true}
                    />
                )
            }
            {/* Upload Top Bar */}
            <UploadTopbar
                setMessage={setMessage}
                refetch={refetch}
            />
            {/* <div className="title">
                <span>Clients</span>
            </div> */}
            <div className="wrap">
                <div className="table-search">
                    <div className="left">
                        {/* <div className="filter-table">
                            <span className="material-icons">
                                filter_alt
                            </span>
                            <span>SORT</span>
                        </div> */}
                        <div className="search-input">
                            <input
                                type="text"
                                placeholder="Search"
                                value={searchString}
                                onChange={onSearchChange}
                            />
                        </div>
                        {/* absolute end */}
                        <div className={`left-dropdown ${searchString.length > 0 ? 'view-drop' : 'hide-drop'}`}>
                            {foundClient.length > 0 && foundClient.map((cl, i) => (
                                <p
                                    key={i}
                                    onClick={() => {
                                        dispatch(loadClients(foundClient.filter(fc => fc.id === cl.id)));
                                        dispatch(loadCount(0));
                                        setSearchString('');
                                    }}  
                                    >
                                    <span className="material-icons">search</span>
                                    <span className="span1">{cl.firstName} {cl.lastName}</span>
                                </p>
                            ))}
                            {
                                foundClient.length === 0 && (
                                    <p>
                                        <span className="material-icons">search</span>
                                        <span className="span1">No Data</span>
                                    </p>
                                )
                            }
                        </div>
                        {/* absolute end */}
                    </div>
                    {/* <div className="right">
                    </div> */}
                </div>
                <div className="tables">
                    <Table
                        aria-labelledby="tableTitle"
                        aria-label="enhanced table"
                        className="table"
                    >
                        <TableHead className="table-header">
                            <TableRow>
                                <TableCell style={{position: 'absolute', border: 'none'}}></TableCell>
                                {headCells.map((headCell: any) => (
                                <TableCell
                                    className="cell"
                                    key={headCell.id}
                                    align='center'
                                    padding={headCell.disablePadding ? 'none' : 'normal'}
                                >
                                    {headCell.label}
                                </TableCell>
                                ))}
                            </TableRow>
                        </TableHead>
                        {
                            isLoading && (
                                <DashLoader />
                            )
                        }
                        <TableBody>
                            {clients && clients.length > 0 && clients.map((row, index) => {
                            return (
                                <TableRow
                                    hover
                                    tabIndex={-1}
                                    key={index}
                                >
                                    {
                                        (id === row.id! && activateLoading) ? (
                                            <SmallLoader />
                                        ) : (
                                            <TableCell style={{position: 'absolute', border: 'none'}}></TableCell>
                                        )
                                    }
                                    <TableCell
                                        className="clickable"
                                        align="center"
                                        onClick={() => history.push(paths.viewclient.replace(':clientId', `${row.id}`))}
                                        >
                                            {capitalize(row.firstName)} {capitalize(row.lastName)}
                                    </TableCell>
                                    <TableCell align="center">{capitalize(row.mpesaPhone)}</TableCell>
                                    <TableCell align="center">{row.email}</TableCell>
                                    <TableCell
                                        className="activate"
                                        align="center"
                                        >
                                        <button
                                            className={`${row.isActive ? 'deact' : 'act'}`}
                                            onClick={() => {
                                                setId(row.id!);
                                                activatingClient({ id: row.id!, isActive: !row.isActive })
                                            }}
                                            >
                                            <span className="material-icons">circle</span>
                                            <span>{row.isActive ? 'Deactivate' : `Activate`}</span>
                                        </button>
                                    </TableCell>
                                    <TableCell
                                        className="edit"
                                        align="center"
                                        onClick={() => history.push(paths.viewclient.replace(':clientId', `${row.id}`))}
                                        >
                                            View
                                    </TableCell>
                                </TableRow>
                            );
                            })}
                        </TableBody>
                    </Table>
                    <div className="pagination">
                        <TablePagination
                            component="div"
                            count={count}
                            page={state.page}
                            onPageChange={handleChangePage}
                            rowsPerPage={state.rowsPerPage}
                            onRowsPerPageChange={handleChangeRowsPerPage}
                        />
                    </div>
                </div>
            </div>
        </motion.div>
    );
}

export default Clients;