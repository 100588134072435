import Spinner from "../assets/images/spinner.gif";


const Loading = () => {
    return(
        <div className="small-loader">
            <div className="small-spinner">
                <img src={Spinner} alt='Loading...' />
            </div>
        </div>
    )
}

export default Loading;