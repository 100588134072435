import { authActions } from "../actions/auth";
import { LOGGIN, LOGOUT } from "../actions/types";
import { authR } from "./types";

const initState: authR = {
  session: null,
  isAuthenticated: false,
  isCompany: false,
  isClient: false,
  isAdmin: false
};

const auth = (state = initState, action: authActions): authR => {
  const { type, payload } = action;
  switch (type) {
    case LOGGIN:
      return {
        ...state,
        session: payload,
        isAuthenticated: true,
        isCompany: payload!.role === 'company',
        isClient: payload!.role === 'client',
        isAdmin: payload!.role === 'admin'
      };
    case LOGOUT:
      return {
        ...state,
        session: null,
        isAuthenticated: false,
        isCompany: false,
        isClient: false,
        isAdmin: false
      };

    default:
      return state;
  }
};

export default auth;