import { useSelector } from "react-redux";
import { RootState } from "../../store/rootReducer";
import Profile from "../../models/Profile";

export const useAuth = (): boolean => {
  return useSelector((state: RootState) => state.auth.isAuthenticated);
};

export const useCompany = (): boolean => {
  return useSelector((state: RootState) => state.auth.isCompany);
};

export const useClient = (): boolean => {
  return useSelector((state: RootState) => state.auth.isClient);
};

export const useAdmin = (): boolean => {
  return useSelector((state: RootState) => state.auth.isAdmin);
};

export const useGetProfile = (): Profile | null => {
  return useSelector((state: RootState) => state.profile.user);
};