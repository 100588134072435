import axios from "axios";
import { baseUrl } from "./base";
import { ICompanyEdit } from "../models/Profile";

const profileApi = {
    getCompany: (isCompany: boolean) => {        
        let endpoint = isCompany ? axios.get(`${baseUrl}/company`) : axios.get(`${baseUrl}/clients/user`);
        return endpoint;
    },
    editLogo: (logo: any) => {
        return axios.post(`${baseUrl}/upload/image`, logo);
    },
    editProfile: (id: string, profile: ICompanyEdit) => {
        return axios.patch(`${baseUrl}/company`, profile)
    }
}

export default profileApi;