import React, { useState } from "react";
import { useMutation } from "react-query";
import { motion } from "framer-motion";
import { sendEmail, sendSms } from "../../data/mutations/auth";
import AuthNotification from "../../common/AuthNotification";
import Loading from "../../common/Loading";


const Verify = () => {
    const [success, setSuccess] = useState('');

    const { mutate: sendMail, isLoading } = useMutation(sendEmail, {
        onSuccess(){
            setSuccess('Request sent! Please check your email');
        }
    });

    const { mutate: sendCode, isLoading: loading } = useMutation(sendSms, {
        onSuccess(){
            setSuccess('Request sent! Please check your phone');
        }
    });

    return(
        <motion.div
            className="auth-right"
            animate={{ x: 40 }}
            transition={{ ease: 'easeOut' }}
        >
            {
                (isLoading || loading) && <Loading />
            }
            {
                success !== '' && (
                    <AuthNotification
                        title="Success"
                        message={success}
                        success={'success'}
                        auto={true}
                    />
            )}
            <div className="verify-middle">
                {
                    true && (
                        <AuthNotification
                            title="Account created"
                            message="Verify your account by either email/phone number"
                            success={'success'}
                            auto={false}
                        />
                )}
                <div className="top">
                    <p>Please verify your account</p>
                </div>
                <div className="verify-buttons">
                    <button
                        className="button-primary"
                        onClick={() => sendMail()}
                        >
                        Send Email Verification
                    </button>
                    <button
                        className="button-primary"
                        onClick={() => sendCode()}
                        >
                        Send Phone Verification
                    </button>
                </div>
            </div>
            </motion.div>
    );
};

export default Verify;